import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  title: {
    id: 'components.Settings.title',
    defaultMessage: 'Configuración general del chat'
  },
  option1: {
    id: 'components.Settings.option1',
    defaultMessage: 'Tema del chat'
  },
  option2: {
    id: 'components.Settings.option2',
    defaultMessage: 'Fondo'
  },
  option3: {
    id: 'components.Settings.option3',
    defaultMessage: 'íconos'
  },
  option4: {
    id: 'components.Settings.option4',
    defaultMessage: 'Mis mensajes'
  },
  option5: {
    id: 'components.Settings.option5',
    defaultMessage: 'Mensajes del contacto'
  },
  option6: {
    id: 'components.Settings.option6',
    defaultMessage: 'Tamaño de la fuente'
  },
  save: {
    id: 'components.Settings.save',
    defaultMessage: 'Guardar'
  },
  back: {
    id: 'components.Settings.back',
    defaultMessage: 'Regresar a disponibles'
  }
});