import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { useStyles } from './MainBarMenuStyles';
import { routes } from 'routes/routes';
import { useDrawer } from 'components/Drawer';
import { useChallenge } from 'hooks/useChallenges';

const MainBarMenu = () => {
  const history = useHistory();
  const { handleCloseDrawer } = useDrawer();
  const { updateChallenge } = useChallenge();
  const classes = useStyles();

  return (
    <Box mt={4} px={4}>
      <List>
        {_.map(
          routes,
          (route) =>
            route.sidebar && (
              <ListItem
                key={`route-${route.name}`}
                button
                className={classes.listItem}
                onClick={() => {
                  updateChallenge(route.idButton);
                  history.push(route.path);
                  handleCloseDrawer();
                }}
              >
                <ListItemText className={classes.listItemText}>
                  {route.name}
                </ListItemText>
              </ListItem>
            )
        )}
      </List>
    </Box>
  );
};

MainBarMenu.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { MainBarMenu };
