import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: _ref => {
    let anchor = _ref.anchor;
    return {
      zIndex: 2,
      pointerEvents: 'none',
      position: 'absolute',
      bottom: 0,
      right: 0,
      fontSize: '10px',
      color: anchor === 'right' ? theme.palette.primary.contrastText : theme.palette.text.primary,
      padding: '5px 8px',
      lineHeight: '9px',
      borderRadius: '7px',
      transition: 'all 150ms',
      whiteSpace: 'nowrap',
      '& svg': {
        marginBottom: '-3px',
        marginLeft: '6px',
        fontSize: '14px'
      }
    };
  }
}));
export { useStyles };