import _ from 'lodash';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Copyright } from '@octopy/react-copyright';
import { Images } from 'assets';
import { useRootProvider } from 'components/RootProvider';
import { useStyles } from './IntroStyles';

const timeToSlide = 2000;

const Intro = () => {
  const classes = useStyles();
  const history = useHistory();
  const { rootState } = useRootProvider();
  const clientImage = _.get(rootState, ['event', 'company', 'image'], '');

  const [index, setIndex] = useState(0);

  useEffect(() => {
    setTimeout(
      () => {
        if (index <= 2) {
          setIndex(index + 1);
        } else {
          setIndex(index + 1);
          history.push('/hall');
        }
      },
      index === 0 ? 500 : timeToSlide
    );
  }, [index]);

  return (
    <div
      className={`${classes.container} ${
        index === 3 ? classes.containerClientCompany : ''
      }`}
    >
      <img
        src={Images.productLogo}
        alt="logo"
        className={clsx(classes.splash, {
          [classes.splashIn]: index === 1,
          [classes.splashOut]: index !== 1
        })}
      />
      <img
        src={Images.companyLogo}
        alt="iconlogo"
        className={clsx(classes.splash, {
          [classes.splashIn]: index === 2,
          [classes.splashOut]: index !== 2
        })}
      />
      <img
        src={clientImage}
        alt="namelogo"
        className={clsx(classes.splash, {
          [classes.splashIn]: index === 3,
          [classes.splashOut]: index !== 3
        })}
      />

      <Copyright />
    </div>
  );
};

export { Intro };
