import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  message: {
    // fontWeight: theme.typography.fontWeightBold
    // textTransform: 'uppercase'
    // color: theme.palette.primary.main
  },
  buttons: {
    textAlign: 'right',
    marginTop: theme.spacing(4),
    '& button': {
      margin: theme.spacing(1)
    }
  }
}));

export { useStyles };
