import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  ContactList: {
    id: 'components.ContactList.SideDrawer.ContactList',
    defaultMessage: 'Mis contactos'
  },
  searchContact: {
    id: 'components.ContactList.SideDrawer.searchContact',
    defaultMessage: 'Buscar en mis contactos'
  },
  settings: {
    id: 'components.ContactList.SideDrawer.settings',
    defaultMessage: 'Configurar chat'
  },
  hideChat: {
    id: 'components.ContactList.SideDrawer.hideChat',
    defaultMessage: 'Esconder'
  }
});