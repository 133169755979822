import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as AvatarMUI, Box, IconButton } from '@material-ui/core';
import { WithIndicator } from './WithIndicator';
import { useStyles } from './AvatarStyles';

const Avatar = (props) => {
  const { onClick, showIndicator, connectionStatus, src, size } = props;
  const classes = useStyles();

  const renderAvatar = () => (
    <AvatarMUI alt="avatar" className={classes[size]} src={src} />
  );

  return (
    <Box className={classes.container}>
      <IconButton aria-label="avatar" onClick={onClick}>
        {showIndicator ? (
          <WithIndicator status={connectionStatus}>
            {renderAvatar()}
          </WithIndicator>
        ) : (
          renderAvatar()
        )}
      </IconButton>
    </Box>
  );
};

Avatar.defaultpProps = {
  size: 'sm'
};

Avatar.propTypes = {
  onClick: PropTypes.func,
  showIndicator: PropTypes.bool,
  connectionStatus: PropTypes.bool,
  src: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'xl'])
};

export { Avatar };
