import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: _ref => {
    let anchor = _ref.anchor,
        noPaddingTop = _ref.noPaddingTop;
    return {
      padding: noPaddingTop ? '0 9px 9px 9px' : '9px',
      maxWidth: '100%',
      overflowWrap: 'anywhere',
      textAlign: 'start',
      lineHeight: '14px',
      color: anchor === 'right' ? theme.palette.text.primary : theme.palette.primary.contrastText
    };
  },
  spacer: {
    display: 'inline-block'
  }
}));
export { useStyles };