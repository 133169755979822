import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: 'auto',
    marginBottom: '10px',
    marginRight: '18px'
  },
  input: {
    borderRadius: '20px'
  }
}));