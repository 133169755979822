import _ from 'lodash';
import React, { useEffect } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { Diary } from 'components/Diary';
import { useRootProvider } from 'components/RootProvider';
import { useApi } from 'hooks';
import { useLoader } from '@octopy/react-loader';
import { useIntl } from 'react-intl';
import { reestructureActivity } from 'utils/reestructureActivity';
import { Alert, useModal } from '@octopy/react-modal';
import { getItem, setItem } from 'utils/persistentStorage';
import { messages } from '../../components/User/Profile/ProfileMessages';
import { useAuth } from '@octopy/react-auth';
import { useHistory } from 'react-router-dom';
import { useDrawer } from 'components/Drawer';
import { Video } from 'components/Video';
import './Home.scss';
import { UpdateUser } from 'components/User/UpdateUser/UpdateUser';

function Home() {
  const {
    handleChangeActivities,
    rootState,
    handleChangeSession
  } = useRootProvider();
  const theme = useTheme();
  const intl = useIntl();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleCloseDrawer, handleOpenDrawer } = useDrawer();
  const { event = {}, session } = rootState;
  const { user: sessionUser } = session;
  const { actions: authActions } = useAuth();
  const history = useHistory();

  const { plataform, image, video_id } = event;
  const [logoutService] = useApi({
    endpoint: 'v1/log-out',
    method: 'post'
  });

  const [getEventActivities] = useApi({
    endpoint: `events`,
    method: 'get'
  });
  const [updateUserService] = useApi({
    endpoint: 'v1/users',
    method: 'patch'
  });

  useEffect(() => {
    handleActivitiesResponse();
    handleValidate();
  }, []);

  const handleLogout = async () => {
    await logoutService();
    authActions.logout();
    handleCloseDrawer();
    window.localStorage.removeItem('event');
    history.push('/login');
  };

  const handleValidate = () => {
    const { name, phone, country, description, speciality } = sessionUser;

    if (!name || !phone || !country || !description || !speciality)
      handleOpenModal({
        body: (
          <Alert
            message={intl.formatMessage(messages.alertData)}
            onAccept={() => handleOpenUpdateUser()}
            onCancel={() => handleLogout()}
          />
        ),
        configProps: {
          maxWidth: 'xs'
        }
      });
  };

  const handleOpenUpdateUser = async () => {
    handleCloseDrawer();

    handleOpenModal({
      configProps: {
        fullWidth: true,
        maxWidth: 'xs',
        PaperProps: {
          style: {
            backgroundColor: theme.palette.common.drawerBackground
          }
        }
      },
      body: (
        <UpdateUser
          handleSubmitUpdateUser={handleSubmitUpdateUser}
          didmountFunction={handleValidate}
        />
      ),
      maxWidth: 'xs'
    });
  };

  const handleSubmitUpdateUser = async (userData) => {
    handleShowLoader(true);
    const mappedUserData = {
      ...userData,
      company_name: userData.compay,
      specialty: userData.speciality
    };

    var formData = new FormData();

    for (const property in mappedUserData) {
      if (property !== 'avatar' && property !== 'file') {
        formData.append(property, mappedUserData[property]);
      }
    }

    if (mappedUserData.avatar !== sessionUser.avatar) {
      const fileName = _.get(userData, ['file', 'name'], '');

      if (fileName !== '') formData.set('file', userData.file, fileName);
    }

    const data = await updateUserService({
      body: formData
    });

    const newUserInformation = _.get(data, 'payload', {});
    const speciality = newUserInformation.specialty;

    delete newUserInformation.specialty;

    setItem('session', {
      ...getItem('session'),
      ...newUserInformation,
      speciality,
      active: false
    });

    handleChangeSession({
      session: {
        token: getItem('token'),
        user: {
          ...sessionUser,
          ...newUserInformation,
          speciality,
          active: false
        }
      }
    });

    handleCloseModal();
    handleOpenDrawer();
    handleShowLoader(false);
    handleOpenModal({
      body: (
        <Alert message={intl.formatMessage(messages.successUpdateProfile)} />
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleActivitiesResponse = async () => {
    const activitiesResponse = await getEventActivities({
      body: {},
      urlParams: `${event._id}/activities`
    });

    const allActivityList = reestructureActivity(
      _.get(activitiesResponse, ['payload', 'items'])
    );

    handleChangeActivities({
      activityList: allActivityList
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isVideo = plataform !== 'image';

  const backgroundStyle = !isVideo
    ? {
        position: 'absolute',
        height: '100vh',
        width: '100%',
        background: `url("${image}") center center no-repeat padding-box`,
        backgroundSize: 'cover'
      }
    : {};

  return (
    <div style={backgroundStyle}>
      <Grid className="home">
        {isVideo && (
          <div className="video-background">
            <div className="video-foreground">
              <Video platform={plataform} id={video_id} allowControls={false} />
            </div>
          </div>
        )}

        <Diary />
      </Grid>
    </div>
  );
}

export { Home };
