import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  title: {
    id: 'components.Auth.PasswordRecovery.EmailForm.title',
    defaultMessage: 'Recuperar contraseña'
  },
  formInstructions: {
    id: 'components.Auth.PasswordRecovery.EmailForm.formInstructions',
    defaultMessage: 'Ingrese su correo electrónico, enviaremos un enlace de recuperación para verificar que es usted'
  }
});