var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from "./ChatContext";
import { useMessenger } from "../Messenger";
import { useNotifications } from "../Notifications";
import { useMessages } from "./hooks/useMessages";

const ChatProvider = _ref => {
  let children = _ref.children;

  const _useMessenger = useMessenger(),
        selectedChat = _useMessenger.selectedChat;

  const _useNotifications = useNotifications(),
        notifications = _useNotifications.notifications,
        deleteNotification = _useNotifications.deleteNotification;

  const pages = useRef([1, 2, 3]);

  const _useMessages = useMessages({
    pages
  }),
        handleSendMessage = _useMessages.handleSendMessage,
        messages = _useMessages.messages,
        setMessages = _useMessages.setMessages,
        pendingMessages = _useMessages.pendingMessages,
        replyingTo = _useMessages.replyingTo,
        _setReplyingTo = _useMessages.setReplyingTo,
        thereIsMore = _useMessages.thereIsMore;

  useEffect(() => notifications.some(notification => notification.id === selectedChat?.id) && deleteNotification(selectedChat), [selectedChat, notifications]);
  const contextValue = useMemo(() => ({
    messages,
    setMessages,
    pendingMessages,
    replyingTo,
    setReplyingTo: message => {
      _setReplyingTo(message);

      document.getElementById('chatInput').focus();
    },
    thereIsMore,
    handleSendMessage,
    pages
  }), [messages, pendingMessages, replyingTo, _setReplyingTo, thereIsMore, handleSendMessage, pages]);
  return _jsx(ChatContext.Provider, {
    value: contextValue
  }, void 0, children);
};

export { ChatProvider };