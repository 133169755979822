function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
export const useMedia = ref => {
  const _useState = useState(true),
        _useState2 = _slicedToArray(_useState, 2),
        paused = _useState2[0],
        setPaused = _useState2[1];

  const _useState3 = useState(false),
        _useState4 = _slicedToArray(_useState3, 2),
        muted = _useState4[0],
        setMuted = _useState4[1];

  const _useState5 = useState(0),
        _useState6 = _slicedToArray(_useState5, 2),
        progress = _useState6[0],
        setProgress = _useState6[1];

  const _useState7 = useState(100),
        _useState8 = _slicedToArray(_useState7, 2),
        volume = _useState8[0],
        setVolume = _useState8[1];

  const handlePlayClick = () => {
    if (ref.current && !document.fullscreenElement) ref.current.paused ? ref.current.play() : ref.current.pause();
  };

  useEffect(() => {
    const updateProgress = _ref => {
      let target = _ref.target;
      const duration = target.duration,
            currentTime = target.currentTime;
      setProgress(currentTime * 100 / duration);
    };

    const handleEnd = () => {
      setTimeout(() => {
        setProgress(0);
        setPaused(true);
        ref.current.currentTime = 0;
      }, 250);
    };

    const handlePause = () => {
      setPaused(true);
    };

    const handlePlay = () => {
      setPaused(false);
    };

    const handleVolumeChange = event => {
      event.target.volume === 0 ? setMuted(true) : setMuted(false);
      setVolume(event.target.volume * 100);
    };

    if (ref.current) {
      ref.current.addEventListener('timeupdate', updateProgress);
      ref.current.addEventListener('ended', handleEnd);
      ref.current.addEventListener('pause', handlePause);
      ref.current.addEventListener('playing', handlePlay);
      ref.current.addEventListener('volumechange', handleVolumeChange);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('timeupdate', updateProgress);
        ref.current.removeEventListener('ended', handleEnd);
        ref.current.removeEventListener('pause', handlePause);
        ref.current.removeEventListener('playing', handlePlay);
        ref.current.removeEventListener('volumechange', handleVolumeChange);
      }
    };
  }, [ref]);

  const handleChangeProgress = event => {
    const x = event.nativeEvent.offsetX;
    const width = event.target.offsetWidth;
    const percent = x * 100 / width;

    if (!!ref.current.duration && ref.current.duration !== Infinity) {
      ref.current.currentTime = ref.current.duration * (percent / 100);
    }
  };

  const handleChangeVolume = (_, value) => ref.current.volume = value / 100;

  return {
    paused,
    muted,
    progress,
    volume,
    handlePlayClick,
    handleChangeProgress,
    handleChangeVolume
  };
};