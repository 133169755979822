import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  headerStream: { heigth: '10%' },
  streamTitle: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize
    }
  },
  streamContainer: {
    width: '75%',
    transition: theme.transitions.create(['width', 'transform'], {
      duration: theme.transitions.duration.complex
    })
  },
  reduceStreamContainer: {
    width: '50%'
  },
  personalChat: {
    width: '0%',
    transition: theme.transitions.create(['width', 'transform'], {
      duration: theme.transitions.duration.complex
    })
  },
  reducePersonalChat: {
    width: '25%'
  },
  fullHeight: { height: '100%' },
  gridContainer: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 116px)'
    },
    [theme.breakpoints.down('md')]: {
      height: '100vh'
    }
  },
  iframe: {
    backgroundColor: '#000'
  }
}));

export { useStyles };
