import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  interested: {
    id: 'components.ProductCard.interested',
    defaultMessage: 'ME INTERESA'
  },
  seeMore: {
    id: 'components.Productcard.seeMore',
    defaultMessage: 'VER MÁS'
  }
});
