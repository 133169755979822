/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Alert, useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { Box, Typography, Button, useTheme } from '@material-ui/core';
import { useAuth } from '@octopy/react-auth';
import { Form, messages as formMessages } from '@octopy/react-form';
import { UserCard } from 'components/UserCard';
import { useDrawer } from 'components/Drawer';
import { useRootProvider } from 'components/RootProvider';
import * as helpers from './helpers';
import { messages } from './ProfileMessages';
import { useStyles } from './ProfileStyles';
import { useApi } from 'hooks';
import { UpdateUser } from '../UpdateUser';
import { getItem, setItem } from 'utils/persistentStorage';
import { useSecondaryApi } from 'hooks';

const Profile = ({ status }) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const { rootState, handleChangeSession } = useRootProvider();
  const { handleCloseDrawer, handleOpenDrawer } = useDrawer();
  const { actions: authActions } = useAuth();
  const { handleOpenModal, handleCloseModal } = useModal();
  const [user, setUser] = useState({});

  const { session } = rootState;
  const { user: sessionUser } = session;

  const [updateStatus] = useSecondaryApi({
    endpoint: `/participant/api-status`,
    method: 'post'
  });

  const [resetPasswordService] = useApi({
    endpoint: 'v1/reset-password',
    method: 'patch'
  });

  const [updateUserService] = useApi({
    endpoint: 'v1/users',
    method: 'patch'
  });

  const [logoutService] = useApi({
    endpoint: 'v1/log-out',
    method: 'post'
  });

  useEffect(() => {
    handleGetUserSession();
  }, [session]);

  const handleGetUserSession = async () => {
    const {
      name,
      avatar,
      email,
      user_id,
      phone,
      country,
      description,
      speciality,
      user_name,
      identification
    } = sessionUser;

    setUser({
      name,
      avatar,
      email,
      id: user_id,
      phone,
      country,
      description,
      speciality,
      username: user_name,
      identification
    });
  };

  const handleSubmit = async (formData) => {
    await resetPasswordService({
      body: { password: formData.password }
    });

    handleCloseDrawer();

    await handleLogout();

    handleOpenModal({
      body: (
        <Alert message={intl.formatMessage(messages.successUpdatePassword)} />
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleSubmitUpdateUser = async (userData) => {
    handleShowLoader(true);
    const mappedUserData = {
      ...userData,
      company_name: userData.compay,
      specialty: userData.speciality
    };

    let formData = new FormData();

    for (const property in mappedUserData) {
      if (property !== 'avatar' && property !== 'file') {
        formData.append(property, mappedUserData[property]);
      }
    }

    if (mappedUserData.avatar !== user.avatar) {
      const fileName = _.get(userData, ['file', 'name'], '');

      if (fileName !== '') formData.set('file', userData.file, fileName);
    }

    const data = await updateUserService({
      body: formData
    });

    const newUserInformation = _.get(data, 'payload', {});
    const speciality = newUserInformation.specialty;

    delete newUserInformation.specialty;

    setItem('session', {
      ...getItem('session'),
      ...newUserInformation,
      speciality,
      active: false
    });

    handleChangeSession({
      session: {
        token: getItem('token'),
        user: {
          ...sessionUser,
          ...newUserInformation,
          speciality,
          active: false
        }
      }
    });

    handleCloseModal();
    handleOpenDrawer();
    handleShowLoader(false);
    handleOpenModal({
      body: (
        <Alert message={intl.formatMessage(messages.successUpdateProfile)} />
      ),
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const handleLogout = async () => {
    await updateStatus({
      body: {
        eventId: _.get(getItem('event'), ['_id'], ''),
        participantId: _.get(getItem('session'), ['user_id'], ''),
        status: 'logout'
      }
    });
    await logoutService();
    authActions.logout();
    handleCloseDrawer();
    window.localStorage.removeItem('event');
    history.push('/login');
  };

  const handleValidate = () => {
    const { name, phone, country, description, speciality } = sessionUser;

    if (!name || !phone || !country || !description || !speciality)
      handleOpenModal({
        body: (
          <Alert
            message={'Para continuar es necesario que completes tu registro'}
            onAccept={() => handleOpenUpdateUser()}
            onCancel={() => handleLogout()}
          />
        ),
        configProps: {
          maxWidth: 'xs'
        }
      });
  };

  const handleOpenUpdateUser = async () => {
    handleCloseDrawer();

    handleOpenModal({
      configProps: {
        fullWidth: true,
        maxWidth: 'xs',
        PaperProps: {
          style: {
            backgroundColor: theme.palette.common.drawerBackground
          }
        }
      },
      body: (
        <UpdateUser
          user={user}
          handleSubmitUpdateUser={handleSubmitUpdateUser}
          didmountFunction={handleValidate}
        />
      ),
      maxWidth: 'xs'
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        {status ? (
          <Typography className={classes.status}>
            {intl.formatMessage(messages.connectionStatus, {
              status: 'available'
            })}
          </Typography>
        ) : (
          <Typography className={classes.status}>
            {intl.formatMessage(messages.connectionStatus, {
              status: 'busy'
            })}
          </Typography>
        )}
        <Box display="flex" justifyContent="center">
          <UserCard
            cardContent={{
              image: user.avatar,
              name: user.name,
              speciality: user.speciality,
              userID: user.identification,
              phone: user.phone,
              description: user.description,
              email: user.email
            }}
          />
        </Box>

        <Typography variant="body1" className={classes.formTitle}>
          {intl.formatMessage(messages.changePassword)}
        </Typography>

        <Form
          fields={helpers.getFields({ intl, messages: formMessages })}
          initialValues={helpers.getInitialValues()}
          validationRules={helpers.getValidationRules({
            intl,
            messages: formMessages
          })}
          buttonSubmitLabel={intl.formatMessage(messages.formSaveButton)}
          handleSubmit={handleSubmit}
          buttonSubmitProps={{
            variant: 'contained',
            color: 'primary',
            fullWidth: true,
            className: classes.formButton
          }}
        />

        <Box
          mt={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button color="primary" onClick={() => handleOpenUpdateUser()}>
            Actualizar datos
          </Button>
          <Button color="primary" onClick={handleLogout}>
            {intl.formatMessage(messages.logout)}
          </Button>
        </Box>
      </div>
    </div>
  );
};

Profile.propTypes = {
  status: PropTypes.bool
};

export { Profile };
