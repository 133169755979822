import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../helpers/colors";
export const useStyles = makeStyles(theme => ({
  container: {
    width: '435px',
    maxWidth: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  noUser: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeIcon: {
    position: 'absolute',
    top: '15px',
    right: '15px'
  },
  spacer: {
    flexGrow: 1,
    margin: '0 38px 0 38px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 15px 0 15px'
    },
    display: 'flex'
  },
  state: {
    color: theme.palette.primary.main,
    font: 'normal normal normal 16px/21px Roboto',
    margin: 'auto 0 auto auto'
  },
  dataContainer: {
    position: 'relative',
    margin: '0 38px 0 38px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 15px 0 15px'
    },
    padding: '102px 15px 25px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: hexToRGBA(theme.palette.getContrastText(theme.palette.common.mainBackground), 0.05),
    borderRadius: '8px',
    transform: 'translate(0, 36px)'
  },
  avatarContainer: {
    position: 'absolute',
    top: '-79px'
  },
  avatar: {
    width: '158px',
    height: '158px',
    boxShadow: theme.shadows[2]
  },
  primaryText: {
    color: theme.palette.primary.main,
    font: 'normal normal bold 16px/19px Montserrat',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'center'
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    font: 'normal normal normal 13px/16px Montserrat',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'center'
  },
  tertiaryText: {
    color: theme.palette.secondary.main,
    font: 'normal normal normal 13px/16px Montserrat',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'center'
  },
  marginTop: {
    marginTop: '10px'
  }
}));