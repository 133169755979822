import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  innerContainer: _ref => {
    let anchor = _ref.anchor,
        header = _ref.header,
        type = _ref.type;
    return {
      display: 'inline-grid',
      marginLeft: '10px',
      marginTop: header ? '3px' : '9px',
      marginRight: '12px',
      marginBottom: ['album', 'image', 'video'].includes(type) ? '6px' : 0,
      padding: '6px 23px 0 5px',
      width: 'calc(100% - 23px)',
      borderRadius: '5px',
      backgroundColor: '#ffffff25',
      borderLeft: "2px solid ".concat(anchor === 'left' ? hexToRGBA(theme.palette.primary.main, 0.6) : hexToRGBA(theme.palette.common.mainBackground, 0.6))
    };
  },
  text: _ref2 => {
    let anchor = _ref2.anchor;
    return {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: anchor === 'right' ? theme.palette.primary.contrastText : theme.palette.text.primary,
      fontSize: '11px'
    };
  },
  userName: {
    lineHeight: 1,
    fontWeight: 'bold'
  },
  messagePreview: {
    lineHeight: '16px'
  }
}));
export { useStyles };