import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  container: {
    width: '90%',
    height: '90%',
    backgroundColor: 'white',
    borderRadius: '7px',
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#858585',
      borderRadius: '5px',
      height: '10px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#7B7979',
      maxHeight: '10px'
    }
  }
}));
export { useStyles };