import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(358),
    width: theme.typography.pxToRem(290),
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.typography.pxToRem(358)
    }
  },
  image: { objectFit: 'cover', border: 0, padding: 200 },
  cardContainer: {
    paddingTop: theme.spacing(12),
    minHeight: theme.typography.pxToRem(300)
  },
  fontWeightBold: { fontWeight: 'bold' },
  descriptionText: {
    textAlign: 'center',
    // fontWeight: '100',
    lineHeight: theme.typography.pxToRem(19)
  },
  resumeText: {
    // fontWeight: '100',
    lineHeight: theme.typography.pxToRem(19)
  },
  email: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  alignCenterText: {
    textAlign: 'center'
  },
  minHeightCard: { minHeight: 350 },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 200,
    maxHeight: 250
  },
  resume: {
    height: 0,
    transition: theme.transitions.create(['height'], {
      duration: theme.transitions.duration.shortest
    })
  },
  resumeOn: {
    height: 300
  },
  spacingBottom: {
    marginBottom: theme.spacing(2)
  },
  scrollVertical: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0
    }
  }
}));

export { useStyles };
