import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  image: {
    id: 'components.Messages.ReplyToMessagePreview.image',
    defaultMessage: 'Imagen'
  },
  video: {
    id: 'components.Messages.ReplyToMessagePreview.video',
    defaultMessage: 'Video'
  },
  album: {
    id: 'components.Messages.ReplyToMessagePreview.album',
    defaultMessage: 'Album'
  },
  file: {
    id: 'components.Messages.ReplyToMessagePreview.file',
    defaultMessage: 'Archivo'
  },
  audio: {
    id: 'components.Messages.ReplyToMessagePreview.audio',
    defaultMessage: 'Mensaje de voz'
  }
});