var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import { useStyles } from "./ChatBarStyles";
import { Chat } from "../Chat";
import { ImagesCarouselProvider } from "../ImagesCarousel";
import { useMessenger } from "../Messenger";

const ChatBar = () => {
  const classes = useStyles();
  const messenger = useMessenger();
  return _jsx(ImagesCarouselProvider, {}, void 0, _jsx("div", {
    className: classes.container
  }, void 0, messenger.openChats.map(chat => _jsx(Chat, {
    chat: chat
  }, chat.id))));
};

export { ChatBar };