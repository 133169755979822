import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    padding: '7px 0 0 7px',
    '&.paddingBottom': {
      paddingBottom: '38px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '7px'
    },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '14px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    '&::-webkit-scrollbar-thumb': {
      border: '4px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      borderRadius: '99999px',
      backgroundColor: hexToRGBA(theme.palette.getContrastText(theme.palette.common.mainBackground), 0.15)
    }
  }
}));
export { useStyles };