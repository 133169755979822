import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Divider, IconButton } from '@material-ui/core';
import { AccountBoxOutlined, Input } from '@material-ui/icons';
import { useStyles } from './ActivityItemStyles';
import { useChallenge } from 'hooks/useChallenges';

const ActivityItem = ({ activity, handleEnterActivity }) => {
  const classes = useStyles();
  const { updateChallenge } = useChallenge();

  return (
    <Grid container direction="row" className={classes.spacingBottom}>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.iconContainer}
        >
          <AccountBoxOutlined />
        </Box>
      </Grid>
      <Grid item xs={10} className={classes.spacingLeft}>
        <Box width={1} display="flex">
          <Box width={0.8}>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.fontWeightBold}
            >
              {activity.scheduleTime} - {activity.description}
            </Typography>
            <Typography variant="body1">
              Ponente - {activity.speaker}
            </Typography>
            <Typography variant="body2" className={classes.lightText}>
              {activity.date
                ? format(new Date(activity.date), 'dd/MM/yyyy')
                : ''}
            </Typography>
          </Box>
          <Box
            width={0.2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              onClick={() => {
                updateChallenge('activity-schedule');
                handleEnterActivity(activity.id);
              }}
            >
              <Input />
            </IconButton>
          </Box>
        </Box>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
};

ActivityItem.propTypes = {
  event: PropTypes.shape({
    scheduleTime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    speaker: PropTypes.string.isRequired,
    date: PropTypes.string,
    activityID: PropTypes.string
  })
};

export { ActivityItem };
