import React, { useMemo, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ChallengeProviderContext,
  initialState
} from './ChallengeProviderContext';
import { Reducer } from './Reducer';

const ChallengeProvider = (props) => {
  const { children } = props;
  const [challengeState, challengeDispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    handleProgressChallenge();
  }, [challengeState]);

  const handleChallengeResponse = (itemChallenge) => {
    const hallChallengesResponse = [];
    const mainRoomChallengesResponse = [];
    const exhibitionRoomChallengesResponse = [];
    const partnerStageChallengesResponse = [];
    const challengeRoomChallengesResponse = [];
    const coffeRoomChallengesResponse = [];
    const quizzRoomChallengeResponse = [];
    const supportRoomchalengeResponse = [];

    _.map(itemChallenge, (item) => {
      if (_.get(item, ['challenges', 'room'], '') === 'hall') {
        hallChallengesResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          hallChallenges: hallChallengesResponse
        });
      }

      if (_.get(item, ['challenges', 'room'], '') === 'main_room') {
        mainRoomChallengesResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          mainRoomChallenges: mainRoomChallengesResponse
        });
      }

      if (_.get(item, ['challenges', 'room'], '') === 'coffe_room') {
        coffeRoomChallengesResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          coffeRoomChallenges: coffeRoomChallengesResponse
        });
      }

      if (_.get(item, ['challenges', 'room'], '') === 'exhibition_room') {
        exhibitionRoomChallengesResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          exhibitionRoomChallenges: exhibitionRoomChallengesResponse
        });
      }

      if (_.get(item, ['challenges', 'room'], '') === 'challenge') {
        challengeRoomChallengesResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          challengeRoomChallenges: challengeRoomChallengesResponse
        });
      }

      if (_.get(item, ['challenges', 'room'], '') === 'partner_stage') {
        partnerStageChallengesResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          partnerStageChallenges: partnerStageChallengesResponse
        });
      }

      if (_.get(item, ['challenges', 'room'], '') === 'quizz_room') {
        quizzRoomChallengeResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          quizChallenges: quizzRoomChallengeResponse
        });
      }

      if (_.get(item, ['challenges', 'room'], '') === 'support_room') {
        supportRoomchalengeResponse.push(item);
        handleChangeChallengeProvider({
          item: _.get(item, ['challenges', 'room'], ''),
          supportChallenges: supportRoomchalengeResponse
        });
      }
    });
  };
  const handleProgressChallenge = () => {
    _.map(challengeState, (challenge) => {
      _.map(challenge, (challengeItem) => {
        if (challengeItem.completed === false) {
        }
      });
    });
  };

  const handleChangeChallengeProvider = (payload) => {
    challengeDispatch({ type: payload.item, payload });
  };

  const contextValue = useMemo(
    () => ({
      handleChallengeResponse,
      challengeState
    }),
    [challengeState]
  );

  return (
    <ChallengeProviderContext.Provider value={contextValue}>
      {children}
    </ChallengeProviderContext.Provider>
  );
};

ChallengeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ChallengeProvider };
