import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  settingsTitle: {
    id: 'components.SettingsPrivateChat.settingsTitle',
    defaultMessage: 'Configuración del chat'
  },
  backgroundColor: {
    id: 'components.SettingsPrivateChat.backgroundColor',
    defaultMessage: 'Fondo'
  },
  windowColor: {
    id: 'components.SettingsPrivateChat.windowColor',
    defaultMessage: 'Tema del chat'
  },
  iconsColor: {
    id: 'components.SettingsPrivateChat.iconsColor',
    defaultMessage: 'Íconos'
  },
  myMessagesColor: {
    id: 'components.SettingsPrivateChat.myMessagesColor',
    defaultMessage: 'Mis mensajes'
  },
  FriendMessageColor: {
    id: 'components.SettingsPrivateChat.FriendMessageColor',
    defaultMessage: 'Mensajes del contacto'
  },
  save: {
    id: 'components.SettingsPrivateChat.save',
    defaultMessage: 'Guardar'
  }
});