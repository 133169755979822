import _map from "lodash/map";
import _set from "lodash/set";
import _isObject from "lodash/isObject";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _forEach from "lodash/forEach";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

const objectMapper = function objectMapper(template) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const mappedObject = {};

  _forEach(template, (value, key) => {
    let updatedValue = null;

    if (_isArray(template[key])) {
      const _template$key = _slicedToArray(template[key], 2),
            pathToFindData = _template$key[0],
            arrayTemplate = _template$key[1];

      updatedValue = arrayMapper(arrayTemplate, _get(data, pathToFindData, []));
    } else if (_isObject(template[key])) {
      updatedValue = objectMapper(template[key], data);
    } else {
      updatedValue = _get(data, value);
    }

    _set(mappedObject, key, updatedValue);
  });

  return mappedObject;
};

const arrayMapper = function arrayMapper(template) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return _map(data, item => {
    const mapper = _isArray(item) ? arrayMapper : objectMapper;
    if (mapper) return mapper(template, item);
    return item;
  });
};

const responseMapper = _ref => {
  let template = _ref.template,
      data = _ref.data;
  if (!data) throw new Error('Nothing to map. The data property is required');
  if (!template) return data;
  const mapper = _isArray(data) ? arrayMapper : objectMapper;
  return mapper(template, data);
};

export { responseMapper };