import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  label: {
    id: 'components.ProfileSummary.ShowMeAvailable.label',
    defaultMessage: 'Mostrarme disponible'
  },
  labelDisconect: {
    id: 'components.ProfileSummary.ShowMeAvailable.labelDisconect',
    defaultMessage: 'Mostrarme desconectado'
  }
});
