import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  userSummary: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  notifications: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none'
    // }
  },
  notificationButton: {
    padding: theme.typography.pxToRem(4)
  },
  notificationIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(24)
  },
  diaryPanel: {
    minWidth: 435,
    maxWidth: 435,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  fontWeightBold: { fontWeight: 'bold' },
  userName: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main
  },
  userSpeciality: {
    color: theme.palette.secondary.main
  }
}));

export { useStyles };
