import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerButton: {
    height: 10,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    }
  },
  mainContainer: {
    // height: 420,
    // [theme.breakpoints.down('sm')]: {
    //   height: 180
    // }
  },
  question: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      paddingTop: 20
    }
  },
  buttonAnswers: {
    textAlign: 'left',
    textTransform: 'inherit',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },

  closeIcon: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  },
  itemQuestion: {
    height: 60
  }
}));

export { useStyles };
