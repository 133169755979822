import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  image: _ref => {
    let anchor = _ref.anchor;
    return {
      cursor: 'pointer',
      objectFit: 'cover',
      maxHeight: '200px',
      [theme.breakpoints.only('xs')]: {
        maxHeight: '350px'
      },
      border: '2px solid transparent',
      borderRadius: 'inherit'
    };
  }
}));
export { useStyles };