import _ from 'lodash';

export const reducer = (state, action) => {
  switch (action.type) {
    case 'event':
      return {
        ...state,
        event: _.get(action, ['payload', 'event'], [])
      };
    case 'partner':
      return {
        ...state,
        partnerList: _.get(action, ['payload', 'partnerList'], [])
      };
    case 'activity':
      return {
        ...state,
        activityList: _.get(action, ['payload', 'activityList'], [])
      };
    case 'activitySchedule':
      return {
        ...state,
        activityScheduleList: _.get(action, ['payload', 'activityScheduleList'])
      };
    case 'awards':
      return {
        ...state,
        awardList: _.get(action, ['payload', 'awardList'], [])
      };
    case 'coffeRoomUser':
      return {
        ...state,
        coffeRoomUserList: _.get(action, ['payload', 'coffeRoomUserList'], [])
      };
    case 'faqs':
      return {
        ...state,
        faqList: _.get(action, ['payload', 'faqsList'], [])
      };
    case 'product':
      return {
        ...state,
        productList: _.get(action, ['payload', 'productList'], {})
      };
    case 'currentProgramDate':
      return {
        ...state,
        currentProgramDate: _.get(action, ['payload', 'currentProgramDate'], '')
      };
    case 'quiz':
      return {
        ...state,
        quizList: _.get(action, ['payload', 'quizList'], '')
      };
    case 'stream':
      return {
        ...state,
        stream: _.get(action, ['payload', 'stream'], '')
      };
    case 'notifications':
      return {
        ...state,
        notificationsPanelList: _.get(
          action,
          ['payload', 'notificationsPanelList'],
          ''
        )
      };
    case 'session':
      return {
        ...state,
        session: _.get(action, ['payload', 'session'], '')
      };
    case 'challengesUser':
      return {
        ...state,
        challengesUser: _.get(action, ['payload'], [])
      };
    case 'challengesActive':
      return {
        ...state,
        challengesActive: _.get(action, ['payload'], [])
      };
    default:
      return state;
  }
};
