/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getItem } from 'utils/persistentStorage';
import { config } from 'providers/config';

export const instanceSecondary = axios.create({
  baseURL: config.endpoints.userBackEndUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': config.endpoints.xApikey
  }
});

instanceSecondary.interceptors.request.use(
  (config) => {
    const token = getItem('token');

    if (token !== null) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

instanceSecondary.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response)
);
