import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography, Avatar } from '@material-ui/core';
import { messages } from '../ChallengeMessages';
import { useStyles } from '../ChallengeStyles';

const UserDescription = ({ avatar, name, category }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box mr={1}>
        <Avatar src={avatar} alt="Activity" />
      </Box>
      <Box>
        <Box pr={2}>
          <Typography variant="body1" className={classes.userCategory}>
            {name}
          </Typography>
          <Typography variant="body1" className={classes.userCategory}>
            {`${intl.formatMessage(messages.category, { name: 'Challenge' })} `}
            {category}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export { UserDescription };
