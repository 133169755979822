import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.CoffeeRoom.title',
    defaultMessage: 'Sala de café'
  },
  description: {
    id: 'components.CoffeeRoom.description',
    defaultMessage: 'Usuarios con disponibilidad de interacción'
  }
});
