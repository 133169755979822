import { useEffect, useRef } from 'react';
export const useDidUpdateEffect = (fn, dependences) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (!firstUpdate.current) {
      return fn();
    } else {
      firstUpdate.current = false;
    }
  }, dependences);
};