import logo from './svg/logo.svg';
import loader from './svg/loader.svg';
import mainBackround from './svg/platform-background.svg';
import companyLogo from './svg/company-logo.svg';
import companyIconLogo from './svg/company-icon-logo.svg';
import companyNameLogo from './svg/company-name-logo.svg';
import productLogo from './svg/product-logo.svg';
import productLogoSmall from './svg/product-logo-small.svg';
import platformEventLogo from './svg/platform-event-logo.svg';
import menu from './svg/menu.svg';
import greetingsLogin from './svg/greetings-login.svg';
import award from './svg/award.svg';

const Images = {
  logo,
  loader,
  mainBackround,
  companyLogo,
  companyIconLogo,
  companyNameLogo,
  productLogo,
  productLogoSmall,
  platformEventLogo,
  menu,
  greetingsLogin,
  award
};

export { Images };
