import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 17px 16px 6px'
  },
  icon: _ref => {
    let anchor = _ref.anchor;
    return {
      width: '37px',
      marginRight: '4px',
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        color: anchor === 'right' ? theme.palette.primary.contrastText : theme.palette.text.primary,
        fontSize: '24px'
      }
    };
  },
  file: _ref2 => {
    let anchor = _ref2.anchor;
    return {
      maxWidth: '260px',
      color: anchor === 'right' ? theme.palette.primary.contrastText : theme.palette.text.primary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      cursor: 'pointer',
      whiteSpace: 'nowrap'
    };
  }
}));
export { useStyles };