import _ from 'lodash';
import { useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Box,
  Hidden
} from '@material-ui/core';
import { messages } from './TableMessages';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles } from './TableStyles';

const StyledTableCell = withStyles((theme) => ({
  root: { borderBottom: 'none' },
  head: {
    backgroundColor: theme.palette.common.mainBackground,
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const Table = ({ headers, body, pagination }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bodyRows, setBodyRows] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!pagination) {
      setPage(0);
      setRowsPerPage(body.length);
    } else {
      setRowsPerPage(10);
      setPage(0);
    }

    setBodyRows(body);
  }, [pagination, body]);

  const handleTableCells = (row) => {
    const createOptions = [];

    _.forEach(headers, (headerItem, index) => {
      const finded = _.find(row, (rowCell) => headerItem.key === rowCell.key);

      createOptions.push(
        <StyledTableCell
          key={`content-row-table-${index}`}
          align={(finded && finded.align) || 'left'}
          className={classes.tableCellFont}
        >
          {finded ? finded.content : ''}
        </StyledTableCell>
      );
    });

    return createOptions;
  };

  return (
    <Box width={1}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <MaterialTable className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {_.map(headers, (header, index) => (
                <Hidden xsDown>
                  <StyledTableCell
                    key={`table-row-header-${header.label}`}
                    align={(header && header.align) || 'left'}
                  >
                    <Typography variant="subtitle2">{header.label}</Typography>
                  </StyledTableCell>
                </Hidden>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(
              body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
              (row, index) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`table-row-content-${index}`}
                >
                  {handleTableCells(row)}
                </TableRow>
              )
            )}
          </TableBody>
        </MaterialTable>
      </TableContainer>

      {pagination && (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.paginator}
        >
          <Grid>
            <Pagination
              count={Math.ceil(bodyRows.length / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              page={page + 1}
              color="primary"
            />
          </Grid>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="select-rows-perPage-label"
              id="select-rows-perPage"
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              <MenuItem value={10}>
                10/{intl.formatMessage(messages.page, { name: 'Table' })}
              </MenuItem>
              <MenuItem value={25}>
                25/{intl.formatMessage(messages.page, { name: 'Table' })}
              </MenuItem>
              <MenuItem value={30}>
                30/{intl.formatMessage(messages.page, { name: 'Table' })}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Box>
  );
};

export { Table };
