import _Box from "@material-ui/core/esm/Box";
const _excluded = ["message", "handleHide", "handleDeleteMessage", "handleEditMessage"];
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./MenuMessages";
import { useStyles } from "./MenuStyles";
import { useMessenger } from "../../../Messenger";
import { useChat } from "../../../Chat";

const Menu = _ref => {
  let message = _ref.message,
      handleHide = _ref.handleHide,
      handleDeleteMessage = _ref.handleDeleteMessage,
      handleEditMessage = _ref.handleEditMessage,
      props = _objectWithoutProperties(_ref, _excluded);

  const _useState = useState(props.position),
        _useState2 = _slicedToArray(_useState, 2),
        position = _useState2[0],
        setPosition = _useState2[1];

  const _useChat = useChat(),
        setReplyingTo = _useChat.setReplyingTo;

  useEffect(() => {
    const menu = document.getElementById("".concat(message.id, "Menu"));

    if (menu.offsetLeft + menu.offsetWidth + 7 > window.innerWidth) {
      setPosition(_ref2 => {
        let left = _ref2.left,
            top = _ref2.top;
        return {
          left: left - menu.offsetWidth,
          top
        };
      });
    }

    if (menu.offsetTop + menu.offsetHeight + 7 > window.innerHeight) {
      setPosition(_ref3 => {
        let left = _ref3.left,
            top = _ref3.top;
        return {
          left,
          top: top - menu.offsetHeight
        };
      });
    }
  }, []);

  const _useMessenger = useMessenger(),
        config = _useMessenger.config.chat.messages.options;

  const intl = useIntl();
  const classes = useStyles();

  const hiderWrapper = fn => () => {
    fn();
    handleHide();
  };

  return _jsx(_Box, {
    id: "".concat(message.id, "Menu"),
    style: position,
    className: classes.container
  }, void 0, config.response && _jsx(_Box, {
    onClick: hiderWrapper(() => setReplyingTo(message)),
    className: classes.item
  }, void 0, intl.formatMessage(messages.reply)), config.edit && _jsx(_Box, {
    onClick: hiderWrapper(() => handleEditMessage(message)),
    className: classes.item
  }, void 0, intl.formatMessage(messages.edit)), config.delete && _jsx(_Box, {
    onClick: hiderWrapper(() => handleDeleteMessage(message)),
    className: classes.item
  }, void 0, intl.formatMessage(messages.delete)));
};

export { Menu };