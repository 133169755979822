import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottomText: {
    textTransform: 'uppercase',
    letterSpacing: '0.2625rem'
  }
}));
export { useStyles };