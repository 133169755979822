import css from "./svg/cssLogo.svg";
import csv from "./svg/csvLogo.svg";
import xls from "./svg/excelLogo.svg";
import xlsx from "./svg/excelLogo.svg";
import file from "./svg/fileGeneric.svg";
import html from "./svg/htmlLogo.svg";
import js from "./svg/jsLogo.svg";
import pdf from "./svg/pdfLogo.svg";
import ppt from "./svg/powerpointLogo.svg";
import pptx from "./svg/powerpointLogo.svg";
import txt from "./svg/txtLogo.svg";
import doc from "./svg/wordLogo.svg";
import docx from "./svg/wordLogo.svg";
const Images = {
  css,
  csv,
  xls,
  xlsx,
  file,
  html,
  js,
  pdf,
  ppt,
  pptx,
  txt,
  doc,
  docx
};
export { Images };