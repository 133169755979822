import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  image: {
    id: 'components.Messages.MessagesComponents.RepliedMessagePreview.image',
    defaultMessage: 'Imagen'
  },
  video: {
    id: 'components.Messages.MessagesComponents.RepliedMessagePreview.video',
    defaultMessage: 'Video'
  },
  album: {
    id: 'components.Messages.MessagesComponents.RepliedMessagePreview.album',
    defaultMessage: 'Album'
  },
  file: {
    id: 'components.Messages.MessagesComponents.RepliedMessagePreview.file',
    defaultMessage: 'Archivo'
  },
  audio: {
    id: 'components.Messages.MessagesComponents.RepliedMessagePreview.audio',
    defaultMessage: 'Mensaje de voz'
  }
});