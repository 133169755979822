import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box } from '@material-ui/core';
import { UserDescription } from './UserDescription';
import { messages } from './CoffeeRoomMessages';
import { useStyles } from './CoffeeRoomStyles';
import { useRootProvider } from '../RootProvider';
import { Messenger } from '@octopy/react-messenger';
import { getItem } from 'utils/persistentStorage';

const CoffeeRoom = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { rootState } = useRootProvider();
  const { coffeRoomUserList } = rootState;

  return (
    <div className={classes.container}>
      <Typography variant="h4" color="primary">
        {intl.formatMessage(messages.title, { name: 'CoffeRoom' })}
      </Typography>
      <Box mt={3}>
        <Typography variant="subtitle2" color="secondary">
          {intl.formatMessage(messages.description, { name: 'CoffeRoom' })}
        </Typography>
      </Box>

      <div className={classes.messenger}>
        <Messenger
          eventId={
            _.get(getItem('session'), ['event'], '') ||
            _.get(getItem('event'), ['_id'], '') ||
            ''
          }
          user={{
            id: _.get(getItem('session'), ['user_id'], ''),
            username: _.get(getItem('session'), ['user_name'], ''),
            name: _.get(getItem('session'), ['name'], '')
          }}
        />
      </div>
    </div>
  );
};

CoffeeRoom.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { CoffeeRoom };
