import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    width: '50px',
    height: '50px',
    borderRadius: '3px',
    position: 'relative',
    marginLeft: '15px',
    boxShadow: 'rgba(136, 165, 191, 0.3) 6px 2px 16px 0px, rgba(255, 255, 255, 0.5) -6px -2px 16px 0px',
    backgroundColor: '#c6c7ca'
  },
  closeIcon: {
    position: 'absolute',
    right: '-8px',
    top: '-7px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    backgroundColor: '#EBECEE',
    [theme.breakpoints.down('xs')]: {
      width: '19px',
      height: '19px',
      right: '-7'
    }
  },
  hidden: {
    display: 'none'
  },
  button: {
    marginLeft: '4px',
    marginTop: '4px'
  }
}));
export { useStyles };