import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: appliedColors => ({
    height: '200px',
    backgroundColor: appliedColors.windowColor || theme.palette.primary.main,
    color: theme.palette.getContrastText(appliedColors.windowColor || theme.palette.primary.main),
    opacity: 0.9,
    zIndex: 9,
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
    [theme.breakpoints.down('xs')]: {
      width: '100vw'
    }
  }),
  title: {
    textAlign: 'center',
    marginBottom: '5px'
  },
  saveButton: appliedColors => ({
    alignSelf: 'flex-end',
    margin: '5px 10px',
    color: theme.palette.getContrastText(appliedColors.windowColor || theme.palette.primary.main),
    borderRadius: '5px',
    '&:hover': {
      color: appliedColors.windowColor || theme.palette.primary.main,
      backgroundColor: theme.palette.getContrastText(appliedColors.windowColor || theme.palette.primary.main),
      border: '0px'
    }
  })
}));
export { useStyles };