export const countdownInterval = function countdownInterval() {
  let timeMinutesToExpire = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
  let callback = arguments.length > 1 ? arguments[1] : undefined;
  let now = new Date();
  now.setMinutes(now.getMinutes() + timeMinutesToExpire);
  now = new Date(now);
  const intervalId = setInterval(function () {
    const date = new Date().getTime();
    const distance = now - date;

    if (distance < 0) {
      clearInterval(intervalId);
      if (callback) callback();
    }
  }, 500);
  return intervalId;
};