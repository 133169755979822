import { createContext } from 'react';

export const initialState = {
  partnerName: '',
  partnerRegion: '',
  mainImage: '',
  partnerImageHeader: '',
  title: '',
  partnerDescription: '',
  partnerPhrase: '',
  profilePartner: {
    roomImage: '',
    roomName: '',
    roomDate: '',
    roomTime: '',
    roomDescription: '',
    roomID: ''
  }
};

export const LandingPartnerContext = createContext();
