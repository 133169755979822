import { get } from 'lodash';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Images } from 'assets';
import { useRootProvider } from 'components/RootProvider';
import { MainBarMenu } from 'components/MainBarMenu';
import { ProfileSummary } from 'components/User';
import { useDrawer } from 'components/Drawer';
import { useStyles } from './HeaderStyles';

const Header = () => {
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const { location } = useHistory();
  const position = location.pathname === '/hall' ? 'absolute' : 'static';

  const { rootState } = useRootProvider();
  const { event } = rootState;
  const customLogo = get(event, ['company', 'image'], null);

  const handleOpenMenu = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right',
        variant: 'temporary', // oneOf ['permanent','persistent','temporary']
        transitionDuration: 1000
      },
      closeButton: true,
      showCopyright: true,
      body: (
        <Box pb={2} style={{ minWidth: 335, maxWidth: 400 }}>
          <MainBarMenu />
        </Box>
      )
    });
  };

  return (
    <AppBar position={position} className={classes.root}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <Link to="/" className={classes.link}>
            <img
              src={customLogo || Images.platformEventLogo}
              className={classes.headerLogo}
              alt="header-logo"
            />
          </Link>
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.sectionDesktop}
        >
          {/* <Language /> */}
          {/* <Theme /> */}
        </Box>

        <ProfileSummary />

        <IconButton
          aria-label="menu"
          onClick={handleOpenMenu}
          className={classes.iconButton}
        >
          <img alt="menu-icon" src={Images.menu} className={classes.menu} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export { Header };
