import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  tableContainer: {
    // width: 1400,
    // [theme.breakpoints.down('lg')]: {
    //   width: 1200
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: 640
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: 320
    // }
  },
  paginator: {
    marginTop: theme.spacing(1)
  }
}));

export { useStyles };
