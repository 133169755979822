import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../helpers/colors";
const useStyles = makeStyles(theme => {
  const chatOnlineBadge = {
    width: 10,
    height: 10,
    borderRadius: '50%',
    position: 'absolute',
    top: 30,
    right: 2
  };
  return {
    title: {
      textAlign: 'left',
      color: theme.palette.secondary.main,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1)
    },
    chatOnlineFriend: {
      padding: '11px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.1),
        '& $avatar': {
          transform: 'scale(1.02, 1.02)'
        }
      },
      borderRadius: '25px',
      transition: 'all 200ms'
    },
    avatar: {
      width: '45px',
      height: '45px',
      transition: 'all 200ms'
    },
    badge: {
      '& .MuiBadge-badge': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    chatOnlineName: {
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none'
    },
    chatOnlineState: {
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis',
      fontSize: 10,
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
      color: hexToRGBA(theme.palette.text.primary, 0.6)
    },
    nameMessages: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    texts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    }
  };
});
export { useStyles };