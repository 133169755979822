import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => {
  const colorToUse = appliedColors => typeof appliedColors === 'object' && appliedColors.hasOwnProperty('windowColor') ? appliedColors.windowColor : theme.palette.common.mainBackground;

  return {
    container: {
      width: '50px',
      backgroundColor: 'rgba(1,1,1,0)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    withIcon: {
      height: '175px'
    },
    withoutIcon: {
      height: '160px'
    },
    item: appliedColors => ({
      width: '40px',
      height: '40px',
      backgroundColor: colorToUse(appliedColors),
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      boxShadow: theme.shadows[2],
      transition: 'transform .2s',
      marginBottom: '7px',
      [theme.breakpoints.down('xs')]: {
        width: '45px',
        height: '45px'
      },
      '&:hover': {
        transform: 'scale(1.1)'
      }
    }),
    icon: appliedColors => ({
      color: theme.palette.getContrastText(colorToUse(appliedColors)),
      height: '0.9em',
      width: '0.9em',
      [theme.breakpoints.down('xs')]: {
        height: '0.94em',
        width: '0.94em'
      }
    })
  };
});
export { useStyles };