import _ from 'lodash';
import { config } from 'providers/config';
import { getFormatTime } from './formatDate';

export const reestructureActivity = (dirtyActivities) =>
  _.map(dirtyActivities, (activity) => ({
    chatIframe: activity.chat_iframe,
    id: activity._id,
    image: activity.image,
    // scheduleTime: new Date(activity.date).getHours(),
    scheduleTime: getFormatTime(activity.date),
    description: activity.description,
    name: activity.name,
    date: activity.date,
    speaker: activity.name,
    streamIframe: activity.stream_iframe,
    speciality: activity.background,
    resume: activity.resume,
    videoId: activity.video_id,
    platform: activity.plataform,
    company: config.company.name,
    nationality: 'México'
  }));
