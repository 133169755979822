import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    font: '25px/30px Roboto',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  description: {
    font: '16px/21px Roboto',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: '100'
  },
  sendButton: {
    marginTop: theme.spacing(1)
  }
}));
