import React from 'react';
import { Navigation } from 'routes/Navigation';
import { useStyles } from './AppStyles';

// TODO: Add auth logic for login & register components
const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.containerWithBg}>
      <Navigation />
    </div>
  );
};

export { App };
