import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  input: {
    flexGrow: 1,
    display: 'block',
    padding: '3px 0 3px 13px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&::after, &::before': {
        display: 'none'
      }
    },
    '& textarea': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '&.noEmojis': {
      padding: '3px 13px 3px 13px'
    }
  },
  emojiIcon: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.15)'
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1.2)'
    }
  }
}));
export { useStyles };