import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: theme.typography.pxToRem(44),
    minWidth: theme.typography.pxToRem(335),
    backgroundColor: '#000000A6',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'inherit',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  positionLeft: {
    left: '0'
  },
  positionRight: {
    right: '0 '
  },
  logo: {
    height: theme.typography.pxToRem(18),
    [theme.breakpoints.down('xs')]: {
      height: theme.typography.pxToRem(14)
    }
  },
  caption: {
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    color: theme.palette.common.captionTextColor
  }
}));
export { useStyles };