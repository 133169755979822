import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Button, IconButton, Grid } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import { useLoader } from '@octopy/react-loader';
import { useStyles } from './StreamSectionStyles';
import { useRootProvider } from 'components/RootProvider';
import { Video } from 'components/Video';
import { useChallenge } from 'hooks/useChallenges';

const StreamSection = () => {
  const classes = useStyles();
  const history = useHistory();
  const { rootState } = useRootProvider();
  const [openPersonalChat, setOpenPersonalChat] = useState(false);
  const { handleShowLoader } = useLoader();
  const { stream } = rootState;
  const { updateChallenge } = useChallenge();

  const { platform, videoId } = stream;

  useEffect(() => {
    updateChallenge('enter-event');
  }, []);

  useEffect(() => {
    if (stream.title.length === 0) history.push('./hall');
  }, [stream]);

  useEffect(() => {
    handleShowLoader(true);

    setTimeout(() => {
      handleShowLoader(false);
    }, 1200);
  }, []);

  const isLiveEvent = _.get(stream, 'videoId', '').includes('LIVE');
  const hasLiveChat = _.get(stream, 'chatUri', '').includes('chat');

  return (
    <Box width={1} className={classes.gridContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        width={1}
        px={3}
        className={classes.headerStream}
      >
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => history.goBack()}>
            <KeyboardBackspace fontSize="large" />
          </IconButton>
          <Typography
            variant="h4"
            color="primary"
            className={classes.streamTitle}
          >
            {stream.title}
          </Typography>
        </Box>

        {/* <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => setOpenPersonalChat((prevOpen) => !prevOpen)}
          >
            Chat
          </Button>
        </Box> */}
      </Box>

      <Box width={1} display="flex" style={{ height: '90%', padding: 4 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={hasLiveChat ? 9 : 12}>
            {/* <Box
              className={clsx(classes.streamContainer, classes.fullHeight, {
                [classes.reduceStreamContainer]: openPersonalChat
              })}
              width={1}
            > */}

            <Video
              isLive={isLiveEvent}
              platform={platform}
              id={videoId.replace('LIVE', '')}
              width="100%"
              height="100%"
            />

            {/* <iframe
              title="stream-player"
              className={classes.iframe}
              src={
                isLiveEvent
                  ? stream.streamUri
                  : _.replace(
                      stream.streamUri,
                      'https://vimeo.com',
                      'https://player.vimeo.com'
                    )
              }
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              width="100%"
              height="100%"
            ></iframe> */}
            {/* </Box> */}
          </Grid>

          {hasLiveChat && (
            <Grid item xs={12} md={3}>
              <Box width={1} className={classes.fullHeight}>
                <iframe
                  title="stream-chat"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  className={classes.iframe}
                  src={
                    stream.platform === 'youtube'
                      ? `${stream.chatUri}&embed_domain=${window.location.hostname}`
                      : stream.chatUri
                  }
                />
              </Box>
            </Grid>
          )}
        </Grid>

        {/*         
        <Box
          className={clsx(classes.personalChat, classes.fullHeight, {
            [classes.reducePersonalChat]: openPersonalChat
          })}
          style={{ height: '100%' }}
        >
          <iframe
            title="W3Schools Free Online Web Tutorials"
            width="100%"
            height="100%"
            src="https://fr.wikipedia.org/wiki/Main_Page"
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export { StreamSection };
