import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../helpers/colors";
export const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    overflow: 'auto'
  },
  divider: {
    border: 'none',
    borderTop: "1px solid ".concat(theme.palette.primary.main),
    margin: "auto auto ".concat(theme.spacing(1), "px auto"),
    width: '96%'
  },
  noUsersContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
      padding: '6px 13px',
      borderRadius: '15px',
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.07)
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  },
  paginationIcon: {
    color: theme.palette.primary.main
  }
}));