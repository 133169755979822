import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& span': {
      fontSize: theme.typography.subtitle2.fontSize
    }
  },
  listItem: {
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export { useStyles };
