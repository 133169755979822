import _get from "lodash/get";
import axios from 'axios';
import { getItem } from "../helpers/persistentStorage";
export const instance = axios.create({
  baseURL: process.env.REACT_APP_MESSENGER_BACKEND_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_MESSENGER_API_KEY
  }
}); // TODO: Config nedeed interceptors to check requests & responses

instance.interceptors.request.use(config => {
  const session = getItem('session');

  const token = _get(session, 'token', false);

  if (token) config.headers.Authorization = "Bearer ".concat(token);
  return config;
}, error => Promise.reject(error));
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});