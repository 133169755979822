import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnimationSwitch } from './AnimationSwitch';

const Navigation = () => (
  <Router>
    <AnimationSwitch />
  </Router>
);

export { Navigation };
