import _ from 'lodash';
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { routes } from './routes';
import './styles.css';

const AnimationSwitch = () => {
  let location = useLocation();
  const token = window.localStorage.getItem('token');

  return (
    <TransitionGroup>
      <CSSTransition timeout={500} classNames="fade" key={location.key}>
        <Switch location={location}>
          <Route
            path="/"
            exact
            render={() => <Redirect to={token ? '/hall' : '/login'} />}
          />
          {_.map(routes, (route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) =>
                route.auth ? (
                  token ? (
                    <div className="fill">
                      <route.layout>
                        <route.component {...props} />
                      </route.layout>
                    </div>
                  ) : (
                    <Redirect to="/login" />
                  )
                ) : (
                  <div className="fill">
                    <route.layout>
                      <route.component {...props} />
                    </route.layout>
                  </div>
                )
              }
            />
          ))}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export { AnimationSwitch };
