var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from "./AlbumStyles";

const Album = _ref => {
  let content = _ref.content,
      message = _ref.message,
      handleOpenCarousel = _ref.handleOpenCarousel,
      anchor = _ref.anchor;
  const classes = useStyles({
    anchor
  });
  if (typeof content === 'string') content = JSON.parse(content);
  return _jsx("div", {
    className: classes.container
  }, void 0, _jsx("div", {
    className: "".concat(classes.firstItem, " ").concat(content.length === 2 && 'onlyTwo'),
    onClick: () => handleOpenCarousel("".concat(message.id, "+0"))
  }, void 0, content[0].type === 'image' ? _jsx("img", {
    src: content[0].content,
    alt: "sentContent",
    className: "".concat(classes.image, " image1")
  }) : _jsx("video", {
    className: "".concat(classes.image, " image1")
  }, void 0, _jsx("source", {
    src: content[0].content
  }))), _jsx("div", {
    className: "".concat(classes.item, " ").concat(!content[2] && 'noMore'),
    onClick: () => handleOpenCarousel("".concat(message.id, "+1"))
  }, void 0, content[1].type === 'image' ? _jsx("img", {
    src: content[1].content,
    alt: "sentContent",
    className: "".concat(classes.image, " image2 ").concat(!content[2] && 'image3')
  }) : _jsx("video", {
    className: "".concat(classes.image, " image2 ").concat(!content[2] && 'image3')
  }, void 0, _jsx("source", {
    src: content[1].content
  }))), content[2] && _jsx("div", {
    className: "".concat(classes.item, " ").concat(content[3] && 'more'),
    onClick: () => handleOpenCarousel("".concat(message.id, "+2"))
  }, void 0, content[2].type === 'image' ? _jsx("img", {
    src: content[2].content,
    alt: "sentContent",
    className: "".concat(classes.image, " image3")
  }) : _jsx("video", {
    className: "".concat(classes.image, " image3")
  }, void 0, _jsx("source", {
    src: content[2].content
  })), content[3] && _jsx("div", {
    className: classes.moreButton
  }, void 0, content.length - 3, "+")));
};

export { Album };