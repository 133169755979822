import _Typography from "@material-ui/core/esm/Typography";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Images } from "../../assets";
import { messages } from "./CopyrightMessages";
import { useStyles } from "./CopyrightStyles";

const Copyright = props => {
  const logo = props.logo,
        text = props.text,
        position = props.position;
  const intl = useIntl();
  const classes = useStyles();
  return _jsx("div", {
    className: clsx(classes.container, {
      [classes.positionLeft]: position !== 'right',
      [classes.positionRight]: position === 'right'
    })
  }, void 0, _jsx("img", {
    alt: "logo",
    src: logo || Images.defaultLogo,
    className: classes.logo
  }), _jsx(_Typography, {
    variant: "caption",
    className: classes.caption
  }, void 0, text || intl.formatMessage(messages.caption)));
};

export { Copyright };