import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    padding: '0px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05)
  },
  hideIcon: {
    display: 'none'
  },
  rotateRight: {
    transform: 'rotate(45deg)',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1.2) rotate(45deg)'
    }
  },
  rotateLeft: {
    transform: 'rotate(90deg)',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1.2) rotate(90deg)'
    }
  },
  iconStyle: _ref => {
    let iconsColor = _ref.iconsColor,
        windowColor = _ref.windowColor;
    return {
      color: !!iconsColor ? iconsColor : !!windowColor ? theme.palette.getContrastText(windowColor) : theme.palette.text.primary,
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(1.2)'
      },
      '&.send': {
        color: theme.palette.primary.main
      }
    };
  },
  scaleIcon: {
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.15)'
    }
  },
  activeRecording: {
    transform: 'scale(1.3)'
  }
}));
export { useStyles };