import React from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Form } from '@octopy/react-form';
import { Box, Typography } from '@material-ui/core';
import * as helpers from './helpers';
import { messages } from './ImInterestedFormModalMessages';
import { useStyles } from './ImInterestedFormModalStyles';
import { getItem } from 'utils/persistentStorage';
import { useModal } from '@octopy/react-modal';
import { Alert } from 'components/Alert/Alert';
import { useApi } from 'hooks';

const ImInterestedFormModal = ({ partnerId }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();

  const [sendData] = useApi({
    endpoint: 'partners/im-interested',
    method: 'post'
  });

  const userSession = getItem('session');

  const handleSubmit = async (values) => {
    try {
      await sendData({
        body: {
          partner: values.partnerId,
          name: values.name,
          email: values.email,
          phone: values.phone,
          business_name: values.company,
          comments: values.comment
        }
      });

      handleOpenModal({
        body: <Alert message={intl.formatMessage(messages.successfullySent)} />,
        configProps: {
          maxWidth: 'xs'
        }
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Box pb={3}>
      <Typography className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Typography className={classes.description}>
        {intl.formatMessage(messages.description)}
      </Typography>
      <Form
        fields={helpers.getFields({
          intl,
          messages
        })}
        initialValues={helpers.getInitialValues({
          name: _.get(userSession, ['name'], ''),
          email: _.get(userSession, ['email'], ''),
          phone: _.get(userSession, ['phone'], ''),
          company:
            _.get(userSession, ['company_name'], '') === 'undefined'
              ? ''
              : _.get(userSession, ['company_name'], ''),
          comment: '',
          partnerId
        })}
        validationRules={helpers.getValidationRules({
          intl,
          messages
        })}
        buttonSubmitLabel={intl.formatMessage(messages.send)}
        handleSubmit={handleSubmit}
        buttonSubmitProps={{
          variant: 'contained',
          color: 'primary',
          fullWidth: true,
          className: classes.sendButton
        }}
      />
    </Box>
  );
};

export { ImInterestedFormModal };
