import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  audio: {
    display: 'none'
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 17px 8px 3px',
    marginBottom: '3px'
  },
  icon: {
    width: '18%',
    marginRight: '2%',
    borderRadius: '50%',
    boxSizing: 'border-box',
    '& svg': {
      color: 'white',
      fontSize: '31px'
    }
  },
  progress: {
    width: '80%',
    height: '3px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    cursor: 'pointer',
    '& > div': {
      height: '100%',
      backgroundColor: 'white',
      pointerEvents: 'none',
      transition: 'width 250ms linear'
    }
  },
  duration: _ref => {
    let anchor = _ref.anchor;
    return {
      color: anchor === 'left' ? theme.palette.text.primary : theme.palette.primary.contrastText,
      position: 'absolute',
      bottom: '26px',
      right: '9%',
      fontSize: '10px',
      padding: '2px 0px',
      opacity: '0.7',
      pointerEvents: 'none'
    };
  }
}));