import _Dialog from "@material-ui/core/esm/Dialog";
import _DialogActions from "@material-ui/core/esm/DialogActions";
import _DialogContent from "@material-ui/core/esm/DialogContent";
import _DialogTitle from "@material-ui/core/esm/DialogTitle";
const _excluded = ["maxWidth", "className", "titleClassName", "showDividers", "scroll"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import { useModal } from "./hooks/useModal";
import { useStyles } from "./ModalStyles";

const Modal = () => {
  const classes = useStyles();

  const _useModal = useModal(),
        modalState = _useModal.modalState,
        handleCloseModal = _useModal.handleCloseModal;

  const show = modalState.show,
        title = modalState.title,
        body = modalState.body,
        actionButtons = modalState.actionButtons,
        isDismissible = modalState.isDismissible,
        _modalState$configPro = modalState.configProps,
        configProps = _modalState$configPro === void 0 ? {} : _modalState$configPro;

  const maxWidth = configProps.maxWidth,
        className = configProps.className,
        titleClassName = configProps.titleClassName,
        showDividers = configProps.showDividers,
        scroll = configProps.scroll,
        otherProps = _objectWithoutProperties(configProps, _excluded);

  return React.createElement(_Dialog, _extends({
    open: show,
    "aria-labelledby": "app-modal",
    onClose: handleCloseModal,
    maxWidth: maxWidth,
    disableBackdropClick: isDismissible ? undefined : true,
    disableEscapeKeyDown: isDismissible ? undefined : true,
    scroll: scroll,
    className: classes.dialog
  }, otherProps), title && _jsx(_DialogTitle, {
    className: titleClassName
  }, void 0, title), _jsx(_DialogContent, {
    dividers: showDividers
  }, void 0, body), actionButtons && _jsx(_DialogActions, {}, void 0, actionButtons));
};

export { Modal };