import _ButtonBase from "@material-ui/core/esm/ButtonBase";
import _Box from "@material-ui/core/esm/Box";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from "./EditModalMessages";
import { useStyles } from "./EditModalStyles";
import { useMessenger } from "../../../Messenger";
import { MessageInput } from "../MessageInput/MessageInput";
import { useChat } from "../../hooks/useChat";

const EditModal = _ref => {
  let visible = _ref.visible,
      setVisible = _ref.setVisible,
      message = _ref.message;
  const intl = useIntl();
  const classes = useStyles();

  const _useMessenger = useMessenger(),
        config = _useMessenger.config.chat;

  const _useState = useState(''),
        _useState2 = _slicedToArray(_useState, 2),
        newText = _useState2[0],
        setNewText = _useState2[1];

  useEffect(() => {
    message && setNewText(message.type === 'text' ? message.content : message.caption);
    document.getElementById('chatEditInput').focus();
  }, [message]);

  const handleSubmit = () => {
    setVisible(false);
  };

  return _jsx(_Box, {
    className: "".concat(classes.backdrop, " ").concat(visible && 'visible'),
    onClick: () => setVisible(false)
  }, void 0, _jsx(_Box, {
    className: classes.container,
    onClick: event => event.stopPropagation()
  }, void 0, _jsx(_Box, {
    className: classes.title
  }, void 0, intl.formatMessage(messages.title)), _jsx(_Box, {
    className: "".concat(classes.inputContainer, " ").concat(!config.emojis && 'noEmojis')
  }, void 0, _jsx(MessageInput, {
    inputId: 'chatEditInput',
    value: newText,
    setValue: setNewText,
    handleSubmit: handleSubmit
  })), _jsx(_Box, {
    className: classes.buttonsContainer
  }, void 0, _jsx(_ButtonBase, {
    onClick: () => setVisible(false),
    className: "".concat(classes.button, " cancel")
  }, void 0, intl.formatMessage(messages.cancel)), _jsx(_ButtonBase, {
    className: classes.button,
    onClick: handleSubmit
  }, void 0, intl.formatMessage(messages.edit)))));
};

export { EditModal };