import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  changePassword: {
    id: 'components.User.Profile.changePassword',
    defaultMessage: 'Cambiar contraseña'
  },
  logout: {
    id: 'components.User.Profile.logout',
    defaultMessage: 'Cerrar sesión'
  },
  connectionStatus: {
    id: 'components.User.Profile.connectionStatus',
    defaultMessage:
      'Estado: {status, select, available {Disponible} busy {Ocupado} unseen {Invisible} disconnected {Desconectado} other {Disponible}}'
  },
  formSaveButton: {
    id: 'components.User.Profile.formSaveButton',
    defaultMessage: 'Guardar'
  },
  renameLabel: {
    id: 'components.User.Profile.renameLabel',
    defaultMessage: 'Nombre completo'
  },
  renamePlaceholder: {
    id: 'components.User.Profile.renamePlaceholder',
    defaultMessage: 'Cambiar nombre'
  },
  phoneLabel: {
    id: 'components.User.Profile.phoneLabel',
    defaultMessage: 'Numero de telefono'
  },
  phonePlaceholder: {
    id: 'components.User.Profile.phonePlaceholder',
    defaultMessage: 'Numero de telefono'
  },
  specialityLabel: {
    id: 'components.User.Profile.specialityLabel',
    defaultMessage: 'Especialidad'
  },
  specialityPlaceholder: {
    id: 'components.User.Profile.specialityPlaceholder',
    defaultMessage: 'Especialidad'
  },
  companyLabel: {
    id: 'components.User.Profile.companyLabel',
    defaultMessage: 'Compañia'
  },
  companyPlaceholder: {
    id: 'components.User.Profile.companyPlaceholder',
    defaultMessage: 'Compañia'
  },
  countryLabel: {
    id: 'components.User.Profile.countryLabel',
    defaultMessage: 'Nacionalidad'
  },
  countryPlaceholder: {
    id: 'components.User.Profile.countryPlaceholder',
    defaultMessage: 'Nacionalidad'
  },
  descriptionLabel: {
    id: 'components.User.Profile.descriptionLabel',
    defaultMessage: 'Descripción'
  },
  descriptionPlaceholder: {
    id: 'components.User.Profile.descriptionPlaceholder',
    defaultMessage: 'Descripción'
  },
  updateData: {
    id: 'components.User.Profile.updateData',
    defaultMessage: 'Actualizar datos'
  },
  requiredFieldError: {
    id: 'components.User.Profile.requiredFieldError',
    defaultMessage: 'Actualizar datos'
  },
  successUpdatePassword: {
    id: 'components.User.Profile.successUpdatePassword',
    defaultMessage:
      'Tu contraseña ha sido actualizada correctamente, vuelve a iniciar sesión.'
  },
  successUpdateProfile: {
    id: 'components.User.Profile.successUpdateProfile',
    defaultMessage: 'Tu información ha sido actualizada correctamente.'
  },
  alertData: {
    id: 'components.User.Profile.alertData',
    defaultMessage: 'Para continuar es necesario que completes tu registro'
  }
});
