import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  firstItem: {
    width: '100%',
    maxHeight: '91px',
    '&.onlyTwo': {
      maxHeight: '80px'
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '147px',
      '&.onlyTwo': {
        maxHeight: '125px'
      }
    }
  },
  item: _ref => {
    let anchor = _ref.anchor;
    return {
      position: 'relative',
      width: '50%',
      maxHeight: '67px',
      '&.noMore': {
        width: '100%',
        maxHeight: '80px'
      },
      '&.more': {
        '&::after': {
          content: '""',
          borderBottomRightRadius: anchor === 'left' ? '10px' : null,
          position: 'absolute',
          bottom: 2,
          left: 1,
          right: 2,
          top: 1,
          zIndex: 1,
          pointerEvents: 'none',
          backdropFilter: 'blur(2px)'
        }
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: '104px',
        '&.noMore': {
          maxHeight: '125px'
        }
      }
    };
  },
  image: _ref2 => {
    let anchor = _ref2.anchor;
    return {
      cursor: 'pointer',
      objectFit: 'cover',
      border: "1px solid transparent",
      borderRadius: 'inherit',
      width: '100%',
      height: '100%',
      '&.image1': {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderTopWidth: '2px',
        borderLeftWidth: '2px',
        borderRightWidth: '2px'
      },
      '&.image2': {
        borderBottomLeftRadius: anchor === 'right' ? '10px' : null,
        borderLeftWidth: '2px',
        borderBottomWidth: '2px'
      },
      '&.image3': {
        borderBottomRightRadius: anchor === 'left' ? '10px' : null,
        borderRightWidth: '2px',
        borderBottomWidth: '2px'
      }
    };
  },
  moreButton: _ref3 => {
    let anchor = _ref3.anchor;
    return {
      zIndex: 2,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '17px',
      pointerEvents: 'none',
      padding: '3px 12px',
      lineHeight: '1',
      borderRadius: '10px',
      backgroundColor: anchor === 'left' ? hexToRGBA(theme.palette.common.mainBackground, 0.65) : hexToRGBA(theme.palette.primary.main, 0.65),
      color: anchor === 'left' ? theme.palette.text.primary : theme.palette.primary.contrastText
    };
  }
}));
export { useStyles };