import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  container: {
    position: 'fixed',
    bottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  mediaPreviewContainer: {
    width: '125px',
    height: '80px',
    margin: '0 5px',
    cursor: 'pointer',
    '&.selected': {
      width: '150px',
      height: '100px'
    },
    '&.index0, &.index4': {
      opacity: '0.3'
    },
    '&.index1, &.index3': {
      opacity: '0.7'
    }
  },
  mediaPreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '5px'
  }
});
export { useStyles };