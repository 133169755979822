import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  row: _ref => {
    let anchor = _ref.anchor,
        lessMargin = _ref.lessMargin;
    return {
      width: '100%',
      display: 'flex',
      justifyContent: anchor === 'left' ? 'flex-start' : 'flex-end',
      '&:hover $sideContainer': {
        opacity: 1
      },
      marginBottom: lessMargin ? '2px' : '7px'
    };
  },
  container: _ref2 => {
    let anchor = _ref2.anchor,
        fullWidth = _ref2.fullWidth,
        hardMaxWidth = _ref2.hardMaxWidth;
    return {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
      boxShadow: theme.shadows[1],
      backgroundColor: anchor === 'left' ? hexToRGBA(theme.palette.primary.main, 0.07) : hexToRGBA(theme.palette.primary.main, 0.85),
      borderBottomLeftRadius: anchor === 'left' ? 0 : '10px',
      borderBottomRightRadius: anchor === 'right' ? 0 : '10px',
      marginLeft: anchor === 'right' ? 'auto' : null,
      width: fullWidth ? '80%' : 'auto',
      maxWidth: hardMaxWidth ? '270px' : '80%',
      position: 'relative'
    };
  },
  sideContainer: _ref3 => {
    let anchor = _ref3.anchor;
    return {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: anchor === 'left' ? 'flex-start' : 'flex-end',
      paddingLeft: anchor === 'left' ? '6px' : 0,
      paddingRight: anchor === 'right' ? '6px' : 0,
      '& svg': {
        fontSize: '24px',
        color: anchor === 'left' ? hexToRGBA(theme.palette.primary.main, 0.13) : hexToRGBA(theme.palette.primary.main, 0.85)
      },
      [theme.breakpoints.up('sm')]: {
        opacity: 0,
        transition: 'opacity 100ms'
      }
    };
  }
}));
export { useStyles };