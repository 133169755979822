import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    zIndex: 20,
    bottom: 0,
    left: 0,
    top: 0,
    right: 0,
    backgroundColor: hexToRGBA(theme.palette.common.mainBackground, 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 200ms',
    '&.visible': {
      opacity: 1,
      pointerEvents: 'all'
    }
  },
  container: {
    padding: theme.spacing(2),
    width: '90%',
    maxWidth: '320px',
    backgroundColor: theme.palette.common.mainBackground,
    border: '1px solid #2aa4d5',
    borderRadius: '10px'
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontSize: '13px',
    textAlign: 'left'
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    borderRadius: '3px',
    fontSize: '11px',
    padding: '6px',
    textTransform: 'uppercase',
    marginLeft: '11px',
    transition: 'background-color 250ms',
    '&:hover': {
      backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.3)
    },
    '&.cancel': {
      fontWeight: 100
    }
  }
}));
export { useStyles };