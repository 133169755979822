import { config } from 'providers/config';

const VIMEO = `https://player.vimeo.com/video/VIDEO_ID?autoplay=1;showinfo=0;loop=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=${config.videoPlayer.vimeoAppId}`;
const VIMEO_LIVE = `https://vimeo.com/event/VIDEO_ID/embed?autoplay=1;showinfo=0;loop=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=${config.videoPlayer.vimeoAppId}`;

const YOUTUBE =
  'https://www.youtube.com/embed/VIDEO_ID?controls=0&showinfo=0&rel=0&autoplay=1&playlist=VIDEO_ID&loop=1&vq=hd720';
const YOUTUBE_LIVE = YOUTUBE;

const getIframeUrl = ({ platform, id, isLive, allowControls }) => {
  let maskUrl = '';

  switch (platform) {
    case 'vimeo':
      maskUrl = isLive ? VIMEO_LIVE : VIMEO;

      if (!allowControls) maskUrl = `${maskUrl};controls=0;`;

      break;

    case 'youtube':
      maskUrl = isLive ? YOUTUBE_LIVE : YOUTUBE;
      break;

    default:
      break;
  }

  return maskUrl.replace(/VIDEO_ID/g, id);
};

export { getIframeUrl };
