import { v4 as uuidv4 } from 'uuid';
export const defaultConfig = {
  chat: {
    messages: {
      options: {
        delete: true,
        edit: true,
        response: true
      },
      infiniteNumber: 5
    },
    personalize: true,
    multimedia: {
      video: true,
      image: true,
      voiceMessage: true,
      file: true
    },
    emojis: true
  },
  chatList: {
    personalize: true,
    online: true,
    recents: true,
    infiniteNumer: 5
  },
  backend: {
    api: 'https://backend.com'
  }
};