import { useEffect, useRef } from 'react';
import { useChat } from "../../Chat";
export const useBottomMover = (containerId, userId) => {
  const _useChat = useChat(),
        messages = _useChat.messages,
        pendingMessages = _useChat.pendingMessages,
        replyingTo = _useChat.replyingTo,
        pages = _useChat.pages;

  const prevLastMessageRef = useRef();
  const prevPendingMessagesLength = useRef(pendingMessages.length);

  const moveToBottom = function moveToBottom() {
    let behavior = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'smooth';
    const container = document.getElementById(containerId);

    if (container) {
      container.scroll({
        top: container.scrollHeight,
        behavior
      });
    }
  };

  useEffect(() => {
    if (pages.current[0] === 1) {
      if (pendingMessages.length > prevPendingMessagesLength.current) {
        moveToBottom('auto');
      }

      prevPendingMessagesLength.current = pendingMessages?.length;
    }
  }, [pendingMessages]);
  useEffect(() => {
    if (messages) {
      const lastMessage = messages[messages.length - 1];
      const prevLastMessage = prevLastMessageRef.current;

      if (lastMessage && prevLastMessage && lastMessage.id !== prevLastMessage.id) {
        if (lastMessage.from === userId) {} else {
          const container = document.getElementById(containerId);

          if (container.offsetHeight - (container.scrollHeight - container.scrollTop) > -50) {
            moveToBottom('auto');
          }
        }
      }

      prevLastMessageRef.current = lastMessage;
    }
  }, [messages]);
  useEffect(() => {
    if (!!replyingTo) {
      const messagesContainer = document.getElementById('chatMessagesContainer');

      if (messagesContainer.offsetHeight + messagesContainer.scrollTop + 38 === messagesContainer.scrollHeight) {
        moveToBottom('auto');
      }
    }
  }, [replyingTo]);
  useEffect(() => moveToBottom('auto'), []);
};