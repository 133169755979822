export const mapUser = user => ({
  id: user._id,
  username: user.user_name,
  name: user.name,
  eventId: user.event,
  email: user.email,
  position: user.position,
  description: user.description,
  phone: user.phone,
  company: user.company
});