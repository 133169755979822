import React from 'react';
import _ from 'lodash';
import { Box, Slide } from '@material-ui/core';
import { AwardItem } from './AwardItem';
import { useChallengeProvider } from 'components/ChallengeProvider';

const AwardsPanel = () => {
  const { challengeState } = useChallengeProvider();

  return (
    <Box width={1} pl={2} display="flex" flexDirection="column">
      {_.map(challengeState, (award, index) =>
        _.map(award, (awardRoom) => (
          <Slide
            key={`award-box-${index}-${award.id_action}`}
            direction="left"
            in
            timeout={100 * index + 200 < 1500 ? 100 * index + 200 : 1500}
          >
            <Box>
              <AwardItem award={awardRoom} />
            </Box>
          </Slide>
        ))
      )}
    </Box>
  );
};

export { AwardsPanel };
