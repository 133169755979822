import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { messages } from './FaqMessages';
import {
  useStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './FaqStyles';
import { useRootProvider } from '../RootProvider';
import { useChallenge } from 'hooks/useChallenges';

const Faq = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { rootState } = useRootProvider();
  const { faqList } = rootState;
  const { updateChallenge } = useChallenge();

  const handleChange = (panel) => (event, isExpanded) => {
    updateChallenge('frequently-questions');
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" color="primary">
        {intl.formatMessage(messages.title, { name: 'Faq' })}
      </Typography>

      <Box className={classes.listContainer} px={2}>
        {_.map(faqList, (faq, index) => (
          <Accordion
            expanded={expanded === `faq${index}`}
            onChange={handleChange(`faq${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className={classes.heading}
                variant="subtitle2"
                color="primary"
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1"> {faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </div>
  );
};

Faq.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Faq };
