import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.MainRoom.title',
    defaultMessage: 'Sala principal'
  },
  switchSchedule: {
    id: 'components.MainRoom.switchSchedule',
    defaultMessage: 'Agendar'
  },
  buttonGetIn: {
    id: 'components.MainRoom.buttonGetIn',
    defaultMessage: 'Ingresar'
  }
});
