import Switch from '@material-ui/core/Switch';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(4)
  },
  showLabel: {
    color: theme.palette.secondary.main,
    fontSize: theme.spacing(1.5)
  }
}));

const CustomSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.common.disconnected,
    '&$checked': {
      color: theme.palette.common.connected
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.common.connected
    }
  },
  checked: {},
  track: { backgroundColor: theme.palette.primary.dark }
}))(Switch);

export { useStyles, CustomSwitch };
