import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../helpers/colors";
const useStyles = makeStyles(theme => ({
  noChat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& > span': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
      padding: '6px 13px',
      borderRadius: '15px',
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.07)
    }
  },
  container: _ref => {
    let windowColor = _ref.windowColor;
    return {
      backgroundColor: !!windowColor ? windowColor : theme.palette.common.mainBackground,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '10px',
      border: "1px solid ".concat(theme.palette.primary.main),
      height: '100%',
      boxShadow: theme.shadows[2],
      width: '80%',
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.modal,
        transition: 'opacity 150ms, transform 150ms',
        transform: 'translateY(100vh)',
        borderRadius: 0,
        border: 0,
        '&.visible': {
          transform: 'translateY(0)'
        }
      }
    };
  },
  headerChat: {
    width: '100%',
    height: '50px'
  },
  bodyChat: _ref2 => {
    let backgroundColor = _ref2.backgroundColor;
    return {
      height: '100%',
      width: '100%',
      backgroundColor: !!backgroundColor ? backgroundColor : theme.palette.common.mainBackground,
      overflow: 'hidden',
      position: 'relative'
    };
  },
  sectionFiles: {
    height: '150px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflowY: 'auto'
  },
  footerChat: {
    width: '100%',
    margin: '0px'
  },
  iconStyle: _ref3 => {
    let iconsColor = _ref3.iconsColor,
        windowColor = _ref3.windowColor;
    return {
      color: !!iconsColor ? iconsColor : !!windowColor ? theme.palette.getContrastText(windowColor) : theme.palette.getContrastText(theme.palette.primary.main),
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(1.2)'
      }
    };
  },
  mediaMenu: {
    position: 'absolute',
    bottom: '0',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      bottom: '70px'
    },
    [theme.breakpoints.down('xs')]: {
      bottom: '60px'
    }
  },
  settings: {
    position: 'fixed',
    bottom: '0',
    zIndex: 15
  },
  searchBar: {
    position: 'absolute',
    marginTop: '50px',
    zIndex: 4,
    width: '100%'
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
export { useStyles };