import _ from 'lodash';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { Box, Button, Zoom, Typography } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { DiaryProgram } from './DiaryProgram';
import { messages } from './DiaryMessages';
import { useStyles } from './DiaryStyles';
import { useDrawer } from 'components/Drawer';
import { ActivityPanel } from 'components/ActivityPanel';
import { useRootProvider } from '../RootProvider';
import { useChallenge } from 'hooks/useChallenges';

const Diary = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [showEvents, setShowEvents] = useState(false);
  const [programDayList, setProgramDayList] = useState([]);
  const { rootState, handleChangeCurrentProgramDate } = useRootProvider();
  const { activityList } = rootState;
  const { handleOpenDrawer } = useDrawer();
  const { updateChallenge } = useChallenge();

  useEffect(() => {}, [programDayList]);

  useEffect(() => {
    const itinerary = [];

    _.forEach(activityList, (activity) => {
      const findActivity = _.find(
        itinerary,
        (activityToCompare) =>
          activityToCompare.formatDate ===
          format(new Date(activity.date), 'dd/MM/yyyy')
      );

      const formatActivity = {
        ...activity,
        formatDate: format(new Date(activity.date), 'dd/MM/yyyy')
      };

      if (!findActivity) {
        itinerary.push(formatActivity);
      }
    });
    setProgramDayList(itinerary);
  }, [activityList]);

  const handleOpenProgram = (programDate) => {
    handleChangeCurrentProgramDate({ currentProgramDate: programDate });
    handleOpenDrawer({
      configProps: {
        anchor: 'right',
        transitionDuration: 1000
      },
      closeButton: true,
      title: <Typography variant="subtitle1">Actividades</Typography>,
      body: (
        <Box className={classes.activityPanelDrawer}>
          <ActivityPanel />
        </Box>
      )
    });
  };

  return (
    <Box
      className={clsx(classes.slideContainer, {
        [classes.slideTransition]: showEvents
      })}
    >
      <Button
        color="primary"
        className={classes.diaryButton}
        onClick={() => {
          updateChallenge('Schedule-icon');
          setShowEvents(!showEvents);
        }}
      >
        {intl.formatMessage(messages.diaryButton, { name: 'Diary' })}
        <ExpandLess
          className={clsx(classes.dropdown, {
            [classes.dropdownOpen]: showEvents,
            [classes.dropdownClosed]: !showEvents
          })}
        />
      </Button>
      <Box py={2} px={3} className={classes.eventsContainer}>
        <Box
          display="flex"
          className={clsx(classes.onScroll, classes.slideXContainer, {
            [classes.slideXTransition]: showEvents
          })}
        >
          {_.map(
            programDayList.sort((a, b) => (a.date > b.date && 1) || -1),
            (programDay, index) => (
              <Zoom key={`date-box-${index}`} in={showEvents} timeout={600}>
                <Box mr={3}>
                  <DiaryProgram
                    date={programDay.date}
                    onClickProgram={() => {
                      updateChallenge('program-day');
                      handleOpenProgram(programDay.formatDate);
                    }}
                  />
                </Box>
              </Zoom>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { Diary };
