import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  container: {
    padding: '2px 10px'
  },
  rectangleSelectColor: {
    width: '30px',
    height: '20px',
    borderRadius: '3px',
    border: "3px solid #ADADAD"
  },
  containerInput: {
    position: 'relative'
  },
  inputHidden: {
    width: '26px',
    height: '20px',
    position: 'absolute',
    opacity: '1%'
  },
  styleInputText: {
    display: 'inline-block',
    color: 'white',
    borderBottom: '1px solid white',
    paddingLeft: '10px'
  },
  titleColor: {
    marginBottom: '-15px'
  },
  labelColor: {
    width: '65px'
  }
}));
export { useStyles };