import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Login as LoginComponent,
  decodeToken,
  useAuth
} from '@octopy/react-auth';
import { getItem, setItem } from 'utils/persistentStorage';
import { Alert, useModal } from '@octopy/react-modal';
import { useApi, useQueryString } from 'hooks';
import { Intro } from 'views/Intro';
import { useRootProvider } from 'components/RootProvider';
import { messages } from './LoginMessages';
import { useSecondaryApi } from 'hooks';

const Login = () => {
  const intl = useIntl();
  const history = useHistory();
  const queryString = useQueryString();
  const { actions: authActions } = useAuth();
  const { handleChangeEvent, handleChangeSession } = useRootProvider();
  const { handleOpenModal, handleCloseModal } = useModal();
  const [showSplash, setShowSplash] = useState(false);

  useEffect(() => {
    const currentToken = getItem('token');
    const currentSession = getItem('session');

    if (currentToken && currentSession) {
      history.push('/hall');
    }
  }, []);

  const [updateStatus] = useSecondaryApi({
    endpoint: `/participant/api-status`,
    method: 'post'
  });

  const [loginService] = useApi({
    endpoint: 'v1/sign-in',
    method: 'post'
  });

  const [recoveryPassword] = useApi({
    endpoint: `v1/reset-password`,
    method: 'post'
  });

  const [getEventService] = useApi({
    endpoint: 'events',
    method: 'get'
  });

  const handleSubmit = async (data) => {
    try {
      const authResponse = await loginService({
        body: { ...data, event: queryString.event }
      });

      const token = _.get(authResponse, ['payload', 'access_token'], null);

      if (token) {
        const decodedData = decodeToken(token);

        const speciality = _.get(decodedData, 'specialty', '');
        const event = _.get(decodedData, 'event', '');

        delete decodedData.specialty;

        if (
          decodedData.role.name === 'participant' ||
          decodedData.role.name === 'coordinator'
        ) {
          const sessionData = {
            token,
            user: { ...decodedData, speciality },
            status: true
          };

          await authActions.login(sessionData);
          handleChangeSession({ session: sessionData });

          setShowSplash(true);

          const eventResponse = await getEventService({ urlParams: event });

          setItem('event', eventResponse.payload);
          handleChangeEvent({ event: eventResponse.payload });

          await updateStatus({
            body: {
              eventId: _.get(eventResponse, ['payload', '_id'], ''),
              participantId: _.get(getItem('session'), ['user_id'], ''),
              status: 'login'
            }
          });

          setItem('session', {
            ...getItem('session'),
            status: true
          });
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const submitEmail = async (formData) => {
    const responseRecoveryPass = await recoveryPassword({
      body: { email: formData.email }
    });

    if (responseRecoveryPass.headerResponse.code === 200) {
      handleCloseModal();

      handleOpenModal({
        body: (
          <Alert message={intl.formatMessage(messages.checkEmailMessage)} />
        ),
        configProps: {
          maxWidth: 'xs'
        }
      });
    }
  };

  return (
    <>
      <LoginComponent
        actions={{ handleSubmit, handleSubmitEmailToRecovery: submitEmail }}
      />
      {showSplash && <Intro />}
    </>
  );
};

export { Login };
