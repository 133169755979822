import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import { Images } from 'assets/images';
import { useStyles } from './AwardItemStyles';

const AwardItem = ({ award }) => {
  const classes = useStyles();

  return (
    <Box width={1} py={1}>
      <Box pr={2} display="flex" alignItems="center">
        <Box
          mr={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.iconContainer}
        >
          <img alt="award" src={Images.award} className={classes.awardIcon} />
        </Box>
        <Box style={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            color="primary"
            className={classes.fontWeightBold}
          >
            {_.get(award, ['challenges', 'name'], '')} -{' '}
            {_.get(award, ['challenges', 'score'], '')} pts
          </Typography>
        </Box>
        <Box pl={2}>
          {award.challenge_overcome ? (
            <CheckBox color="primary" fontSize="large" />
          ) : (
            <CheckBoxOutlineBlank fontSize="large" />
          )}
        </Box>
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};

AwardItem.propTypes = {
  award: PropTypes.shape({
    description: PropTypes.string.isRequired,
    score: PropTypes.string.isRequired,
    completed: PropTypes.bool
  })
};

export { AwardItem };
