import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor:
      theme.palette.common.introSliderBackground ||
      theme.palette.common.mainBackground,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    zIndex: 2
  },
  containerClientCompany: {
    backgroundColor:
      theme.palette.common.introSliderClientBackground ||
      theme.palette.common.mainBackground
  },
  splash: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.complex
    }),
    position: 'absolute',
    height: 150,
    width: 'auto'
  },
  splashIn: {
    opacity: 1
  },
  splashOut: {
    opacity: 0
  },
  startButton: {
    marginTop: theme.spacing(48)
  }
}));

export { useStyles };
