import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(8)
  },
  containerForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: theme.typography.pxToRem(350),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  productLogo: {
    height: theme.typography.pxToRem(60),
    [theme.breakpoints.down('xs')]: {
      height: theme.typography.pxToRem(40),
      marginTop: theme.spacing(2)
    }
  },
  instructions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold
  }
}));
export { useStyles };