import _IconButton from "@material-ui/core/esm/IconButton";
import _Close from "@material-ui/icons/esm/Close";
import _Box from "@material-ui/core/esm/Box";
import _Typography from "@material-ui/core/esm/Typography";
import _Avatar from "@material-ui/core/esm/Avatar";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { detectInnerClick } from "../../../../helpers/detectClick";
import { useStyles } from "./HeaderChatStyles";
import { useMessenger } from "../../../Messenger";
import { useUsers } from "../../../Users/hooks/useUsers";

const HeaderChat = _ref => {
  let appliedColors = _ref.appliedColors,
      searchBar = _ref.searchBar,
      settings = _ref.settings,
      selectedChat = _ref.selectedChat;
  const classes = useStyles(appliedColors);
  const refShowSearchBarButton = useRef(null);
  const refShowSettingsButton = useRef(null);
  const messenger = useMessenger();

  const _useUsers = useUsers(),
        connectedUsers = _useUsers.connectedUsers;

  detectInnerClick(refShowSearchBarButton, searchBar[1], searchBar[0]);
  detectInnerClick(refShowSettingsButton, settings[1], settings[0]);
  const isConnected = connectedUsers.contacts?.some(_ref2 => {
    let id = _ref2.id;
    return id === selectedChat?.id;
  });
  return _jsx("div", {
    className: classes.containerHeader
  }, void 0, _jsx(_Box, {
    display: "flex",
    alignItems: "center"
  }, void 0, _jsx(_Avatar, {
    src: selectedChat?.avatar,
    alt: "Activity",
    className: classes.avatar
  }), _jsx(_Typography, {
    variant: "variant",
    className: classes.userName
  }, void 0, "\xA0", selectedChat?.name)), _jsx(_Box, {
    display: "flex",
    alignItems: "center"
  }, void 0, _jsx(_IconButton, {
    component: "span",
    size: "small",
    onClick: () => messenger.handleCloseChat()
  }, void 0, _jsx(_Close, {
    className: classes.iconStyle
  }))));
};

export { HeaderChat };