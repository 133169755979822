import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  back: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  title: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1)
  },
  textOptions: {
    color: theme.palette.primary.main,
    fontSize: '15px'
  },
  options: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    padding: "0 ".concat(theme.spacing(1), "px")
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
    marginBottom: '11px'
  },
  select: {
    padding: '3px 10px',
    fontSize: '10px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  save: {
    alignSelf: 'flex-start',
    marginTop: '20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '5px',
    width: '90px'
  }
}));
export { useStyles };