import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LandingPartnerContext, initialState } from './LandingPartnerContext';

const LandingPartnerProvider = (props) => {
  const { children } = props;
  const [landingState, setLandingState] = useState(initialState);
  const changeDataPartner = (dataPartner) => setLandingState(dataPartner);

  const contextValue = useMemo(
    () => ({
      landingState,
      changeDataPartner
    }),
    [landingState]
  );

  return (
    <LandingPartnerContext.Provider value={contextValue}>
      {children}
    </LandingPartnerContext.Provider>
  );
};

LandingPartnerProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { LandingPartnerProvider };
