import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    display: 'flex'
  },
  content: {
    flex: '1 0 auto'
  },
  title: {
    fontWeight: 700,
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 12
  },
  subtitle: {
    fontStyle: 'italic',
    marginTop: 2,
    marginLeft: 12
  },
  media: {
    height: '100%',
    width: '100%'
  },
  dividerMargin: {
    width: '100%',
    color: 'primary',
    border: `solid ${theme.palette.primary.main}`,
    borderWidth: `1px 0px 0px 0px`
  },
  imageBackground: {
    width: '40%',
    backgroundColor:
      theme.palette.common[theme.palette.type === 'light' ? 'white' : 'black']
  },
  image: {
    width: '100%',
    minHeight: 160,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  },
  partnerInfo: {
    minHeight: 160,
    width: '60%',
    '& .data-container': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  }
}));

export { useStyles };
