import _ from 'lodash';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, Slide } from '@material-ui/core';
import { ActivityItem } from './ActivityItem';
import { useDrawer } from 'components/Drawer';
import { useRootProvider } from '../RootProvider';

const ActivityPanel = () => {
  const history = useHistory();
  const { handleCloseDrawer } = useDrawer();
  const { rootState } = useRootProvider();
  const { activityList, currentProgramDate } = rootState;
  const [activitiesByDate, setActivitiesByDate] = useState([]);

  useEffect(() => {
    const activitiesByDate = _.filter(
      activityList,
      (activity) =>
        format(new Date(activity.date), 'dd/MM/yyyy') === currentProgramDate
    );

    const sortedActivitiesByDate = activitiesByDate
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .reverse();

    setActivitiesByDate(sortedActivitiesByDate);
  }, [activityList, currentProgramDate]);

  return (
    <Box width={1} display="flex" flexDirection="column">
      {_.map(activitiesByDate, (activity, index) => (
        <Slide
          key={`event-box-${index}`}
          direction="left"
          in
          timeout={100 * index + 200 < 1500 ? 100 * index + 200 : 1500}
        >
          <Box>
            <ActivityItem
              activity={activity}
              handleClick={() => {
                history.push('/main-room');
                handleCloseDrawer();
              }}
            />
          </Box>
        </Slide>
      ))}
    </Box>
  );
};

export { ActivityPanel };
