import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { reducer } from './reducer';
import { RootProviderContext, initialState } from './RootProviderContext';

const RootProvider = (props) => {
  const { children } = props;
  const [rootState, rootDispatch] = useReducer(reducer, initialState);

  const handleChangeEvent = (payload) => {
    rootDispatch({ type: 'event', payload });
  };

  const handleChangeActivities = (payload) => {
    rootDispatch({ type: 'activity', payload });
  };

  const handleChangeAwards = (payload) => {
    rootDispatch({ type: 'awards', payload });
  };

  const handleChangeCoffeRoomUsers = (payload) => {
    rootDispatch({ type: 'coffeRoomUser', payload });
  };

  const handleChangeFaqs = (payload) => {
    rootDispatch({ type: 'faq', payload });
  };

  const handleChangeProducts = (payload) => {
    rootDispatch({ type: 'product', payload });
  };

  const handleChangeCurrentProgramDate = (payload) => {
    rootDispatch({ type: 'currentProgramDate', payload });
  };

  const handleChangeActivitySchedule = (payload) => {
    rootDispatch({ type: 'activitySchedule', payload });
  };

  const handleChangeQuizzes = (payload) => {
    rootDispatch({ type: 'quiz', payload });
  };

  const handleChangeStream = (payload) => {
    rootDispatch({ type: 'stream', payload });
  };

  const handleChangeNotificationsPanelList = (payload) => {
    rootDispatch({ type: 'notification', payload });
  };

  const handleChangeSession = (payload) => {
    rootDispatch({ type: 'session', payload });
  };

  const handleChangePartner = (payload) => {
    rootDispatch({ type: 'partner', payload });
  };

  const handleChangeUserChallenges = (payload) => {
    rootDispatch({ type: 'challengesUser', payload });
  };
  const handleChangeChallenges = (payload) => {
    rootDispatch({ type: 'challengesActive', payload });
  };

  const contextValue = useMemo(
    () => ({
      handleChangeEvent,
      handleChangeProducts,
      handleChangeAwards,
      handleChangeCoffeRoomUsers,
      handleChangeFaqs,
      handleChangeActivities,
      handleChangeCurrentProgramDate,
      handleChangeActivitySchedule,
      handleChangeQuizzes,
      handleChangeStream,
      handleChangeNotificationsPanelList,
      handleChangeSession,
      handleChangePartner,
      handleChangeUserChallenges,
      handleChangeChallenges,
      rootState
    }),
    [rootState]
  );

  return (
    <RootProviderContext.Provider value={contextValue}>
      {children}
    </RootProviderContext.Provider>
  );
};

RootProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { RootProvider };
