import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, IconButton, Button } from '@material-ui/core';
import { useStyles } from './TestQuestionsStyles';
import { useModal } from '@octopy/react-modal';
import { ValidateAnswer } from 'components/ValidateAnswer';
import CloseIcon from '@material-ui/icons/Close';
import { useApi } from 'hooks';
import { useChallenge } from 'hooks/useChallenges';

const answersPrefix = ['a', 'b', 'c', 'd', 'e'];

const TestQuestions = ({ data }) => {
  const classes = useStyles();
  const { handleCloseModal, handleOpenModal } = useModal();
  const { question, answers, rightAnswer, points, id } = data;
  const { updateChallenge } = useChallenge();

  const [AnswerUserPost] = useApi({
    endpoint: 'quizzes/answers',
    method: 'post'
  });

  const validateAnswer = async (responseUser, correctAnswer, pointsWin) => {
    const answerResponse = await AnswerUserPost({ body: responseUser });
    const codeStatus = _.get(answerResponse, ['headerResponse']);

    handleOpenModal({
      body: <ValidateAnswer codeStatus={codeStatus} pointsWin={pointsWin} />,
      configProps: {
        maxWidth: 'sm'
      }
    });
  };

  return (
    <div className={classes.mainContainer}>
      <Grid container justify="center" className={classes.mainContainer}>
        <Grid container justify="flex-end" className={classes.containerButton}>
          <Grid item>
            <IconButton aria-label="CloseIcon" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item className={classes.itemQuestion}>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.question}
            >
              {question}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          {answers.map((responsesAnswer, index) => (
            <Grid item xs={12}>
              <Button
                color="primary"
                className={classes.buttonAnswers}
                onClick={() => {
                  updateChallenge('answer-question');
                  validateAnswer(
                    { question: id, answer: index + 1 },
                    rightAnswer,
                    points
                  );
                }}
              >
                {`${answersPrefix[index]}) ${_.upperFirst(responsesAnswer)}`}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

TestQuestions.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { TestQuestions };
