import React from 'react';
import PropTypes from 'prop-types';
import { getIframeUrl } from './helpers';
import { useStyles } from './VideoStyles';

const Video = ({ platform, id, isLive, width, height, allowControls }) => {
  const classes = useStyles();
  const url = getIframeUrl({ platform, id, isLive, allowControls });

  return (
    <iframe
      // className={classes.player}
      title={`${platform}-player`}
      src={url}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder="0"
      width={width}
      height={height}
    />
  );
};

Video.defaultProps = {
  isLive: false,
  allowControls: true,
  width: undefined,
  height: undefined
};

Video.propTypes = {
  id: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(['vimeo', 'youtube']).isRequired,
  isLive: PropTypes.bool,
  allowControls: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
};

export { Video };
