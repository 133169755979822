var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from "./BottomPreviewStyles";
import { useImagesCarousel } from "../hooks/useImagesCarousel";

const BottomPreview = () => {
  const classes = useStyles();

  const _useImagesCarousel = useImagesCarousel(),
        handleChangeSelected = _useImagesCarousel.handleChangeSelected,
        selected = _useImagesCarousel.selected,
        album = _useImagesCarousel.album;

  const _useState = useState(),
        _useState2 = _slicedToArray(_useState, 2),
        visualMediaPreview = _useState2[0],
        setVisualMediaPreview = _useState2[1];

  useEffect(() => {
    if (album) {
      const selectedIndex = album.findIndex(media => media.id === selected.id);
      const visualMediaPreview = [album[selectedIndex - 2] || null, album[selectedIndex - 1] || null, album[selectedIndex], album[selectedIndex + 1] || null, album[selectedIndex + 2] || null];
      setVisualMediaPreview(visualMediaPreview);
    } else setVisualMediaPreview(null);
  }, [selected]);
  return visualMediaPreview ? _jsx("div", {
    className: classes.container
  }, void 0, visualMediaPreview.map((media, index) => _jsx("div", {
    className: "".concat(classes.mediaPreviewContainer, " index").concat(index, " ").concat(media?.id === selected.id && 'selected')
  }, void 0, media && (media.type === 'image' ? _jsx("img", {
    onClick: event => {
      event.stopPropagation();
      handleChangeSelected(media.id);
    },
    className: classes.mediaPreview,
    src: media.content,
    alt: "sentImage"
  }) : media.type === 'video' && _jsx("video", {
    onClick: event => {
      event.stopPropagation();
      handleChangeSelected(media.id);
    },
    className: classes.mediaPreview
  }, void 0, _jsx("source", {
    src: media.content
  })))))) : null;
};

export { BottomPreview };