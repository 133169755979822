import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 2,
    position: 'absolute',
    bottom: '0',
    width: '100%',
    marginLeft: '-7px',
    backgroundColor: theme.palette.common.mainBackground,
    padding: '8px 13px 3px 13px',
    display: 'flex',
    alignItems: 'center',
    borderTop: "1px solid ".concat(hexToRGBA(theme.palette.text.primary, 0.1)),
    borderBottom: "1px solid ".concat(hexToRGBA(theme.palette.text.primary, 0.1)),
    '&::after': {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
      content: '',
      width: '100%',
      height: '100%'
    }
  },
  textContainer: {
    flexGrow: 1,
    padding: '0 13px',
    overflow: 'hidden'
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: '25px',
    '&.close': {
      cursor: 'pointer'
    }
  },
  text: _ref => {
    let fromUser = _ref.fromUser;
    return {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.primary,
      fontSize: '11px'
    };
  },
  userName: {
    lineHeight: 1,
    fontWeight: 'bold'
  },
  messagePreview: {
    lineHeight: '16px'
  }
}));
export { useStyles };