import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fontWeightBold: { fontWeight: 'bold' },
  buttonContainer: {
    padding: 0,
    textAlign: 'left',
    textTransform: 'initial',
    minWidth: 400
  },
  divider: {
    border: 'none',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(3)
  },
  iconContainer: {
    minWidth: 56,
    minHeight: 56,
    maxHeight: 56,
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255, 0.03)'
  },
  lightText: {
    fontWeight: '100',
    lineHeight: theme.typography.pxToRem(19)
  },
  avatar: {
    width: theme.typography.pxToRem(56),
    height: theme.typography.pxToRem(56)
  },
  verticalSpacing: {
    marginBottom: theme.spacing(1)
  },
  descriptionActivity: {
    minWidth: 304,
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
      maxWidth: 200
    }
  }
}));

export { useStyles };
