import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1)
  },
  roomName: { paddingLeft: 25, paddingBottom: 15 },
  tableHead: {
    width: 180,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down(1126)]: {
      width: 110
    },
    [theme.breakpoints.down(769)]: {
      width: 100
    },
    [theme.breakpoints.down(650)]: {
      width: 60
    },
    [theme.breakpoints.down(426)]: {
      width: 55
    },
    [theme.breakpoints.down(376)]: {
      width: 30
    },
    [theme.breakpoints.down(321)]: {
      width: 25
    }
  },
  titleHead: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 8
    }
  },
  divider: {
    border: `solid ${theme.palette.primary.main}`,
    borderWidth: `0px 0px 2px 0px`
  },
  boxContainer: {
    paddingLeft: 20,
    paddingRight: 20
  },
  contentCard: {
    width: 180,
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down(1126)]: {
      width: 110
    },
    [theme.breakpoints.down(769)]: {
      width: 80
    },
    [theme.breakpoints.down(650)]: {
      width: 60
    },
    [theme.breakpoints.down(426)]: {
      width: 50
    },
    [theme.breakpoints.down(376)]: {
      width: 40
    },
    [theme.breakpoints.down(321)]: {
      width: 30
    }
  },
  cardSize: {
    width: 130,
    marginTop: 12,
    marginBottom: 12,
    height: 130,
    justifyContent: 'center',
    [theme.breakpoints.down(1126)]: {
      width: 80,
      height: 80
    },
    [theme.breakpoints.down(769)]: {
      width: 60,
      height: 60
    },
    [theme.breakpoints.down(650)]: {
      width: 55,
      height: 55
    },
    [theme.breakpoints.down(426)]: {
      width: 48,
      height: 48
    },
    [theme.breakpoints.down(376)]: {
      width: 40,
      height: 40
    },
    [theme.breakpoints.down(321)]: {
      width: 30,
      height: 30
    }
  },
  cardAction: {
    width: 130,
    height: 130,
    [theme.breakpoints.down(1126)]: {
      width: 80,
      height: 80
    },
    [theme.breakpoints.down(769)]: {
      width: 60,
      height: 60
    },
    [theme.breakpoints.down(650)]: {
      width: 55,
      height: 55
    },
    [theme.breakpoints.down(426)]: {
      width: 48,
      height: 48
    },
    [theme.breakpoints.down(376)]: {
      width: 40,
      height: 40
    },
    [theme.breakpoints.down(321)]: {
      width: 30,
      height: 30
    }
  },
  points: {
    textAlign: 'center',
    [theme.breakpoints.down(1126)]: {
      fontSize: 30
    },
    [theme.breakpoints.down(769)]: {
      fontSize: 24
    },
    [theme.breakpoints.down(650)]: {
      fontSize: 20
    },
    [theme.breakpoints.down(426)]: {
      fontSize: 18
    },
    [theme.breakpoints.down(376)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 10
    }
  }
}));

export { useStyles };
