import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: '80vh',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column'
  },
  messenger: {
    flexGrow: 1,
    marginTop: '33px',
    overflow: 'hidden'
  }
}));

export { useStyles };
