export const dark = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      connected: '#3F9C35',
      disconnected: '#E30613',
      mainBackground: '#00040F',
      loaderBackground: '#0D0D0D',
      drawerBackground: '#00040F',
      captionTextColor: '#FFFFFF7F',
      headerBackground: '#00040F40'
    },
    type: 'dark',
    primary: {
      main: '#2aa4d5',
      light: 'rgb(84, 182, 221)',
      dark: 'rgb(29, 114, 149)',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#c5ff3a',
      light: 'rgb(208, 255, 97)',
      dark: 'rgb(137, 178, 40)',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    background: {
      paper: '#040D1A',
      default: '#00040F',
      level2: '#333',
      level1: '#212121'
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24
    }
  },
  nprogress: {
    color: '#fff'
  }
};
