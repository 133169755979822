import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Box,
  Card,
  Switch,
  CardContent,
  Typography,
  Button,
  IconButton,
  Collapse
} from '@material-ui/core';
import { Avatar } from 'components/User/Avatar';
import { messages } from 'components/MainRoom/MainRoomMessages';
import { useStyles } from './UserCardStyles';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { useChallenge } from 'hooks/useChallenges';

const UserCard = ({ cardContent, getInAction, scheduleAction }) => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(false);
  const { updateChallenge } = useChallenge();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const changeSchedule = (e) => {
    scheduleAction({ isChecked: e.target.checked });
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="center" mb={-12}>
        <Avatar src={cardContent.image} size="xl" className={classes.image} />
      </Box>
      <Card
        className={clsx(classes.cardContainer, {
          [classes.minHeightCard]: scheduleAction
        })}
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <CardContent className={classes.cardContent}>
          <Box>
            {cardContent.name && (
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={`${classes.fontWeightBold} ${classes.capitalize}`}
                >
                  {cardContent.name}
                </Typography>
              </Box>
            )}

            <Box
              mt={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {cardContent.speciality && (
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.alignCenterText}
                >
                  {cardContent.speciality}
                </Typography>
              )}
              {cardContent.userID && (
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.alignCenterText}
                >
                  {`ID ${cardContent.userID}`}
                </Typography>
              )}

              {cardContent.date && (
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.alignCenterText}
                >
                  {format(new Date(cardContent.date), 'dd/MM/yyyy')}
                </Typography>
              )}
              {cardContent.scheduleTime && (
                <Typography variant="body1" color="secondary">
                  {cardContent.scheduleTime} Hrs
                </Typography>
              )}
            </Box>

            {cardContent.email && (
              <Typography variant="body2" className={classes.email}>
                {cardContent.email}
              </Typography>
            )}
          </Box>
          {cardContent.description && (
            <Box
              mt={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.scrollVertical}
            >
              <Typography variant="body2" className={classes.descriptionText}>
                {cardContent.description}
              </Typography>
            </Box>
          )}
        </CardContent>

        {scheduleAction && (
          <Box>
            <Box px={2} width={1} display="flex" justifyContent="space-between">
              <Button
                color="primary"
                onClick={() => {
                  getInAction(cardContent.id);
                  history.push('/stream');
                }}
              >
                {intl.formatMessage(messages.buttonGetIn, {
                  name: 'MainRoom'
                })}
              </Button>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  className={classes.descriptionText}
                >
                  {intl.formatMessage(messages.switchSchedule, {
                    name: 'MainRoom'
                  })}
                </Typography>

                <Switch
                  color="primary"
                  checked={cardContent.isSchedule}
                  onChange={(e) => {
                    updateChallenge('schedule-appointment');
                    changeSchedule(e);
                  }}
                />
                <IconButton
                  onClick={() => {
                    updateChallenge('describe-event');
                    handleExpandClick();
                  }}
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded
                  })}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={expanded}>
              <Box px={3} py={2} display="flex" flexDirection="column">
                <Typography
                  variant="body2"
                  className={clsx(classes.resumeText, classes.spacingBottom)}
                >
                  Información:
                </Typography>
                <Typography variant="body2" className={classes.resumeText}>
                  {cardContent.resume}
                </Typography>
              </Box>
            </Collapse>
          </Box>
        )}
      </Card>
    </Box>
  );
};

UserCard.propTypes = {
  userData: PropTypes.shape({
    userImage: PropTypes.string,
    userName: PropTypes.string,
    userPosition: PropTypes.string,
    userID: PropTypes.string,
    userDescription: PropTypes.string,
    speciality: PropTypes.string
  }),
  roomData: PropTypes.shape({
    roomImage: PropTypes.string,
    roomName: PropTypes.string,
    roomDate: PropTypes.string,
    roomTime: PropTypes.string,
    roomDescription: PropTypes.string,
    roomID: PropTypes.string
  }),
  getInAction: PropTypes.func,
  scheduleAction: PropTypes.func
};

export { UserCard };
