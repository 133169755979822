import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  sm: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    }
  },
  xl: {
    width: theme.spacing(21),
    height: theme.spacing(21),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(15),
      height: theme.spacing(15)
    }
  },
  availableStatus: {
    '& > span': {
      backgroundColor: theme.palette.common.connected,
      border: `4px solid ${theme.palette.common.mainBackground}`
    }
  },
  unavailableStatus: {
    '& > span': {
      backgroundColor: theme.palette.common.disconnected,
      border: `4px solid ${theme.palette.common.mainBackground}`
    }
  }
}));

export { useStyles };
