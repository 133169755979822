import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fontWeightBold: { fontWeight: 'bold' },
  divider: {
    border: 'none',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2)
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255, 0.03)'
  },
  lightText: {
    fontWeight: '100',
    lineHeight: theme.typography.pxToRem(19)
  },
  avatar: {
    width: theme.typography.pxToRem(56),
    height: theme.typography.pxToRem(56)
  },
  spacingBottom: {
    marginBottom: theme.spacing(2)
  },
  spacingLeft: {
    paddingLeft: theme.spacing(2)
  }
}));

export { useStyles };
