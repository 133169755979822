import { BaseLayout, FullScreenLayout, HeaderLogoLayout } from 'layouts';
import { Home } from 'views/Home';
// import { Example } from 'views/Example';
import { PartnerStage } from '../components/PartnerStage';
import { Error404 } from 'views/Error404';
import { MainRoom } from 'components/MainRoom';
import { Challenge } from 'components/Challenge';
import { Login, Register, PasswordRecovery } from 'components/Auth';
import { ExhibitionRoom } from 'components/ExhibitionRoom/ExhibitionRoom';
import { Quiz } from 'components/Quiz';
import { Faq } from 'components/Faq';
import { StreamSection } from 'components/StreamSection';

import { CoffeeRoom } from 'components/CoffeeRoom';
import { LandingPartner } from 'components/LandingPartner';

export const routes = [
  // {
  //   path: '/example',
  //   layout: BaseLayout,
  //   component: Example,
  //   exact: true
  // },
  {
    path: '/login/:email?',
    layout: HeaderLogoLayout,
    component: Login,
    exact: true
  },
  {
    path: '/register',
    layout: HeaderLogoLayout,
    component: Register,
    exact: true
  },
  {
    path: '/password-recovery/:tokenPassword?',
    layout: HeaderLogoLayout,
    component: PasswordRecovery,
    exact: true
  },
  {
    name: 'Hall',
    path: '/hall',
    layout: BaseLayout,
    component: Home,
    exact: true,
    auth: true,
    sidebar: true,
    idButton: 'hall-room'
  },
  {
    name: 'Sala principal',
    path: '/main-room',
    layout: BaseLayout,
    component: MainRoom,
    exact: true,
    auth: true,
    sidebar: true
  },
 /*  {
    name: 'Sala de exhibición',
    path: '/exhibition-room',
    layout: BaseLayout,
    component: ExhibitionRoom,
    exact: true,
    auth: true,
    sidebar: true
  }, */
  {
    name: 'Sala partner',
    path: '/partner-room',
    layout: BaseLayout,
    component: PartnerStage,
    exact: true,
    auth: true,
    sidebar: true
  },
  {
    name: 'Challenges',
    path: '/challenges',
    layout: BaseLayout,
    component: Challenge,
    exact: true,
    auth: true,
    sidebar: true,
    idButton: 'challenges-menu'
  },
  {
    name: 'Quiz',
    path: '/quiz',
    layout: BaseLayout,
    component: Quiz,
    exact: true,
    auth: true,
    sidebar: true,
    idButton: 'quiz-menu'
  },
  {
    name: 'Sala de café',
    path: '/cofee-room',
    layout: BaseLayout,
    component: CoffeeRoom,
    exact: true,
    auth: true,
    sidebar: true,
    idButton: 'coffe_room'
  },
  {
    path: '/landing-partner',
    layout: BaseLayout,
    component: LandingPartner,
    exact: true,
    auth: true
  },
  {
    name: 'Soporte',
    path: '/faq',
    layout: BaseLayout,
    component: Faq,
    exact: true,
    auth: true,
    sidebar: true,
    idButton: 'questions-menu'
  },
  {
    path: '/stream',
    layout: BaseLayout,
    component: StreamSection,
    exact: true,
    auth: true
  },
  {
    layout: FullScreenLayout,
    component: Error404
  }
];
