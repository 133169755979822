import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Form } from '@octopy/react-form';
import { Box, Typography } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import * as helpers from './helpers';
import { messages } from '../Profile/ProfileMessages';
import { useStyles } from '../Profile/ProfileStyles';
import { ImagePicker } from 'components/ImagePicker';
import { getItem } from 'utils/persistentStorage';

const UpdateUser = ({ user, handleSubmitUpdateUser, didmountFunction }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatarFile, setAvatarFile] = useState({});
  const sessionUser = getItem('session');

  useEffect(() => {
    setAvatarUrl(_.get(sessionUser, ['avatar'], ''));
  }, []);

  useEffect(
    () => () => {
      didmountFunction();
    },
    []
  );

  return (
    <Box pb={3}>
      <Box width={1} display="flex" justifyContent="center">
        <ImagePicker
          onChange={(logo) => {
            setAvatarUrl(logo.blobUrl);
            setAvatarFile(logo.file);
          }}
          Icon={Person}
          name="logoUrl"
          imageUrl={avatarUrl}
        />
      </Box>
      <Typography
        variant="body1"
        color="primary"
        className={classes.titleUpdateData}
      >
        {intl.formatMessage(messages.updateData)}
      </Typography>
      <Form
        fields={helpers.getFields({
          intl,
          messages: messages
        })}
        initialValues={helpers.getInitialValues({
          avatar: avatarUrl,
          name: _.get(sessionUser, ['name'], ''),
          phone: _.get(sessionUser, ['phone'], ''),
          speciality: _.get(sessionUser, ['speciality'], ''),
          country: _.get(sessionUser, ['country'], ''),
          description: _.get(sessionUser, ['description'], '')
        })}
        validationRules={helpers.getValidationRules({
          intl,
          messages: messages
        })}
        buttonSubmitLabel={intl.formatMessage(messages.formSaveButton)}
        handleSubmit={(formData) => {
          const dataRefactor = {
            ...formData,
            avatar: avatarUrl,
            file: avatarFile
          };

          handleSubmitUpdateUser(dataRefactor);
        }}
        buttonSubmitProps={{
          variant: 'contained',
          color: 'primary',
          fullWidth: true,
          className: classes.formButton
        }}
      />
    </Box>
  );
};

export { UpdateUser };
