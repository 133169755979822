import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Badge, Box, IconButton, Typography } from '@material-ui/core';
import { EventAvailable as EventAvailableIcon } from '@material-ui/icons';
import { useDrawer } from 'components/Drawer';
import { DiaryPanel } from 'components/DiaryPanel';
import { useRootProvider } from 'components/RootProvider';
import { Avatar } from '../Avatar';
import { Profile } from '../Profile';
import { useStyles } from './ProfileSummaryStyles';
import { useChallenge } from 'hooks/useChallenges';
import { ShowMeAvailable } from './ShowMeAvailable';
import { getItem, setItem } from 'utils/persistentStorage';

const ProfileSummary = () => {
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const { rootState } = useRootProvider();
  const [user, setUser] = useState({});
  const { session } = rootState;
  const { user: userSession } = session;
  const { updateChallenge } = useChallenge();

  const [showConnectionStatus, setShowConnectionStatus] = useState(
    _.get(getItem('session'), ['status'], false)
  );

  useEffect(() => {
    handleGetUserInformation();
  }, [session]);

  const handleGetUserInformation = async () => {
    const { name, avatar, email, speciality } = userSession;

    setUser({ name, avatar, email, speciality });
  };

  // const handleShowEmails = () => {
  //   handleOpenDrawer({
  //     configProps: {
  //       anchor: 'right',
  //       transitionDuration: 1000
  //     },
  //     closeButton: true,
  //     title: (
  //       <Typography className={classes.fontWeightBold} variant="subtitle2">
  //         Notificaciones
  //       </Typography>
  //     ),
  //     body: (
  //       <Box pl={2} pb={2} style={{ maxWidth: 435 }}>
  //         <NotificationPanel />
  //       </Box>
  //     )
  //   });
  // };

  const handleShowEvents = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right',
        transitionDuration: 1000
      },
      closeButton: true,
      title: (
        <Typography className={classes.fontWeightBold} variant="subtitle2">
          Mi agenda
        </Typography>
      ),
      body: (
        <Box pb={2} className={classes.diaryPanel}>
          <DiaryPanel />
        </Box>
      )
    });
  };

  const handleShowProfile = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right',
        variant: 'temporary', // oneOf ['permanent','persistent','temporary']
        transitionDuration: 1000
      },
      closeButton: true,
      body: <Profile status={showConnectionStatus} />
    });
  };

  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center" textAlign="right">
        <Box className={classes.userSummary}>
          <Typography
            color="primary"
            variant="body1"
            className={classes.userName}
          >
            {user.name}
          </Typography>
          <Typography
            color="primary"
            variant="caption"
            className={classes.userSpeciality}
          >
            {user.speciality}
          </Typography>
          <ShowMeAvailable
            status={showConnectionStatus}
            onChange={setShowConnectionStatus}
          />
        </Box>

        <Avatar
          src={user.avatar}
          onClick={handleShowProfile}
          size="sm"
          showIndicator
          connectionStatus={showConnectionStatus}
        />

        <Box className={classes.notifications}>
          <IconButton
            aria-label="events"
            className={classes.notificationButton}
            onClick={() => {
              updateChallenge('see-schedule');
              handleShowEvents();
            }}
          >
            <Badge color="secondary" badgeContent={1}>
              <EventAvailableIcon className={classes.notificationIcon} />
            </Badge>
          </IconButton>

          {/* <IconButton
            aria-label="emails"
            className={classes.notificationButton}
            onClick={handleShowEmails}
          >
            <Badge color="secondary" badgeContent={0}>
              <MailIcon className={classes.notificationIcon} />
            </Badge>
          </IconButton> */}
        </Box>
      </Box>
    </div>
  );
};

ProfileSummary.propTypes = {
  exampleProp: PropTypes.string
};

export { ProfileSummary };
