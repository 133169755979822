import React from 'react';
import PropTypes from 'prop-types';
import { Copyright } from '@octopy/react-copyright';
import { Images } from 'assets';
import { Loader } from '@octopy/react-loader';
import { BasicLoader } from '@octopy/react-loader';
import { Modal } from '@octopy/react-modal';
import { GoBackButton } from 'components/GoBackButton';
import { useStyles } from './HeaderLogoLayoutStyles';

const HeaderLogoLayout = (props) => {
  const classes = useStyles();
  const { showBackButton, children } = props;

  return (
    <>
      <Loader>
        <BasicLoader />
      </Loader>
      <Modal />
      <div className={classes.container}>
        <div
          className={`${classes.header} ${
            !showBackButton ? classes.logoToRight : ''
          }`}
        >
          {showBackButton && <GoBackButton />}
          <img alt="company-logo" src={Images.companyLogo} />
        </div>

        {children}

        <Copyright />
      </div>
    </>
  );
};

HeaderLogoLayout.defaultProps = {
  showBackButton: true
};

HeaderLogoLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showBackButton: PropTypes.bool
};

export { HeaderLogoLayout };
