import * as yup from 'yup';

const getInitialValues = (initialValuesUser) => ({
  name: initialValuesUser.name,
  phone: initialValuesUser.phone,
  speciality: initialValuesUser.speciality,
  country: initialValuesUser.country,
  description: initialValuesUser.description,
  ...initialValuesUser
});

const getFields = ({ intl, messages }) => [
  {
    name: 'name',
    label: intl.formatMessage(messages.renameLabel),
    placeholder: intl.formatMessage(messages.renamePlaceholder)
  },
  {
    name: 'phone',
    label: intl.formatMessage(messages.phoneLabel),
    placeholder: intl.formatMessage(messages.phonePlaceholder)
  },
  {
    name: 'speciality',
    label: intl.formatMessage(messages.specialityLabel),
    placeholder: intl.formatMessage(messages.specialityPlaceholder)
  },
  {
    name: 'country',
    label: intl.formatMessage(messages.countryLabel),
    placeholder: intl.formatMessage(messages.countryPlaceholder)
  },
  {
    type: 'textarea',
    name: 'description',
    label: intl.formatMessage(messages.descriptionLabel),
    placeholder: intl.formatMessage(messages.descriptionPlaceholder)
  }
];

const getValidationRules = ({ intl, messages }) => {
  const requiredFieldError = intl.formatMessage(messages.requiredFieldError);

  return yup.object({
    name: yup.string().required(requiredFieldError),
    phone: yup.string().required(requiredFieldError),
    speciality: yup.string().required(requiredFieldError),
    country: yup.string().required(requiredFieldError),
    description: yup.string().required(requiredFieldError)
  });
};

export { getFields, getInitialValues, getValidationRules };
