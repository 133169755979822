/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { useApi } from 'hooks';
import { getItem } from 'utils/persistentStorage';
import { useRootProvider } from 'components/RootProvider';
import { useLoader } from '@octopy/react-loader';

const useChallenge = () => {
  const {
    handleChangeChallenges,
    handleChangeUserChallenges,
    rootState
  } = useRootProvider();
  const { handleShowLoader } = useLoader();

  const userId = _.get(getItem('session'), ['user_id'], '');
  const eventId = _.get(getItem('event'), ['_id'], '');

  const [getChallenges] = useApi({
    endpoint: `/challenges`,
    method: 'get'
  });

  const [updateChallenges] = useApi({
    endpoint: `/challenges/user`,
    method: 'post'
  });

  const getChallengesUser = async () => {
    const challengeResponse = await getChallenges({
      urlParams: `${userId}/users`
    });

    await handleChangeUserChallenges(
      _.get(challengeResponse, ['payload', 'items'], [])
    );

    return _.get(challengeResponse, ['payload', 'items'], []);
  };

  const getChallengesActive = async () => {
    const challengeResponse = await getChallenges({
      urlParams: `?event=${eventId}`
    });

    await handleChangeChallenges(
      _.get(challengeResponse, ['payload', 'items'], [])
    );

    return _.get(challengeResponse, ['payload', 'items'], []);
  };

  const updateChallenge = async (idButton) => {
    const { challengesUser, challengesActive } = rootState;
    let challengesAvailable = [];
    let userChallenges = [];

    if (challengesUser.length === 0 && challengesActive.length === 0) {
      challengesAvailable = await getChallengesActive();
      userChallenges = await getChallengesUser();
    } else {
      challengesAvailable = challengesActive;
      userChallenges = challengesUser;
    }

    const filterChallenges = challengesAvailable.filter(
      (challenge) =>
        _.get(challenge, ['challenges', 'id_action'], '') === idButton &&
        _.get(challenge, ['challenges', 'enable'], false)
    );

    const challengeId = _.get(filterChallenges, [[0], 'challenges', '_id'], '');
    const itsDone = userChallenges.filter(
      (challenge) => challenge.challenge === challengeId
    );

    if (challengeId !== '' && itsDone.length === 0) {
      const newChallenges = await updateChallenges({
        body: {
          challenge: challengeId,
          user: userId,
          challenge_overcome: true,
          event: eventId
        }
      });

      handleChangeUserChallenges(
        _.get(newChallenges, ['payload', 'challenges'], [])
      );
    }

    handleShowLoader(false);
  };

  return { updateChallenge };
};

export { useChallenge };
