import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  questionKnowMore: {
    id: 'components.LandingPartner.questionKnowMore',
    defaultMessage: '¿Te gustaría descubrir más?'
  },
  interestedButton: {
    id: 'components.LandingPartner.interestedButton',
    defaultMessage: 'Me interesa'
  },
  exitButton: {
    id: 'components.LandingPartner.exitButton',
    defaultMessage: 'Salir'
  }
});
