import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  formInstructions: {
    id: 'components.Auth.Register.formInstructions',
    defaultMessage: 'Para registrarte en este evento, completa los siguientes datos.'
  },
  buttonSubmit: {
    id: 'components.Auth.Register.buttonSubmit',
    defaultMessage: 'Registrarme'
  },
  haveAccountText: {
    id: 'components.Auth.Register.haveAccountText',
    defaultMessage: '¿Ya tienes una cuenta?'
  },
  loginText: {
    id: 'components.Auth.Register.loginText',
    defaultMessage: 'Iniciar sesión'
  }
});