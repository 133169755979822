import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  reply: {
    id: 'components.Menu.reply',
    defaultMessage: 'Responder'
  },
  edit: {
    id: 'components.Menu.reply',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.Menu.reply',
    defaultMessage: 'Eliminar'
  }
});