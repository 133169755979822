import * as yup from 'yup';

const getInitialValues = (initialValues) => ({
  password: '',
  passwordConfirm: '',
  ...initialValues
});

const getInitialValuesUpdateUser = (initialValuesUser) => ({
  nameUser: initialValuesUser.name,
  phone: initialValuesUser.phone,
  speciality: initialValuesUser.speciality,
  company: initialValuesUser.company,
  country: initialValuesUser.country,
  description: initialValuesUser.description,
  ...initialValuesUser
});

const getFieldsUpdateUser = ({ intl, messages }) => [
  {
    name: 'name',
    label: intl.formatMessage(messages.renameLabel),
    placeholder: intl.formatMessage(messages.renamePlaceholder)
  },
  {
    name: 'phone',
    label: intl.formatMessage(messages.phoneLabel),
    placeholder: intl.formatMessage(messages.phonePlaceholder)
  },
  {
    name: 'speciality',
    label: intl.formatMessage(messages.specialityLabel),
    placeholder: intl.formatMessage(messages.specialityPlaceholder)
  },
  {
    name: 'company',
    label: intl.formatMessage(messages.companyLabel),
    placeholder: intl.formatMessage(messages.companyPlaceholder)
  },
  {
    name: 'country',
    label: intl.formatMessage(messages.countryLabel),
    placeholder: intl.formatMessage(messages.countryPlaceholder)
  },
  {
    type: 'textarea',
    name: 'description',
    label: intl.formatMessage(messages.descriptionLabel),
    placeholder: intl.formatMessage(messages.descriptionPlaceholder)
  }
];

const getFields = ({ intl, messages }) => [
  {
    type: 'password',
    name: 'password',
    label: intl.formatMessage(messages.newPasswordLabel),
    placeholder: intl.formatMessage(messages.newPasswordPlaceholder)
  }
  // {
  //   type: 'password',
  //   name: 'passwordConfirm',
  //   label: intl.formatMessage(messages.newPasswordConfirmLabel),
  //   placeholder: intl.formatMessage(messages.newPasswordConfirmPlaceholder)
  // }
];

const getValidationRules = ({ intl, messages }) => {
  const requiredFieldError = intl.formatMessage(messages.requiredFieldError);

  const passwordNoMatchesError = intl.formatMessage(
    messages.passwordNoMatchesError
  );

  return yup.object({
    password: yup
      .string()
      .required(requiredFieldError)
      .oneOf([yup.ref('password')], passwordNoMatchesError)
  });
};

const getValidationRulesUpdateUser = ({ intl, messages }) => {
  const requiredFieldError = intl.formatMessage(messages.requiredFieldError);

  return yup.object({
    name: yup.string().required(requiredFieldError),
    phone: yup.string().required(requiredFieldError),
    speciality: yup.string().required(requiredFieldError),
    company: yup.string().required(requiredFieldError),
    country: yup.string().required(requiredFieldError),
    description: yup.string().required(requiredFieldError)
  });
};

export {
  getFields,
  getInitialValues,
  getValidationRules,
  getInitialValuesUpdateUser,
  getFieldsUpdateUser,
  getValidationRulesUpdateUser
};
