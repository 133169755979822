import React from 'react';
import { Register as RegisterComponent } from '@octopy/react-auth';
import { useHistory } from 'react-router-dom';
import { useLoader } from '@octopy/react-loader';

const Register = () => {
  const history = useHistory();
  const { handleShowLoader } = useLoader();

  const handleSubmit = (data) => {
    handleShowLoader(true);

    setTimeout(() => {
      handleShowLoader(false);
      history.push('/hall');
    }, 1000);
  };

  return <RegisterComponent actions={{ handleSubmit }} />;
};

export { Register };
