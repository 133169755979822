import * as yup from 'yup';

const getInitialValues = (initialValuesUser) => ({
  name: initialValuesUser.name,
  email: initialValuesUser.email,
  phone: initialValuesUser.phone,
  company: initialValuesUser.company,
  comment: initialValuesUser.comment,
  ...initialValuesUser
});

const getFields = ({ intl, messages }) => [
  {
    name: 'name',
    label: intl.formatMessage(messages.nameLabel),
    placeholder: intl.formatMessage(messages.namePlaceholder)
  },
  {
    name: 'email',
    label: intl.formatMessage(messages.emailLabel),
    placeholder: intl.formatMessage(messages.emailPlaceholder)
  },
  {
    name: 'phone',
    label: intl.formatMessage(messages.phoneLabel),
    placeholder: intl.formatMessage(messages.phonePlaceholder)
  },
  {
    name: 'company',
    label: intl.formatMessage(messages.companyLabel),
    placeholder: intl.formatMessage(messages.companyPlaceholder)
  },
  {
    name: 'comment',
    type: 'textarea',
    label: intl.formatMessage(messages.commentLabel),
    placeholder: intl.formatMessage(messages.commentPlaceholder)
  }
];

const getValidationRules = ({ intl, messages }) => {
  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const emailErrorMessage = intl.formatMessage(messages.emailError);

  return yup.object({
    name: yup.string().required(requiredErrorMessage),
    email: yup.string().email(emailErrorMessage).required(requiredErrorMessage),
    phone: yup.string().required(requiredErrorMessage),
    company: yup.string(),
    comment: yup.string().required(requiredErrorMessage)
  });
};

export { getFields, getInitialValues, getValidationRules };
