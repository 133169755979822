import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Grid,
  Typography,
  Button,
  LinearProgress
} from '@material-ui/core';
import { Table } from 'components/Table';
import { useDrawer } from 'components/Drawer';
import { AwardsPanel } from 'components/AwardsPanel';
import { useModal } from '@octopy/react-modal';
import { UserDescription } from './UserDescription';
import { messages } from './ChallengeMessages';
import { useStyles } from './ChallengeStyles';
import { useApi } from 'hooks';
import { useChallengeProvider } from 'components/ChallengeProvider';
import { getItem } from 'utils/persistentStorage';
import { useChallenge } from 'hooks/useChallenges';
import { useRootProvider } from 'components/RootProvider';

const Challenge = () => {
  const intl = useIntl();
  const classes = useStyles();
  const idEvent = _.get(getItem('event'), ['_id'], '');
  const userName = _.get(getItem('session'), ['name'], '');
  const [scoreUsers, setRanking] = useState([]);
  const [categoriesState, setCategories] = useState([]);
  const [progressState, setProgress] = useState({});
  const [rankUserState, setRankUser] = useState([]);
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleChallengeResponse } = useChallengeProvider();
  const { updateChallenge } = useChallenge();
  const { rootState } = useRootProvider();
  const { handleOpenDrawer } = useDrawer();

  const [rankingService] = useApi({
    endpoint: `challenges/ranking/${idEvent}`,
    method: 'get'
  });

  const [getPolicies] = useApi({
    endpoint: 'challenges/policies',
    method: 'get'
  });
  const [getCategories] = useApi({
    endpoint: `/challenges/categories/${idEvent}`,
    method: 'get'
  });

  const handleGetRanking = async () => {
    const rankingResponse = await rankingService();

    setRanking(_.get(rankingResponse, ['payload', 'items'], []));
  };

  useEffect(() => {
    handleGetRanking();
    updateChallenge('see-ranking');
    handleGetCategories();
    handleRankUser();
  }, []);

  const handleGetCategories = async () => {
    const categoriesResponse = await getCategories();
    const categories = _.get(categoriesResponse, ['payload', 'categories'], []);

    setCategories(categories);
  };
  const handleRankUser = async () => {
    const rankingResponse = await rankingService();
    const rankingList = _.get(rankingResponse, ['payload', 'items'], []);
    const rankUser = _.filter(rankingList, {
      user_name: userName
    });

    setRankUser(rankUser[0]);
  };

  useEffect(() => {
    challengeOvercome();
  }, [rootState]);

  useEffect(() => {
    rankingUserLimit(rankUserState ? rankUserState.total_score : 'null');
  }, [rankUserState]);

  const rankingUserLimit = (category) => {
    _.map(categoriesState, (item) => {
      if (item.min_score < category && category < item.max_score) {
        setProgress(item);
      }
    });
  };

  const normalise = (value) =>
    ((value.user - value.limitMin) * 100) / (value.limitMax - value.limitMin);

  const challengeOvercome = async () => {
    const { challengesUser, challengesActive } = rootState;

    const itemsOvercome = [];

    _.map(challengesActive, (itemEvent) => {
      const filterChallenges = challengesUser.filter(
        (userChallenge) =>
          userChallenge.challenge ===
          _.get(itemEvent, ['challenges', '_id'], [])
      );

      if (filterChallenges.length !== 0) {
        itemsOvercome.push({ ...itemEvent, challenge_overcome: true });
      } else {
        itemsOvercome.push(itemEvent);
      }
    });

    handleChallengeResponse(itemsOvercome);
  };

  const handleChangePolicies = async () => {
    const PoliciesResponse = await getPolicies({
      body: {},
      urlParams: `${idEvent}`
    });

    const policiesChallenge = _.get(
      PoliciesResponse,
      ['payload', 'policies'],
      []
    );

    handleOpenModal({
      body: (
        <Box m={2}>
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ marginBottom: 10, fontWeight: 'bold' }}
            >
              {intl.formatMessage(messages.policiesChallenge)}
            </Typography>
            <Box mt={1} className={classes.scrollBox}>
              <Typography variant="body1">
                {_.get(
                  JSON.parse(policiesChallenge),
                  ['blocks', [0], 'text'],
                  intl.formatMessage(messages.errorPolices, {
                    name: 'Challenge'
                  })
                )}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button color="primary" onClick={() => handleCloseModal()}>
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      ),
      configProps: {
        maxWidth: 'sm'
      }
    });
  };

  const handleOpenChallenges = () => {
    handleOpenDrawer({
      configProps: {
        anchor: 'right',
        transitionDuration: 1000
      },
      closeButton: true,
      title: (
        <Typography variant="subtitle1">
          {intl.formatMessage(messages.challengesDesblock, {
            name: 'Challenge'
          })}
        </Typography>
      ),
      body: (
        <Box pb={2} style={{ maxWidth: 400 }}>
          <AwardsPanel />
        </Box>
      )
    });
  };

  const headerScoreTable = [
    { key: 'rank', label: 'Rank' },
    { key: 'user', label: 'Usuario' },
    { key: 'score', label: 'Total Score', align: 'center' }
  ];

  const contentScoreTable = _.map(scoreUsers, (userRank, index) => [
    {
      key: 'rank',
      content: (
        <Box>
          <Typography variant="h6" className={classes.rank}>
            {index + 1}
          </Typography>{' '}
        </Box>
      )
    },
    {
      key: 'user',
      content: (
        <UserDescription
          name={userRank.user_name}
          avatar={userRank.avatar}
          category={userRank.category}
        />
      )
    },
    {
      key: 'score',
      content: (
        <Typography className={classes.score} variant="h6" color="primary">
          {_.get(userRank, ['total_score'], 0)}
          {intl.formatMessage(messages.points, { name: 'Challenge' })}
        </Typography>
      ),
      align: 'center'
    }
  ]);

  return (
    <div className={classes.container}>
      <Grid container justify="space-between">
        <Grid item sm={7} xs={3}>
          <Typography
            variant="h4"
            color="primary"
            className={classes.titleHead}
          >
            {intl.formatMessage(messages.title, { name: 'Challenge' })}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={7} className={classes.linearProgressContainer}>
          <Typography variant="subtitle2">
            {intl.formatMessage(messages.category, { name: 'Challenge' })}{' '}
            <span className={classes.category}>
              {rankUserState
                ? rankUserState.category
                : intl.formatMessage(messages.noClasificate, {
                    name: 'Challenge'
                  })}
            </span>
          </Typography>

          <Box width={1} my={1}>
            <LinearProgress
              variant="determinate"
              value={normalise(
                rankUserState
                  ? {
                      user: _.get(rankUserState, ['total_score'], 0),
                      limitMin: _.get(progressState, ['min_score'], 0),
                      limitMax: _.get(progressState, ['max_score'], 0)
                    }
                  : { user: 0, limitMin: 0, limitMax: 10 }
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          <Typography variant="caption">
            {intl.formatMessage(messages.nextCategory, { name: 'Challenge' })}{' '}
            {rankUserState
              ? _.get(progressState, ['max_score'], 0) -
                _.get(rankUserState, ['total_score'], 0)
              : 0}
            {intl.formatMessage(messages.points, { name: 'Challenge' })}
          </Typography>
        </Box>
      </Box>
      <Box sm={5} pt={2}>
        <Typography variant="body1">
          {intl.formatMessage(messages.description, { name: 'Challenge' })}
        </Typography>
      </Box>
      <Box
        py={2}
        px={2}
        pb={3}
        width={240}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          color="primary"
          onClick={() => {
            handleChangePolicies();
            handleOpenModal();
            updateChallenge('politics-challenge');
          }}
        >
          {intl.formatMessage(messages.policiesButton, { name: 'Challenge' })}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            handleOpenChallenges();
            updateChallenge('achievements-user');
          }}
        >
          {intl.formatMessage(messages.awardsButton, { name: 'Challenge' })}
        </Button>
      </Box>
      <Typography>
        {intl.formatMessage(messages.globalRanking, { name: 'Challenge' })}
      </Typography>

      <Box display="flex" justifyContent="center" pt={2}>
        <Table headers={headerScoreTable} body={contentScoreTable} pagination />
      </Box>
    </div>
  );
};

export { Challenge };
