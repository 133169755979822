import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'components/Header';
import { Loader } from '@octopy/react-loader';
import { BasicLoader } from '@octopy/react-loader';
import { Modal } from '@octopy/react-modal';
import { Drawer } from 'components/Drawer';
import './BaseLayout.scss';

const BaseLayout = ({ children }) => (
  <>
    <Loader>
      <BasicLoader />
    </Loader>
    <Modal />

    <Header />
    <div className="base-layout">{children}</div>
    <Drawer />
  </>
);

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export { BaseLayout };
