import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  congratulations: {
    id: 'components.ValidateAnswer.congratulations',
    defaultMessage: '¡FELICIDADES!'
  },
  missed: {
    id: 'components.ValidateAnswer.missed',
    defaultMessage: '¡QUE PENA!'
  },
  messageWinner: {
    id: 'components.ValidateAnswer.messageWinner',
    defaultMessage: 'OBTUVISTE '
  },
  youLost: {
    id: 'components.ValidateAnswer.youLost',
    defaultMessage: 'NO LOGRASTE OBTENER LOS PUNTOS, INTENTA CON OTRA PREGUNTA'
  },
  questionAnswered: {
    id: 'components.ValidateAnswer.questionAnswered',
    defaultMessage: 'YA CONTESTASTE ESTA PREGUNTA, INTENTA CON OTRA'
  },
  okButton: {
    id: 'components.ValidateAnswer.okButton',
    defaultMessage: 'OK'
  }
});
