/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { HttpErrorAlert, useModal } from '@octopy/react-modal';
import { useAuth } from '@octopy/react-auth';
import { instanceSecondary } from 'providers/api';

const useSecondaryApi = ({ endpoint, method }) => {
  const history = useHistory();
  const [responseData, setResponseData] = useState(null);
  const [responseCode, setResponseCode] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { actions: authActions } = useAuth();

  const { handleOpenModal, handleCloseModal } = useModal();

  useEffect(() => {
    if (_.includes(endpoint, 'log-out')) return;

    const isAuthFlow = _.includes(endpoint, 'sign-in');

    if (_.get(error, 'code', 0) === 401 && !isAuthFlow) {
      authActions.logout();

      // TODO: Add this remove to auth package
      window.localStorage.removeItem('event');
      history.push('/login');

      handleOpenModal({
        configProps: {
          maxWidth: 'sm'
        },
        body: (
          <HttpErrorAlert
            errorCode={error ? error.code : responseCode}
            errorMessage={'Tu sesión ha caducado, vuelve a ingresar.'}
            onAccept={handleCloseModal}
          />
        )
      });
    } else if (error || (responseCode && responseCode >= 400)) {
      const customMessage = _.get(responseData, 'headerResponse.message', null);

      handleOpenModal({
        configProps: {
          maxWidth: 'sm'
        },
        body: (
          <HttpErrorAlert
            errorCode={error ? error.code : responseCode}
            errorMessage={error?.message || customMessage}
            onAccept={handleCloseModal}
          />
        )
      });
    }
  }, [error, responseCode]);

  const handleFetch = async ({
    body = {},
    urlParams = null,
    queryString = null
  } = {}) => {
    const url = `${endpoint}${urlParams ? `/${urlParams}` : ''}`;

    try {
      setError(null);
      setLoading(true);

      const response = await instanceSecondary[method](url, body);

      setResponseData(response.data);
      setStatus(response.status);
      setResponseCode(_.get(response, 'data.headerResponse.code', null));

      return response.data;
    } catch (error) {
      setLoading(false);

      setError({
        code: error.response.status,
        name: error.response.statusText,
        message: _.get(
          error,
          ['response', 'data', 'headerResponse', 'message'],
          'se genero un error externo'
        )
      });

      return error.response.data;
    } finally {
      setLoading(false);
    }
  };

  return [handleFetch, responseData, loading, status];
};

export { useSecondaryApi };
