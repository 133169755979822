import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:
      theme.palette.common.headerBackground || theme.palette.background.paper

    // position: 'absolute'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  link: {
    display: 'flex',
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  headerLogo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: theme.typography.pxToRem(64),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 0,
      width: theme.typography.pxToRem(132),
      height: theme.typography.pxToRem(56)
    }
  },
  menu: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4)
    }
  },
  sectionDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export { useStyles };
