import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, Avatar, Button } from '@material-ui/core';
import { useStyles } from './ActivityItemStyles';

const ActivityItem = ({ activity, handleClick }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonContainer}
      onClick={() =>
        setTimeout(() => {
          handleClick();
        }, 300)
      }
    >
      <Box width={1} py={1} pl={2} display="flex">
        <Box mr={3}>
          <Avatar
            src={activity.image}
            className={classes.avatar}
            alt="Activity"
          />
        </Box>
        <Box className={classes.descriptionActivity}>
          <Box pr={2} width={1}>
            <Typography
              variant="subtitle2"
              color="primary"
              className={`${classes.fontWeightBold} ${classes.verticalSpacing}`}
            >
              {activity.scheduleTime} - {activity.description}
            </Typography>
            <Typography variant="body1" className={classes.verticalSpacing}>
              Ponente - {activity.speaker}
            </Typography>
            <Typography variant="body2" className={classes.lightText}>
              {activity.company}
              <br /> {activity.nationality}
            </Typography>
          </Box>
          <Divider className={classes.divider} />
        </Box>
      </Box>
    </Button>
  );
};

ActivityItem.propTypes = {
  activity: PropTypes.shape({
    description: PropTypes.string,
    score: PropTypes.string,
    completed: PropTypes.bool,
    avatar: PropTypes.string,
    scheduleTime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    speaker: PropTypes.string.isRequired,
    company: PropTypes.string,
    nationality: PropTypes.string
  })
};

export { ActivityItem };
