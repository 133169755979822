import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  title: {
    id: 'components.Messages.EditModal.title',
    defaultMessage: '¿Seguro que quieres editar este mensaje?'
  },
  newMessagePlaceholder: {
    id: 'components.Messages.EditModal.newMessagePlaceholder',
    defaultMessage: 'Escribe el nuevo mensaje'
  },
  edit: {
    id: 'components.Messages.EditModal.edit',
    defaultMessage: 'Editar'
  },
  cancel: {
    id: 'components.Messages.EditModal.cancel',
    defaultMessage: 'Cancelar'
  }
});