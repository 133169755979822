export const formatTime = date => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = "".concat(hour, ":").concat(minutes < 10 ? "0".concat(minutes) : minutes);
  return formattedTime;
};
export const formatSeconds = seconds => {
  const date = new Date(0);
  date.setSeconds(seconds);
  const timeString = seconds < 3600 ? date.toISOString().substr(14, 5) : date.toISOString().substr(11, 8);
  return timeString;
};