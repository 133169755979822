import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { Box, Typography, Grow } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { messages } from './DiaryPanelMessages';
import { ActivityItem } from './ActivityItem';
import { useDrawer } from 'components/Drawer';
import { useApi } from 'hooks';
import { reestructureActivity } from 'utils/reestructureActivity';
import { useRootProvider } from '../RootProvider/hooks/useRootProvider';

const DiaryPanel = () => {
  const intl = useIntl();
  const {
    rootState,
    handleChangeActivitySchedule,
    handleChangeStream
  } = useRootProvider();
  const { activityScheduleList } = rootState;
  const history = useHistory();
  const { handleCloseDrawer } = useDrawer();
  const [activitiesToDate, setActivitiesToDate] = useState([]);
  const [date, changeDate] = useState(new Date());
  const [updateDate, setUpdateDate] = useState(true);

  const [getActivitiesSchedule] = useApi({
    endpoint: `activities/schedule`,
    method: 'get'
  });

  useEffect(() => {
    handleGetScheduleActivities();
  }, []);

  const handleGetScheduleActivities = async () => {
    const activityScheduleResponse = await getActivitiesSchedule({ body: {} });
    const activityScheduleList = reestructureActivity(
      _.get(activityScheduleResponse, ['payload', 'items'])
    );

    handleChangeActivitySchedule({
      activityScheduleList: activityScheduleList
    });
  };

  useEffect(() => {
    const formatDate = format(date, 'dd/MM/yyyy');

    setUpdateDate(false);
    setActivitiesToDate(
      _.filter(activityScheduleList, (activity) =>
        activity.date
          ? format(new Date(activity.date), 'dd/MM/yyyy') === formatDate
          : null
      )
    );
    setTimeout(() => {
      setUpdateDate(true);
    }, 300);
  }, [date, activityScheduleList]);

  return (
    <Box>
      <Box width={1} display="flex" justifyContent="center">
        <DatePicker
          orientation="portrait"
          variant="static"
          value={date}
          onChange={changeDate}
        />
      </Box>
      <Box pl={{ xs: 1, sm: 2 }} mt={2}>
        <Typography variant="body1">
          {intl.formatMessage(messages.scheduleEvents)}
        </Typography>
      </Box>
      <Box pl={{ xs: 1, sm: 2 }} mt={2} width={1}>
        {_.map(activitiesToDate, (activity, index) => (
          <Grow
            key={`event-day-box-${index}`}
            in={updateDate}
            timeout={index === 0 ? 200 : 200 * index}
          >
            <Box>
              <ActivityItem
                activity={activity}
                handleEnterActivity={(activityID) => {
                  handleCloseDrawer();
                  setTimeout(() => {
                    const {
                      chatIframe,
                      name,
                      description,
                      platform,
                      videoId
                    } = activity;

                    handleChangeStream({
                      stream: {
                        title: description,
                        speaker: name,
                        chatUri: chatIframe,
                        platform,
                        videoId
                      }
                    });
                    history.push(`/stream?activity=${activityID}`);
                  }, 400);
                }}
              />
            </Box>
          </Grow>
        ))}
      </Box>
    </Box>
  );
};

export { DiaryPanel };
