import { createContext } from 'react';

export const initialState = {
  hallChallenges: [],
  mainRoomChallenges: [],
  exhibitionRoomChallenges: [],
  partnerStageChallenges: [],
  coffeRoomChallenges: [],
  challengeRoomChallenges: [],
  quizzChallenges: [],
  supportChallenges: []
};

export const ChallengeProviderContext = createContext();
