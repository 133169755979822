import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative'
  },
  video: {
    maxWidth: '100%',
    maxHeight: '200px',
    [theme.breakpoints.only('xs')]: {
      maxHeight: '350px'
    },
    borderRadius: '5px'
  },
  controls: {
    position: 'absolute',
    bottom: 10,
    left: 6,
    right: 6,
    backgroundColor: hexToRGBA(theme.palette.common.mainBackground, 0.65),
    padding: '5px 10px 10px 10px',
    borderRadius: '15px',
    maxWidth: '450px',
    marginLeft: 'auto',
    marginRight: 'auto',
    opacity: 0,
    transition: 'all 250ms',
    '&.visible': {
      opacity: 1
    }
  },
  controlsRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  duration: _ref => {
    let anchor = _ref.anchor;
    return {
      color: anchor === 'left' ? theme.palette.text.primary : theme.palette.primary.contrastText,
      fontSize: '11px',
      padding: '2px 0px',
      pointerEvents: 'none',
      marginBottom: '-4px',
      marginRight: '7px',
      lineHeight: 1
    };
  },
  progress: {
    flexGrow: 1,
    height: '4px',
    backgroundColor: hexToRGBA(theme.palette.primary.contrastText, 0.2),
    cursor: 'pointer',
    marginTop: '5px',
    borderRadius: '11px',
    '& > div': {
      height: '100%',
      backgroundColor: theme.palette.primary.contrastText,
      pointerEvents: 'none',
      transition: 'width 250ms linear',
      borderRadius: '11px'
    }
  },
  icon: {
    margin: '0 4px',
    '& svg': {
      color: theme.palette.primary.contrastText
    },
    '&.volume': {
      marginLeft: '14px'
    }
  },
  volumeSlider: {
    color: theme.palette.primary.contrastText,
    maxWidth: '80px',
    marginLeft: '15px'
  }
}));
export { useStyles };