import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  createMuiTheme,
  ThemeProvider as MUIThemeProvider
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { config } from 'providers/config';
import { skins, availableSkins } from 'providers/theme';
import { ThemeContext } from './ThemeContext';

const ThemeProvider = (props) => {
  const { children, defaultTheme } = props;
  const { siteConfig } = config;
  const { LIGHT, DARK } = availableSkins;
  const [theme, setTheme] = useState(defaultTheme || siteConfig.defaultTheme);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const browserTheme = prefersDarkMode ? DARK : LIGHT;
  const mandatoryAppTheme = siteConfig.allowBrowserTheme ? browserTheme : theme;

  useEffect(() => {
    setTheme(defaultTheme);
  }, [defaultTheme]);

  const contextValue = useMemo(
    () => ({
      theme,
      availableSkins,
      handleChangeTheme: setTheme
    }),
    [theme]
  );

  const materialTheme = useMemo(
    () =>
      createMuiTheme({
        palette: { type: mandatoryAppTheme },
        ...skins[mandatoryAppTheme]
      }),
    [mandatoryAppTheme]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <MUIThemeProvider theme={materialTheme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  defaultTheme: PropTypes.string,
  children: PropTypes.node.isRequired
};

export { ThemeProvider };
