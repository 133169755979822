import _ from 'lodash';

export const Reducer = (state, action) => {
  switch (action.type) {
    case 'hall':
      return {
        ...state,
        hallChallenges: _.get(action, ['payload', 'hallChallenges'], [])
      };
    case 'main_room':
      return {
        ...state,
        mainRoomChallenges: _.get(action, ['payload', 'mainRoomChallenges'], [])
      };
    case 'exhibition_room':
      return {
        ...state,
        exhibitionRoomChallenges: _.get(
          action,
          ['payload', 'exhibitionRoomChallenges'],
          []
        )
      };
    case 'partner_stage':
      return {
        ...state,
        partnerStageChallenges: _.get(
          action,
          ['payload', 'partnerStageChallenges'],
          []
        )
      };
    case 'coffe_room':
      return {
        ...state,
        coffeRoomChallenges: _.get(
          action,
          ['payload', 'coffeRoomChallenges'],
          []
        )
      };
    case 'challenge':
      return {
        ...state,
        challengeRoomChallenges: _.get(
          action,
          ['payload', 'challengeRoomChallenges'],
          []
        )
      };
    case 'quizz_room':
      return {
        ...state,
        quizzChallenges: _.get(action, ['payload', 'quizzChallenges'], [])
      };
    case 'support_room':
      return {
        ...state,
        supportChallenges: _.get(action, ['payload', 'supportChallenges'], [])
      };
    default:
      return state;
  }
};
