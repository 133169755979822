import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  back: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  title: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1)
  },
  avatar: {
    width: '40px',
    height: '40px',
    transition: 'all 200ms'
  },
  badge: {
    '&.available .MuiBadge-badge': {
      backgroundColor: theme.palette.secondary.main
    },
    '&.busy .MuiBadge-badge': {
      backgroundColor: '#00000000'
    }
  },
  chatOnlineFriend: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '25px',
    transition: 'all 200ms',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.1),
      '& $avatar': {
        transform: 'scale(1.02, 1.02)'
      }
    }
  },
  chatOnlineName: {
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary
  },
  chatOnlineState: {
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    fontSize: 10,
    color: hexToRGBA(theme.palette.text.primary, 0.6)
  },
  chatMessages: {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    borderRadius: '50%',
    color: 'white',
    fontSize: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main
  },
  nameMessages: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    padding: "0 ".concat(theme.spacing(2), "px"),
    overflow: 'hidden'
  }
}));
export { useStyles };