import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
    maxWidth: theme.typography.pxToRem(435),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      maxWidth: 'inherit'
    }
  },
  content: {
    maxWidth: theme.typography.pxToRem(358),
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'inherit'
    }
  },
  status: {
    color: theme.palette.primary.main,
    display: 'block',
    textAlign: 'right',
    marginBottom: theme.spacing(2)
  },
  formTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1.5)
  },
  titleUpdateData: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1)
  },
  formButton: {
    marginTop: theme.spacing(2)
  }
}));

export { useStyles };
