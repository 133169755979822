import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imagePartner: {
    backgroundSize: 'auto',
    height: theme.typography.pxToRem(550)
  },
  presentationPartner: {
    paddingTop: theme.spacing(3),
    backgroundColor: '#E7E7E7',
    height: theme.typography.pxToRem(550),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  logoPartnerContainer: {
    height: '25%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: 0
    }
  },
  bodyPartner: {
    height: '75%',
    width: '70%',
    marginLeft: theme.spacing(8),
    // paddingTop: 25,
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginLeft: 0
    }
  },
  sloganPartner: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.common.black,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      marginTop: 20,
      marginBottom: 40
    }
  },
  mainPhraseContainer: {
    backgroundColor: theme.palette.common.black,
    height: theme.typography.pxToRem(150),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(255)
    }
  },
  mainPhrase: { textAlign: 'center', fontStyle: 'italic' },
  partnerCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  partnerImage: {
    height: theme.typography.pxToRem(450),
    backgroundColor: 'blue',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.typography.pxToRem(290)
    }
  },
  footer: {
    backgroundColor: '#000000',
    height: theme.typography.pxToRem(300),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  questionKnowMore: {
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      textAlign: 'center'
    }
  },
  spacingBottom: {
    marginBottom: theme.spacing(10)
  },
  quotationMarksSlogan: {
    color: theme.palette.primary.main,
    fontSize: '30px',
    lineHeight: '39px',
    fontStyle: 'italic'
  },
  button: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

export { useStyles };
