import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: '17px 25px 33px 25px'
  },
  title: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2)
  },
  drawer: {
    backgroundColor: 'white',
    width: '400px',
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100vw'
    }
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    padding: '10px 25px'
  },
  bodyContainer: {
    padding: theme.spacing(2)
  },
  closeIcon: {
    cursor: 'pointer',
    transition: 'color 300ms',
    color: 'white',
    boxSizing: 'content-box'
  },
  settingsIcon: {
    marginLeft: 'auto',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 300ms',
    marginRight: '15px'
  },
  searchIcon: {
    color: 'white',
    cursor: 'pointer',
    transition: 'color 300ms'
  },
  inputSearch: {
    width: '180px',
    backgroundColor: 'white',
    borderRadius: '13px',
    marginRight: '15px',
    padding: '0 13px'
  }
}));