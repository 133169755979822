import React from 'react';
import PropTypes from 'prop-types';
import './FullScreenLayout.scss';

function FullScreenLayout({ children }) {
  return <div className="full-screen-layout">{children}</div>;
}

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export { FullScreenLayout };
