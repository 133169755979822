import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.mainBackground
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  fontWeightBold: {
    fontWeight: 'bold'
  },
  divider: {
    border: 'none',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export { useStyles };
