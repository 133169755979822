import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { useModal } from '@octopy/react-modal';
import { useStyles } from './AlertStyles';

const Alert = (props) => {
  const classes = useStyles();
  const { handleCloseModal } = useModal();
  const { message, onAccept, onCancel } = props;

  const handleClickOnAccept = () => {
    handleCloseModal();

    if (onAccept) onAccept();
  };

  const handleClickOnCancel = () => {
    handleCloseModal();

    if (onCancel) onCancel();
  };

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" className={classes.message}>
        {message}
      </Typography>

      <div className={classes.buttons}>
        {onCancel && (
          <Button color="primary" onClick={handleClickOnCancel}>
            Cancelar
          </Button>
        )}
        <Button color="primary" onClick={handleClickOnAccept}>
          Aceptar
        </Button>
      </div>
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func
};

export { Alert };
