import _ButtonBase from "@material-ui/core/esm/ButtonBase";
import _Box from "@material-ui/core/esm/Box";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./DeleteModalMessages";
import { useStyles } from "./DeleteModalStyles";

const DeleteModal = _ref => {
  let visible = _ref.visible,
      setVisible = _ref.setVisible,
      message = _ref.message;
  const intl = useIntl();
  const classes = useStyles();
  return _jsx(_Box, {
    className: "".concat(classes.backdrop, " ").concat(visible && 'visible'),
    onClick: () => setVisible(false)
  }, void 0, _jsx(_Box, {
    className: classes.container,
    onClick: event => event.stopPropagation()
  }, void 0, _jsx(_Box, {
    className: classes.title
  }, void 0, intl.formatMessage(messages.title)), _jsx(_Box, {
    className: classes.buttonsContainer
  }, void 0, _jsx(_ButtonBase, {
    onClick: () => setVisible(false),
    className: "".concat(classes.button, " cancel")
  }, void 0, intl.formatMessage(messages.cancel)), _jsx(_ButtonBase, {
    className: classes.button
  }, void 0, intl.formatMessage(messages.delete)))));
};

export { DeleteModal };