import _IconButton from "@material-ui/core/esm/IconButton";
import _TextField from "@material-ui/core/esm/TextField";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./MessageInputMessages";
import { useStyles } from "./MessageInputStyles";
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Picker from 'emoji-picker-react';
import { useEmojiPicker } from "./hooks/useEmojiPicker";

const MessageInput = _ref => {
  let value = _ref.value,
      setValue = _ref.setValue,
      handleSubmit = _ref.handleSubmit,
      inputId = _ref.inputId;
  const intl = useIntl();
  const classes = useStyles();
  const emojiPicker = useEmojiPicker();
  return React.createElement(React.Fragment, null, _jsx(_TextField, {
    inputProps: {
      id: inputId
    },
    multiline: true,
    rowsMax: 3,
    className: classes.input,
    value: value,
    placeholder: intl.formatMessage(messages.writeAMessage),
    onChange: _ref2 => {
      let target = _ref2.target;
      return setValue(target.value);
    },
    onKeyDown: event => {
      if (event.key === 'Enter') {
        handleSubmit && handleSubmit();
        event.preventDefault();
      }
    }
  }), !!emojiPicker.position && _jsx(Picker, {
    pickerStyle: _objectSpread({
      position: 'fixed',
      zIndex: 10,
      transform: 'translate(-100%, -100%)',
      boxShadow: '0 0 5px #00000025'
    }, emojiPicker.position),
    onEmojiClick: (_, _ref3) => {
      let emoji = _ref3.emoji;
      return setValue(value => "".concat(value).concat(emoji));
    }
  }), _jsx(_IconButton, {
    onClick: emojiPicker.handleSwitch
  }, void 0, _jsx(EmojiEmotionsIcon, {
    className: classes.emojiIcon
  })));
};

export { MessageInput };