import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  mainText: {
    marginLeft: 20,
    marginBottom: 20,
    fontWeight: 700
  }
}));

export { useStyles };
