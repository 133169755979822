import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  allContactList: {
    id: 'components.Contacts.allContactList',
    defaultMessage: 'Todos mis contactos'
  },
  back: {
    id: 'components.Contacts.back',
    defaultMessage: 'Regresar a disponibles'
  }
});