import _ from 'lodash';
import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { messages } from './ShowMeAvailableMessages';
import { useStyles, CustomSwitch } from './ShowMeAvailableStyles';
import { useChallenge } from 'hooks/useChallenges';
import { getItem, setItem } from 'utils/persistentStorage';
import { useSecondaryApi } from 'hooks';

const ShowMeAvailable = ({ onChange, status }) => {
  const intl = useIntl();
  const classes = useStyles();
  const idEvent = _.get(getItem('event'), ['_id'], '');
  const userId = _.get(getItem('session'), ['user_id'], '');

  const { updateChallenge } = useChallenge();
  const [updateStatus] = useSecondaryApi({
    endpoint: `/participant/api-status`,
    method: 'post'
  });

  const handleChangeStatus = async () => {
    if (onChange) {
      onChange(!status);
      updateChallenge('show-available');

      await updateStatus({
        body: {
          eventId: idEvent,
          participantId: userId,
          status: status ? 'logout' : 'login'
        }
      });

      setItem('session', {
        ...getItem('session'),
        status: !status
      });
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.showLabel}>
        {/* {status
          ? intl.formatMessage(messages.labelDisconect)
          : intl.formatMessage(messages.label)} */}
        {intl.formatMessage(messages.label)}
      </Typography>

      <CustomSwitch
        // color={status ? 'primary' : 'secondary'}
        size="small"
        checked={status}
        onChange={() => handleChangeStatus()}
        className={classes.switch}
      />
    </div>
  );
};

ShowMeAvailable.propTypes = {
  onChange: PropTypes.func
};

export { ShowMeAvailable };
