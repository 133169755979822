import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& img': {
      height: theme.typography.pxToRem(40),
      width: theme.typography.pxToRem(129)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  logoToRight: {
    justifyContent: 'flex-end'
  }
}));

export { useStyles };
