import { makeStyles } from '@material-ui/core/styles';
import { Images } from 'assets';

const useStyles = makeStyles((theme) => ({
  containerWithBg: {
    backgroundColor: theme.palette.common.mainBackground,
    minWidth: '100%',
    minHeight: '100vh',
    background: `url("${Images.mainBackround}") 0% 0% no-repeat padding-box`,
    backgroundPosition: 'left bottom'
  }
}));

export { useStyles };
