import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    width: '100%',
    height: '100%',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05)
  },
  userName: {
    maxWidth: '190px',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1)
  },
  iconStyle: _ref => {
    let iconsColor = _ref.iconsColor,
        windowColor = _ref.windowColor;
    return {
      color: !!iconsColor ? iconsColor : !!windowColor ? theme.palette.getContrastText(windowColor) : theme.palette.text.primary,
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(1.2)'
      }
    };
  },
  avatar: {
    width: '35px',
    height: '35px'
  },
  availableStatus: {
    '& > span': {
      minWidth: theme.spacing(1.5),
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundColor: theme.palette.common.connected
    }
  },
  unavailableStatus: {
    '& > span': {
      minWidth: theme.spacing(1.5),
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundColor: theme.palette.common.disconnected
    }
  }
}));
export { useStyles };