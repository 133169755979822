import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../helpers/colors";
export const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: '30px',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
    display: 'flex',
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(2)
  },
  tab: {
    padding: '8px 16px',
    borderRadius: '30px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '15px',
    flexGrow: 1,
    textAlign: 'center',
    transition: 'all 175ms',
    cursor: 'pointer',
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.mainBackground
    }
  }
}));