import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails
} from '@material-ui/core';

const Accordion = withStyles((theme) => ({
  root: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      marginBottom: theme.spacing(5)
    }
  },
  expanded: { borderBottom: 'none' }
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.mainBackground,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.mainBackground,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.primary.main}`
  }
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  listContainer: {
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

export { useStyles, Accordion, AccordionSummary, AccordionDetails };
