import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Challenge.title',
    defaultMessage: 'Challenge'
  },
  description: {
    id: 'components.Challenge.description',
    defaultMessage:
      'Bienvenido al área de Challenge, aquí podrás visualizar los retos que has logrado superar y los que todavía te faltan. Descubre, participa y aumenta tus skills.'
  },
  category: {
    id: 'components.Challenge.category',
    defaultMessage: 'Categoría: '
  },
  nextCategory: {
    id: 'components.Challenge.nextCategory',
    defaultMessage: 'Para alcanzar la siguiente categoría: '
  },
  policiesButton: {
    id: 'components.Challenge.policiesButton',
    defaultMessage: 'Políticas'
  },
  awardsButton: {
    id: 'components.Challenge.awardsButton',
    defaultMessage: 'Logros'
  },
  points: {
    id: 'components.Challenge.points',
    defaultMessage: 'Pts.'
  },
  globalRanking: {
    id: 'components.Challenge.globalRaking',
    defaultMessage: 'Ranking Global'
  },
  policiesChallenge: {
    id: 'components.challenge.policiesChallenge',
    defaultMessage: 'Políticas Challenge'
  },
  errorPolices: {
    id: 'components.challenge.errorPolices',
    defaultMessage: 'No se obtuvieron las políticas correctamente.'
  },
  challengesDesblock: {
    id: 'components.challenge.challengesDesblock',
    defaultMessage: 'Logros Desbloqueados'
  },
  noClasificate: {
    id: 'components.challenge.noClasificate',
    defaultMessage: 'Aun no clasificas'
  }
});
