import React from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  Card,
  Box,
  Button,
  Divider,
  Grid
} from '@material-ui/core';
import { messages } from './PartnerCardMessages';
import { useStyles } from './PartnerCardStyles';
import { useHistory } from 'react-router-dom';
import { useChallenge } from 'hooks/useChallenges';

const PartnerCard = ({ contendCard, action }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { updateChallenge } = useChallenge();
  const { business_name, country, image } = contendCard;

  return (
    <Card className={classes.root}>
      <Grid container className={classes.imageBackground}>
        <Box
          className={classes.image}
          style={{
            backgroundImage: `url(${image})`
          }}
        />
      </Grid>

      <Grid
        container
        alignItems="center"
        justify="flex-start"
        className={classes.partnerInfo}
      >
        <div className="data-container">
          <div className="info">
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.title}
              component="p"
            >
              {business_name}
            </Typography>

            <Typography
              variant="caption"
              className={classes.subtitle}
              component="p"
            >
              {country}
            </Typography>
          </div>

          <div className="button">
            <Divider className={classes.dividerMargin} />
            <Button
              style={{ width: '100%' }}
              color="primary"
              size="large"
              onClick={() => {
                updateChallenge('enter-stand');
                action();
                history.push('/landing-partner');
              }}
            >
              {intl.formatMessage(messages.messageButton, {
                name: 'PartnerCard'
              })}
            </Button>
          </div>
        </div>
      </Grid>
    </Card>
  );
};

export { PartnerCard };
