import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.LandingPartner.ImInterestedFormModal.title',
    defaultMessage: '¿Te gustaría conocer más sobre nosotros?'
  },
  description: {
    id: 'components.LandingPartner.ImInterestedFormModal.description',
    defaultMessage:
      'Estamos felices de que te encuentres interesado en nosotros. Completa el formulario y envíanos tus cometarios, nos pondremos en contacto contigo.'
  },
  nameLabel: {
    id: 'components.LandingPartner.ImInterestedFormModal.input.name.label',
    defaultMessage: 'Nombre completo'
  },
  namePlaceholder: {
    id:
      'components.LandingPartner.ImInterestedFormModal.input.name.placeholder',
    defaultMessage: 'Ingresar nombre completo'
  },
  emailLabel: {
    id: 'components.LandingPartner.ImInterestedFormModal.input.email.label',
    defaultMessage: 'Correo electrónico'
  },
  emailPlaceholder: {
    id:
      'components.LandingPartner.ImInterestedFormModal.input.email.placeholder',
    defaultMessage: 'Ingresar correo electrónico'
  },
  phoneLabel: {
    id: 'components.LandingPartner.ImInterestedFormModal.input.phone.label',
    defaultMessage: 'Teléfono'
  },
  phonePlaceholder: {
    id:
      'components.LandingPartner.ImInterestedFormModal.input.phone.placeholder',
    defaultMessage: 'Ingresar teléfono'
  },
  companyLabel: {
    id: ' components.LandingPartner.ImInterestedFormModal.input.company.label',
    defaultMessage: 'Nombre de la empresa'
  },
  companyPlaceholder: {
    id:
      'components.LandingPartner.ImInterestedFormModal.input.company.placeholder',
    defaultMessage: 'Ingresar nombre de la empresa'
  },
  commentLabel: {
    id: 'components.LandingPartner.ImInterestedFormModal.input.comment.label',
    defaultMessage: 'Comentario'
  },
  commentPlaceholder: {
    id:
      'components.LandingPartner.ImInterestedFormModal.input.comment.placeholder',
    defaultMessage: 'Escribe tu comentario aquí'
  },
  requiredError: {
    id: 'components.LandingPartner.ImInterestedFormModal.input.error.required',
    defaultMessage: 'Este campo es requerido'
  },
  emailError: {
    id: 'components.LandingPartner.ImInterestedFormModal.input.email.required',
    defaultMessage: 'Ingresa un correo electrónico valido'
  },
  send: {
    id: 'components.LandingPartner.ImInterestedFormModal.button.submit.send',
    defaultMessage: 'Enviar'
  },
  successfullySent: {
    id: 'components.LandingPartner.ImInterestedFormModal.successfullySent',
    defaultMessage: '¡SOLICITUD ENVIADA EXITOSAMENTE!'
  },
  error: {
    id: 'components.LandingPartner.ImInterestedFormModal.error',
    defaultMessage: 'Ha ocurrido un error al enviar tu solicitud'
  }
});
