var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./ReplyToMessagePreviewMessages";
import { useStyles } from "./ReplyToMessagePreviewStyles";
import ReplyIcon from '@material-ui/icons/Reply';
import CloseIcon from '@material-ui/icons/Close';
import { useChat } from "../../Chat";
import { useAuth } from "../../Auth";

const ReplyToMessagePreview = () => {
  const intl = useIntl();

  const _useChat = useChat(),
        setReplyingTo = _useChat.setReplyingTo,
        message = _useChat.replyingTo;

  const _useAuth = useAuth(),
        user = _useAuth.user;

  const classes = useStyles({
    fromUser: user.id === message.from
  });
  return _jsx("div", {
    className: classes.container
  }, void 0, _jsx(ReplyIcon, {
    className: classes.icon
  }), _jsx("div", {
    className: classes.textContainer
  }, void 0, _jsx("div", {
    className: "".concat(classes.userName, " ").concat(classes.text)
  }, void 0, message.from), _jsx("div", {
    className: "".concat(classes.messagePreview, " ").concat(classes.text)
  }, void 0, message.type === 'text' ? message.content : intl.formatMessage(messages[message.type]))), _jsx(CloseIcon, {
    className: "".concat(classes.icon, " close"),
    onClick: () => setReplyingTo(null)
  }));
};

export { ReplyToMessagePreview };