import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100px',
    zIndex: 3,
    padding: '5px 0',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.98),
    fontSize: '13px',
    borderRadius: '5px',
    boxShadow: theme.shadows[3]
  },
  item: {
    cursor: 'pointer',
    padding: '3px 10px',
    transition: 'all 150ms',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: hexToRGBA(theme.palette.common.mainBackground, 0.85),
      color: theme.palette.text.primary
    }
  }
}));
export { useStyles };