import _get from "lodash/get";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from "../Auth";
import { useSocket } from "../Socket";
import { NotificationsContext } from "./NotificationsContext";
import { deleteNotification as deleteNotificationAPI, getNotifications } from "../../api/requests/notifications";
import { useErrors } from "../Errors/hooks/useErrors";
import { mapUser } from "./helpers";
export const NotificationsProvider = _ref => {
  let children = _ref.children;
  const socket = useSocket();

  const _useAuth = useAuth(),
        _useAuth$user = _useAuth.user,
        user = _useAuth$user === void 0 ? {} : _useAuth$user,
        connected = _useAuth.connected;

  const _useErrors = useErrors(),
        handleShowError = _useErrors.handleShowError;

  const _useState = useState([]),
        _useState2 = _slicedToArray(_useState, 2),
        notifications = _useState2[0],
        setNotifications = _useState2[1];

  const notificationsRef = useRef();
  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);
  useEffect(() => {
    if (connected) {
      _asyncToGenerator(function* () {
        try {
          const notifications = yield getNotifications({
            userId: user.id
          });
          setNotifications(notifications);
        } catch (error) {
          handleShowError(error.message);
          setNotifications([]);
        }
      })();

      socket.on('private_message', _ref3 => {
        let from = _ref3.from;
        const fromUser = mapUser(from);
        fromUser.id !== user.id && !notificationsRef.current.some(notification => _get(notification, 'id') === fromUser.id) && setNotifications([...notificationsRef.current, fromUser]);
      });
    }
  }, [connected]);

  const deleteNotification = notificationToDelete => {
    deleteNotificationAPI({
      from: _get(notificationToDelete, 'id'),
      to: _get(user, 'id')
    });
    setNotifications(notifications => notifications.filter(notification => _get(notification, 'id') !== _get(notificationToDelete, 'id')));
  };

  const contextValue = {
    notifications,
    deleteNotification
  };
  return _jsx(NotificationsContext.Provider, {
    value: contextValue
  }, void 0, children);
};