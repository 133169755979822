import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  slideContainer: {
    position: 'fixed',
    bottom: -177,
    width: '100%',
    height: theme.typography.pxToRem(222),
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex
    })
  },
  slideTransition: {
    transform: `translate(0%, calc(-100% + 48px))`
  },
  slideXContainer: {
    transform: `translate(100%, 0%)`,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex
    })
  },
  slideXTransition: {
    transform: `translate(0%, 0%)`
  },
  diaryButton: {
    width: theme.typography.pxToRem(224),
    height: theme.typography.pxToRem(48),
    borderRadius: '5px 5px 0 0',
    backgroundColor: theme.palette.common.mainBackground,
    justifyContent: 'space-around',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: theme.palette.common.mainBackground
    }
  },
  dropdown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short
    })
  },
  dropdownOpen: {
    transform: 'rotate(180deg)'
  },
  dropdownClosed: {
    transform: 'rotate(0)'
  },
  eventsContainer: {
    backgroundColor: theme.palette.common.mainBackground
  },
  onScroll: {
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  activityPanelDrawer: {
    maxWidth: 400,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    }
  }
}));

export { useStyles };
