import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => {
  const colorToUse = appliedColors => typeof appliedColors === 'object' && appliedColors.hasOwnProperty('windowColor') ? appliedColors.windowColor : theme.palette.common.mainBackground;

  return {
    container: appliedColors => ({
      padding: '5px 10px',
      color: theme.palette.getContrastText(colorToUse(appliedColors)),
      backgroundColor: colorToUse(appliedColors),
      borderTop: "1px solid ".concat(hexToRGBA(theme.palette.text.primary, 0.1)),
      borderBottom: "1px solid ".concat(hexToRGBA(theme.palette.text.primary, 0.1)),
      [theme.breakpoints.down('xs')]: {
        width: '100vw'
      }
    }),
    search: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fontSize: '20px'
      }
    },
    formControl: {
      flexGrow: 1
    },
    input: appliedColors => ({
      color: theme.palette.getContrastText(colorToUse(appliedColors)),
      fontSize: '13px',
      marginLeft: '5px',
      '& ::placeholder': {
        color: theme.palette.getContrastText(colorToUse(appliedColors))
      }
    })
  };
});
export { useStyles };