import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getDate, format, getYear } from 'date-fns';
import { es } from 'date-fns/locale';
import { Box, Typography, Button } from '@material-ui/core';
import { messages } from '../DiaryMessages';
import { useStyles } from './DiaryProgramStyles';

const DiaryProgram = ({ date, onClickProgram }) => {
  const intl = useIntl();
  const classes = useStyles();

  const formatDate = new Date(date);

  const day = getDate(formatDate);
  const month = format(formatDate, 'LLLL', { locale: es });
  const year = getYear(formatDate);

  return (
    <Box p={1} className={classes.container}>
      <Typography className={classes.dayText}>{day}</Typography>
      <Typography className={classes.dateText}>
        {month} {year}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button color="primary" onClick={onClickProgram}>
          {intl.formatMessage(messages.programButton, { name: 'Diary' })}
        </Button>
      </Box>
    </Box>
  );
};

DiaryProgram.propTypes = {
  date: PropTypes.string.isRequired,
  onClickProgram: PropTypes.func
};

export { DiaryProgram };
