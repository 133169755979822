import _Box from "@material-ui/core/esm/Box";
import _Typography from "@material-ui/core/esm/Typography";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./ColorPickerMessages";
import { useStyles } from "./ColorPickerStyles";
import { SketchPicker } from 'react-color';

const ColorPicker = _ref => {
  let title = _ref.title,
      id = _ref.id,
      propertyName = _ref.propertyName,
      objectColors = _ref.objectColors,
      setObjectColors = _ref.setObjectColors,
      colorSaved = _ref.colorSaved;
  const intl = useIntl();
  const classes = useStyles();

  const _useState = useState(colorSaved !== undefined ? colorSaved : '#FFFFFF'),
        _useState2 = _slicedToArray(_useState, 2),
        color = _useState2[0],
        setColor = _useState2[1];

  return _jsx(_Box, {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    className: classes.container
  }, void 0, _jsx(_Box, {}, void 0, _jsx(_Typography, {
    variant: "variant",
    className: classes.titleColor
  }, void 0, title)), _jsx(_Box, {
    display: "flex",
    alignItems: "center"
  }, void 0, _jsx(_Box, {
    display: "flex",
    alignItems: "center",
    className: classes.containerInput
  }, void 0, _jsx("input", {
    id: id,
    type: "color",
    className: classes.inputHidden,
    value: color,
    onChange: event => {
      setColor(event.target.value.toUpperCase());
    },
    onBlur: event => {
      setObjectColors(_objectSpread(_objectSpread({}, objectColors), {}, {
        [propertyName]: event.target.value.toUpperCase()
      }));
    }
  }), _jsx("label", {
    htmlFor: id
  }, void 0, _jsx("div", {
    style: {
      backgroundColor: color
    },
    className: classes.rectangleSelectColor
  }))), _jsx(_Box, {
    className: classes.labelColor
  }, void 0, _jsx(_Typography, {
    variant: "variant",
    className: classes.titleColor
  }, void 0, "\xA0\xA0", color))));
};

export { ColorPicker };