import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  diaryButton: {
    id: 'components.Diary.diaryButton',
    defaultMessage: 'Agenda del evento'
  },
  programButton: {
    id: 'components.Diary.programButton',
    defaultMessage: 'Programa'
  }
});
