import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: _ref => {
    let topSeparation = _ref.topSeparation;
    return {
      padding: '0 9px 9px 9px',
      paddingTop: topSeparation ? '9px' : null
    };
  },
  text: _ref2 => {
    let anchor = _ref2.anchor;
    return {
      maxWidth: '100%',
      overflowWrap: 'anywhere',
      textAlign: 'start',
      lineHeight: '14px',
      color: anchor === 'right' ? theme.palette.primary.contrastText : theme.palette.text.primary
    };
  },
  link: _ref3 => {
    let anchor = _ref3.anchor;
    return {
      textDecoration: 'none',
      color: anchor === 'left' ? theme.palette.text.primary : theme.palette.primary.contrastText
    };
  },
  spacer: {
    display: 'inline-block'
  }
}));
export { useStyles };