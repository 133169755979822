import { createContext } from 'react';
import { getItem } from 'utils/persistentStorage';

export const initialState = {
  event: getItem('event'),
  activityList: [],
  awardList: [
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: true
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: false
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: false
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: true
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: false
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: true
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: false
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: false
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: true
    },
    {
      description: 'Permanecer 15 minutos en el evento',
      score: '50',
      completed: false
    }
  ],
  coffeRoomUserList: [
    {
      avatar:
        'http://pm1.narvii.com/6035/511da1ef081c26ed3051a97892f34504b906752a_00.jpg',
      name: 'Marisela Hernández Méndez',
      position: 'Developer',
      company: 'Google'
    },
    {
      avatar:
        'http://pm1.narvii.com/6035/511da1ef081c26ed3051a97892f34504b906752a_00.jpg',
      name: 'christian Hernández Méndez',
      position: 'Developer',
      company: 'Google'
    },
    {
      avatar:
        'http://pm1.narvii.com/6035/511da1ef081c26ed3051a97892f34504b906752a_00.jpg',
      name: 'Marisela Hernández Méndez',
      position: 'Developer',
      company: 'Google'
    },
    {
      avatar:
        'http://pm1.narvii.com/6035/511da1ef081c26ed3051a97892f34504b906752a_00.jpg',
      name: 'Marisela Hernández Méndez',
      position: 'Developer',
      company: 'Google'
    },
    {
      avatar:
        'http://pm1.narvii.com/6035/511da1ef081c26ed3051a97892f34504b906752a_00.jpg',
      name: 'Marisela Hernández Méndez',
      position: 'Developer',
      company: 'Google'
    }
  ],
  faqList: [
    {
      question: '¿Qué es la sala Hall?',
      answer:
        'La sala Hall es el equivalente al lobby en un evento real y donde encontrarás el programa de actividades a seguir.'
    },
    {
      question: '¿Qué es la sala Principal?',
      answer:
        'La sala Principal es el lugar donde se encuentran las actividades destacadas del evento. Ahí se concentran las exposiciones de mayor interés para ti.'
    },
    {
      question: '¿Qué es la sala de Exhibición?',
      answer:
        'Es el lugar que muestra los productos y servicios ofrecidos por el organizador a los asistentes.'
    },
    {
      question: '¿Qué es la sala Partner?',
      answer:
        'Es el espacio destinado a todos los expositores para colocar sus stands y donde los visitantes pueden conocer las ofertas que ofrecen los expositores aliados en el evento.'
    },
    {
      question: '¿Cómo ingreso a una conferencia?',
      answer:
        'Sólo tienes que seleccionarla en el listado y dar clic en la tarjeta de cada uno de los eventos. Encontrarás un botón con la leyenda: “ver más”, oprímelo y listo.'
    },
    {
      question: '¿Cómo puedo invitar a alguien al evento?',
      answer:
        'Sólo notifica a nuestro asesor del evento en el correo: soporterooms@octopy.com, y, de acuerdo a las reglas del negocio, crearemos el acceso del usuario invitado.'
    },
    {
      question: '¿Cómo funciona el Quiz?',
      answer:
        'Es la sección más divertida donde puedes contestar preguntas sobre el evento. Si aciertas obtendrás puntos canjeables por premios que ofrece el organizador.'
    },
    {
      question:
        '¿Tengo una falla en la plataforma Rooms, con quién puedo atenderla?',
      answer:
        'Envíanos un correo a rooms_support@octopy.com y te atenderemos a la brevedad.'
    },
    {
      question: '¿Para qué sirven los puntos?',
      answer:
        'Son la moneda de intercambio por premios e incentivos de parte del organizador. En las bases puedes ver las reglas de uso de los mismos.'
    },
    {
      question: '¿Por qué no puedo ver la conferencia?',
      answer:
        'Es común que se deba a problemas del navegador. Revisa los permisos de tu navegador y revisa si tienes algún bloqueo o permiso desactivado. Si el problema persiste envíanos un correo a rooms_support@octopy.com'
    },
    {
      question: '¿No puedo responder el Quiz?',
      answer:
        'Normalmente ocurre por problemas del navegador. Revisa los permisos de tu navegador y revisa si tienes algún bloqueo o permiso desactivado. Si el problema persiste envíanos un correo a rooms_support@octopy.com'
    }
  ],
  partnerList: [],
  activityScheduleList: [],
  productList: [],
  notificationsPanelList: [
    {
      message:
        'La conferencia de Fernando Martínez herrera se ha agregado a tu agenda'
    },
    {
      message:
        'La conferencia de Fernando Martínez herrera se ha agregado a tu agenda'
    },
    {
      message:
        'La conferencia de Fernando Martínez herrera se ha agregado a tu agenda'
    }
  ],
  quizList: [
    {
      question: '¿CUANTO ES 4 AL CUADRADO?',
      event: 1,
      answer: [
        {
          value: 25
        },
        {
          value: 12
        },
        {
          value: 30
        },
        {
          value: 16,
          correctAnswer: true
        }
      ],
      points: 100
    },
    {
      question: '¿CUANTO ES 4 AL CUADRADO?',
      event: 4,
      answer: [
        {
          value: 25
        },
        {
          value: 12
        },
        {
          value: 30
        },
        {
          value: 16,
          correctAnswer: true
        }
      ],
      points: 50
    },
    {
      question: '¿CUANTO ES 4 AL CUADRADO?',
      event: 2,
      answer: [
        {
          value: 25
        },
        {
          value: 12
        },
        {
          value: 30
        },
        {
          value: 16,
          correctAnswer: true
        }
      ],
      points: 50
    },
    {
      question: '¿Cuanto es 5 al cuadrado?',
      event: 1,
      answer: [
        {
          value: 25,
          correctAnswer: true
        },
        {
          value: 12
        },
        {
          value: 10
        }
      ],
      points: 50
    },
    {
      question: '¿Cuanto es 4 al cuadrado?',
      event: 4,
      answer: [
        {
          value: 25
        },
        {
          value: 12
        },
        {
          value: 30
        },
        {
          value: 16,
          correctAnswer: true
        }
      ],
      points: 100
    },
    {
      question: '¿Cuanto es 4 al cuadrado?',
      event: 4,
      answer: [
        {
          value: 25
        },
        {
          value: 12
        },
        {
          value: 16,
          correctAnswer: true
        }
      ],
      points: 200
    },
    {
      question: '¿Cuanto es 4 al cuadrado?',
      event: 3,
      answer: [
        {
          value: 25
        },
        {
          value: 12
        },
        {
          value: 16,
          correctAnswer: true
        }
      ],
      points: 200
    }
  ],
  currentProgramDate: '',
  stream: {
    title: '',
    speaker: '',
    streamUri: '',
    chatUri: '',
    platform: '',
    videoId: ''
  },
  session: {
    token: getItem('token'),
    user: getItem('session')
  },
  challengesUser: [],
  challengesActive: []
};

export const RootProviderContext = createContext();
