var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./RepliedMessagePreviewMessages";
import { useStyles } from "./RepliedMessagePreviewStyles";
import { useAuth } from "../../../Auth";
import { useMessenger } from "../../../Messenger";

const RepliedMessagePreview = _ref => {
  let type = _ref.type,
      header = _ref.header,
      repliedMessage = _ref.repliedMessage,
      styleProps = _ref.styleProps;

  const _useAuth = useAuth(),
        user = _useAuth.user;

  const _useMessenger = useMessenger(),
        selectedChat = _useMessenger.selectedChat;

  const intl = useIntl();
  const classes = useStyles(_objectSpread(_objectSpread({}, styleProps), {}, {
    header,
    type
  }));
  return _jsx("div", {
    className: classes.container
  }, void 0, _jsx("div", {
    className: classes.innerContainer
  }, void 0, _jsx("div", {
    className: "".concat(classes.userName, " ").concat(classes.text)
  }, void 0, repliedMessage.from === user.id ? user.name : selectedChat.name), _jsx("div", {
    className: "".concat(classes.messagePreview, " ").concat(classes.text)
  }, void 0, repliedMessage.type === 'text' ? repliedMessage.content : intl.formatMessage(messages[repliedMessage.type]))));
};

export { RepliedMessagePreview };