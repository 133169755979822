import { makeStyles } from '@material-ui/core/styles';
import { hexToRGBA } from "../../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    backgroundColor: hexToRGBA(theme.palette.common.mainBackground, 0.9),
    padding: theme.spacing(1),
    cursor: 'pointer',
    transition: 'background-color 175ms',
    borderRadius: theme.spacing(2),
    '&:hover': {
      backgroundColor: hexToRGBA(theme.palette.secondary.light, 0.2)
    },
    '&.active': {
      backgroundColor: hexToRGBA(theme.palette.secondary.light, 0.4)
    },
    boxShadow: theme.shadows[2]
  },
  avatarContainer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  fontWeightBold: {
    fontWeight: 'bold'
  },
  on: {
    color: hexToRGBA(theme.palette.primary.main, 0.4),
    transition: 'color 150ms'
  },
  off: {
    color: hexToRGBA(theme.palette.secondary.main, 0.4),
    transition: 'color 150ms'
  },
  notification: {
    color: theme.palette.primary.main,
    transition: 'color 150ms'
  },
  availableStatus: {
    '& > span': {
      minWidth: theme.spacing(2),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.common.connected,
      border: "2px solid ".concat(theme.palette.common.mainBackground)
    }
  },
  unavailableStatus: {
    '& > span': {
      minWidth: theme.spacing(2),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.common.disconnected,
      border: "2px solid ".concat(theme.palette.common.mainBackground)
    }
  },
  notificationBadge: {
    '& > span': {
      minWidth: theme.spacing(2),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      border: "1px solid ".concat(theme.palette.primary.dark)
    }
  },
  messageIcon: {
    color: hexToRGBA(theme.palette.secondary.main, 0.4),
    transition: 'color 150ms'
  }
}));
export { useStyles };