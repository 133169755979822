import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    },
    height: '40px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    bottom: '10px',
    opacity: '0.75'
  },
  cutText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));
export { useStyles };