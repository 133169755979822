import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'contents',
    position: 'relative',
    borderRadius: 'inherit'
  },
  video: _ref => {
    let anchor = _ref.anchor;
    return {
      cursor: 'pointer',
      objectFit: 'cover',
      maxHeight: '100%',
      border: '2px solid transparent',
      borderRadius: 'inherit'
    };
  },
  playIcon: _ref2 => {
    let anchor = _ref2.anchor;
    return {
      zIndex: 1,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: anchor === 'left' ? hexToRGBA(theme.palette.common.mainBackground, 0.5) : hexToRGBA(theme.palette.primary.main, 0.5),
      padding: '5px',
      borderRadius: '40%',
      '& svg': {
        fontSize: '25px',
        color: anchor === 'left' ? theme.palette.text.primary : theme.palette.primary.contrastText
      }
    };
  }
}));
export { useStyles };