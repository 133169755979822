import _Button from "@material-ui/core/esm/Button";
import _Typography from "@material-ui/core/esm/Typography";
import _TextField from "@material-ui/core/esm/TextField";
const _excluded = ["fields", "initialValues", "validationRules", "handleSubmit", "buttonSubmitLabel", "error", "ignoreInputErrorStyle", "disableSubmitOnFormInvalid", "validateOnMount", "inputProps", "buttonSubmitProps", "withInputsBackground", "withInputsBorder", "autoFocus"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/no-autofocus */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { PasswordAdornment } from "./Adornments";
import { Autocomplete } from "./Autocomplete";
import * as types from "./types";
import { useStyles } from "./FormStyles";

const Form = props => {
  const classes = useStyles();

  const fields = props.fields,
        initialValues = props.initialValues,
        validationRules = props.validationRules,
        handleSubmit = props.handleSubmit,
        buttonSubmitLabel = props.buttonSubmitLabel,
        error = props.error,
        ignoreInputErrorStyle = props.ignoreInputErrorStyle,
        disableSubmitOnFormInvalid = props.disableSubmitOnFormInvalid,
        validateOnMount = props.validateOnMount,
        inputProps = props.inputProps,
        buttonSubmitProps = props.buttonSubmitProps,
        withInputsBackground = props.withInputsBackground,
        withInputsBorder = props.withInputsBorder,
        autoFocus = props.autoFocus,
        otherProps = _objectWithoutProperties(props, _excluded);

  const formik = useFormik({
    initialValues,
    validationSchema: validationRules,
    onSubmit: handleSubmit
  });
  const passwordFields = fields.filter(fieldItem => fieldItem.type === 'password');

  const _useState = useState(passwordFields.map(fieldItem => _objectSpread(_objectSpread({}, fieldItem), {}, {
    showPassword: false
  }))),
        _useState2 = _slicedToArray(_useState, 2),
        passwordInputs = _useState2[0],
        setPasswordInputs = _useState2[1];

  const handleClickShowPassword = fieldName => {
    const passwordInputsUpdated = passwordInputs.map(inputItem => {
      const name = inputItem.name,
            showPassword = inputItem.showPassword;
      return _objectSpread(_objectSpread({}, inputItem), {}, {
        showPassword: name === fieldName ? !showPassword : showPassword
      });
    });
    setPasswordInputs(passwordInputsUpdated);
  };

  const getShowPasswordStatus = fieldName => {
    const passwordInput = passwordInputs.find(inputItem => inputItem.name === fieldName);
    return passwordInput.showPassword;
  };

  const handleMouseDownPassword = fieldName => {};

  useEffect(() => {
    if (validateOnMount) formik.validateForm();
  }, []);
  return React.createElement("form", _extends({
    onSubmit: formik.handleSubmit,
    className: "".concat(classes.form, " ").concat(withInputsBackground ? classes.withInputBackground : '', " ").concat(withInputsBorder ? '' : classes.withoutInputBorder)
  }, otherProps), fields.map((field, index) => field.type === 'select' ? _jsx(Autocomplete, {
    field: field,
    inputProps: inputProps,
    error: formik.touched[field.name] && formik.errors[field.name] && !ignoreInputErrorStyle && true,
    helperText: formik.touched[field.name] && formik.errors[field.name],
    onChange: formik.setFieldValue,
    options: field.options,
    value: formik.values[field.name],
    getOptionLabel: field.getOptionLabel
  }, index) : _jsx(_TextField, {
    autoFocus: autoFocus && index === 0,
    fullWidth: inputProps.fullWidth,
    disabled: field.disabled,
    id: field.id,
    name: field.name,
    label: field.label,
    type: field.type === 'password' && getShowPasswordStatus(field.name) ? 'text' : field.type,
    placeholder: field.placeholder,
    multiline: field.type === 'textarea',
    rows: field.type === 'textarea' ? inputProps.multilineRows : undefined,
    variant: inputProps.variant,
    margin: inputProps.margin,
    InputProps: {
      endAdornment: field.type === 'password' && _jsx(PasswordAdornment, {
        fieldStatus: getShowPasswordStatus(field.name),
        onClick: () => handleClickShowPassword(field.name),
        onMouseDown: () => handleMouseDownPassword(field.name)
      })
    },
    value: formik.values[field.name],
    onChange: formik.handleChange,
    error: (formik.touched[field.name] || field.type === 'textarea') && formik.errors[field.name] && !ignoreInputErrorStyle && true,
    helperText: (formik.touched[field.name] || field.type === 'textarea') && formik.errors[field.name]
  }, "form-".concat(field.name, "-").concat(index))), React.createElement(_Button, _extends({
    type: "submit",
    disabled: disableSubmitOnFormInvalid && !formik.isValid
  }, buttonSubmitProps), _jsx(_Typography, {
    variant: "body1",
    color: "textPrimary"
  }, void 0, buttonSubmitLabel)), error && _jsx("p", {
    className: classes.submitError
  }, void 0, error));
};

Form.defaultProps = {
  inputProps: types.defaultProps.inputType,
  buttonSubmitProps: types.defaultProps.buttonType,
  withInputsBackground: true,
  withInputsBorder: false,
  autoFocus: false
};
export { Form };