import _AttachFile from "@material-ui/icons/esm/AttachFile";
import _MusicNote from "@material-ui/icons/esm/MusicNote";
import _Grow from "@material-ui/core/esm/Grow";
import _Image from "@material-ui/icons/esm/Image";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useStyles } from "./MediaMenuStyles";
import { detectOutsideClick } from "../../../../helpers/detectClick";
import { v4 as uuidv4 } from 'uuid';
import { useMessenger } from "../../../Messenger";

const MediaMenu = _ref => {
  let showMediaMenu = _ref.showMediaMenu,
      setShowMediaMenu = _ref.setShowMediaMenu,
      handleAddFileToSend = _ref.handleAddFileToSend,
      appliedColors = _ref.appliedColors;

  const _useMessenger = useMessenger(),
        config = _useMessenger.config.chat.multimedia;

  const classes = useStyles(appliedColors);
  const refMediaMenu = useRef(null);
  detectOutsideClick(refMediaMenu, setShowMediaMenu);

  const handleAddNewFile = (event, type) => {
    const fileSelected = Array.from(event.target.files);

    if (fileSelected.length > 0) {
      const newObject = {
        id: uuidv4(),
        file: fileSelected[0],
        url: URL.createObjectURL(fileSelected[0]),
        type: type,
        name: fileSelected[0].name,
        extension: fileSelected[0].name.split('.').pop().toLowerCase()
      };
      return handleAddFileToSend(newObject);
    }
  };

  return React.createElement("div", {
    ref: refMediaMenu,
    className: "".concat(classes.container, " ").concat(classes.withIcon)
  }, config.image && React.createElement(React.Fragment, null, _jsx("input", {
    type: "file",
    accept: ".jpg,.jpeg,.png",
    hidden: true,
    id: "uploadImage",
    onChange: e => handleAddNewFile(e, 'image')
  }), _jsx("label", {
    htmlFor: "uploadImage"
  }, void 0, React.createElement(_Grow, _extends({
    in: showMediaMenu
  }, showMediaMenu ? {
    timeout: 300
  } : {}), _jsx("div", {
    className: classes.item
  }, void 0, _jsx(_Image, {
    className: classes.icon
  }))))), config.voiceMessage && React.createElement(React.Fragment, null, _jsx("input", {
    type: "file",
    accept: ".mp3",
    hidden: true,
    id: "uploadAudio",
    onChange: e => handleAddNewFile(e, 'audio')
  }), _jsx("label", {
    htmlFor: "uploadAudio"
  }, void 0, React.createElement(_Grow, _extends({
    in: showMediaMenu
  }, showMediaMenu ? {
    timeout: 150
  } : {}), _jsx("div", {
    className: classes.item
  }, void 0, _jsx(_MusicNote, {
    className: classes.icon
  }))))), config.file && React.createElement(React.Fragment, null, _jsx("input", {
    type: "file",
    accept: ".pdf,.xlsx,.xls,.doc,.docx",
    hidden: true,
    id: "uploadFile",
    onChange: e => handleAddNewFile(e, 'file')
  }), _jsx("label", {
    htmlFor: "uploadFile"
  }, void 0, _jsx(_Grow, {
    in: showMediaMenu
  }, void 0, _jsx("div", {
    className: classes.item
  }, void 0, _jsx(_AttachFile, {
    className: classes.icon
  }))))));
};

export { MediaMenu };