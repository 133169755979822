export const light = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      connected: '#3F9C35',
      disconnected: '#E30613',
      mainBackground: '#F5F7F7',
      loaderBackground: '#f5f7f7d2',
      drawerBackground: '#F5F7F7',
      captionTextColor: '#fff',
      // headerBackground: '#00040F40',
      secondaryBackground: '#63666A',
      headerBackground: '#FFFFFF',
      introSliderBackground: '#F5F7F7'
      // introSliderClientBackground: '#C8102E' // Latest intro slider with client logo
    },
    type: 'light',
    primary: {
      main: '#C8102E',
      light: '#ff5357',
      dark: '#8f0007',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#63666A',
      light: '#909498',
      dark: '#393c3f',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff',
      level2: '#f5f5f5',
      level1: '#fff'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    },
    input: {
      primary: {
        color: '#313131',
        labelColor: {
          base: '#00000061',
          focused: '#4267CB',
          filled: '#4267CB'
        },
        borderColor: {
          base: '#4267CB',
          hover: '#4267CB',
          focused: '#4267CB'
        },
        underline: '#4267CB'
      },
      secondary: {
        color: '#CFF31B',
        labelColor: {
          base: '#FFFFFF',
          focused: '#FFFFFF',
          filled: '#FFFFFF'
        },
        borderColor: {
          base: '#191919',
          hover: '#252525',
          focused: '#4267CB'
        },
        underline: {
          base: '#191919',
          hover: '#FFFFFF',
          focused: '#4267CB',
          error: '#FFFFFF'
        }
      }
    }
  },
  nprogress: {
    color: '#000'
  }
};
