import { useEffect } from 'react';
export const detectOutsideClick = (ref, setState) => {
  const handleClickOutside = event => {
    ref.current && !ref.current.contains(event.target) && setState(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', e => handleClickOutside(e));
    return document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
};
export const detectInnerClick = (ref, setState, state) => {
  const handleClickOutside = event => {
    ref.current && ref.current.contains(event.target) && setState(!state);
  };

  useEffect(() => {
    document.addEventListener('mousedown', e => handleClickOutside(e));
    return document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
};