import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4)
  },
  titleHead: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },

  category: { color: theme.palette.primary.main },
  linearProgressContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  buttonContainer: { marginTop: theme.spacing(2) },
  rank: {
    paddingLeft: 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 30
    }
  },
  userCategory: {
    marginLeft: 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  score: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  policiesData: {
    width: 600,
    height: 600
  },
  scrollBox: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      display: 'none'
    },
    height: 400
  }
}));

const useSliderStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSlider-rail': {
      backgroundColor: theme.palette.primary.dark,
      height: 6
    },
    '& .MuiSlider-track': {
      backgroundColor: theme.palette.primary.main,
      height: 6
    }
  },
  thumb: {
    height: '0.0px !important',
    width: '0.0px !important',
    marginTop: 0
  }
}));

export { useStyles, useSliderStyles };
