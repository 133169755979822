import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 155,
    height: 140,
    backgroundColor: theme.palette.background.paper
  },
  dayText: {
    fontSize: theme.typography.pxToRem(70),
    lineHeight: theme.typography.pxToRem(75)
  },
  dateText: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'capitalize',
    fontWeight: 100
  }
}));

export { useStyles };
