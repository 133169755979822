import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    right: '10px',
    [theme.breakpoints.down('xs')]: {
      right: 0
    },
    bottom: '0px',
    backgroundColor: 'rgba(1,1,1,0)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '40px'
  }
}));
export { useStyles };