import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useModal } from '@octopy/react-modal';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import { messages } from './ValidateAnswerMessages';
import { useStyles } from './ValidateAnswerStyles';

const ValidateAnswer = ({ codeStatus, pointsWin }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleCloseModal } = useModal();
  //const { indexUser, correctRightAnswer } = pointsEarned;
  const { code, message } = codeStatus;

  return (
    <>
      {code === 200 && (
        <Box>
          <Grid container className={classes.boxModal}>
            <Grid item>
              <Typography
                variant="body1"
                color="primary"
                className={classes.congratulations}
              >
                {message === 'Respuesta correcta...'
                  ? intl.formatMessage(messages.congratulations)
                  : intl.formatMessage(messages.missed)}
              </Typography>
              <Typography variant="subtitle2" className={classes.info}>
                {message === 'Respuesta correcta...'
                  ? `${intl.formatMessage(
                      messages.messageWinner
                    )} ${pointsWin} PTS.`
                  : intl.formatMessage(messages.youLost)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.button}>
            <Grid item>
              <Button color="primary" onClick={handleCloseModal}>
                {intl.formatMessage(messages.okButton)}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {message === 'Ya contestaste esta pregunta.' && (
        <Box>
          <Grid container className={classes.boxModal}>
            <Grid item>
              <Typography
                variant="body1"
                color="primary"
                className={classes.congratulations}
              >
                {intl.formatMessage(messages.missed)}
              </Typography>
              <Typography variant="subtitle2" className={classes.info}>
                {intl.formatMessage(messages.questionAnswered)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.button}>
            <Grid item>
              <Button color="primary" onClick={handleCloseModal}>
                {intl.formatMessage(messages.okButton)}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

ValidateAnswer.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ValidateAnswer };
