import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  formInstructions: {
    id: 'components.Auth.PasswordRecovery.formInstructions',
    defaultMessage: 'Restablece la contraseña para acceder a este evento'
  },
  buttonSubmit: {
    id: 'components.Auth.PasswordRecovery.buttonSubmit',
    defaultMessage: 'Restablecer contraseña'
  },
  successRecoveryMessage: {
    id: 'components.Auth.PasswordRecovery.successRecoveryMessage',
    defaultMessage: 'Se ha restablecido la contraseña exitosamente, ya puedes iniciar sesión.'
  },
  expiredTimeMessage: {
    id: 'components.Auth.PasswordRecovery.expiredTimeMessage',
    defaultMessage: 'Su tiempo para restablecer la contraseña ha expirado, vuelva a intentarlo.'
  }
});