import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import MUIRichTextEditor from 'mui-rte';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Box,
  Button,
  CardMedia,
  Hidden,
  useTheme
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { UserCard } from 'components/UserCard';
import { Video } from 'components/Video';
import { useLandingPartner } from './hooks/useLandingPartner';
import { messages } from './LandingPartnerMessages';
import { useStyles } from './LandingPartnerStyles';
import { useChallenge } from 'hooks/useChallenges';
import { ImInterestedFormModal } from './ImInterestedFormModal/ImInterestedFormModal';
import { useModal } from '@octopy/react-modal';

const LandingPartner = () => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { handleOpenModal } = useModal();
  const { landingState } = useLandingPartner();
  const [partnerInformation, setPartnerInformation] = useState({});
  const [partnerStand, setPartnerStand] = useState({});

  const titleTheme = createMuiTheme();
  const descriptionTheme = createMuiTheme();
  const sloganTheme = createMuiTheme();
  const { updateChallenge } = useChallenge();

  Object.assign(titleTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          color: '#000',
          fontWeight: 'bold',
          fontSize: '50px'
        },
        editor: {
          fontSize: '45px',
          lineHeight: '50px'
        }
      }
    }
  });

  Object.assign(descriptionTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          color: '#000'
        }
      }
    }
  });

  Object.assign(sloganTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          color: theme.palette.primary.main
        },
        editor: {
          fontSize: '30px',
          lineHeight: '39px',
          fontStyle: 'italic'
        }
      }
    }
  });

  useEffect(() => {
    if (!landingState.business_name) {
      history.push('/partner-room');
    } else {
      const {
        image,
        business_name,
        description,
        stand,
        country
      } = landingState;

      const {
        slogan,
        backgroud,
        image_1: partnerExhibition1,
        image_2: partnerExhibition2,
        description: standDescription,
        title,
        video_id,
        plataform
      } = stand;

      const partnerStand = {
        image,
        name: business_name,
        speciality: country,
        standDescription,
        description,
        title,
        videoId: video_id,
        platform: plataform,
        background: backgroud
      };

      setPartnerInformation({
        ...landingState,
        slogan,
        background: backgroud,
        partnerExhibition1,
        partnerExhibition2
      });

      setPartnerStand(partnerStand);
    }
  }, []);

  const handleOpenImInterestedForm = () =>
    handleOpenModal({
      configProps: {
        fullWidth: true,
        maxWidth: 'xs',
        PaperProps: {
          style: {
            backgroundColor: theme.palette.common.drawerBackground
          }
        }
      },
      body: <ImInterestedFormModal partnerId={partnerInformation._id} />,
      maxWidth: 'xs'
    });

  return (
    <Grid container>
      <Grid
        item
        sm={12}
        md={6}
        className={classes.imagePartner}
        style={{
          // backgroundImage: `url(${partnerInformation.background})`
          backgroundColor: partnerStand.background
        }}
      >
        <Video
          id={partnerStand.videoId}
          platform={partnerStand.platform}
          width="100%"
          height="100%"
          allowControls={false}
        />
      </Grid>

      <Grid
        item
        sm={12}
        md={6}
        className={classes.presentationPartner}
        style={{ backgroundColor: partnerStand.background }}
      >
        <Box className={classes.logoPartnerContainer}>
          <Box
            style={{
              minWidth: 150,
              minHeight: 150,
              backgroundImage: `url(${partnerInformation.image})`,
              backgroundSize: '100% auto',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          className={classes.bodyPartner}
        >
          <Box>
            <MuiThemeProvider theme={titleTheme}>
              <MUIRichTextEditor
                defaultValue={partnerStand.title && partnerStand.title}
                toolbar={false}
                readOnly
                style={{ color: '#000' }}
              />
            </MuiThemeProvider>
            <MuiThemeProvider theme={descriptionTheme}>
              <MUIRichTextEditor
                defaultValue={
                  partnerStand.standDescription && partnerStand.standDescription
                }
                toolbar={false}
                readOnly
                style={{ color: '#000' }}
              />
            </MuiThemeProvider>
            <Box mt={{ sm: 1, md: 2 }}>
              <Button
                onClick={() => {
                  updateChallenge('interest-stand');
                  handleOpenImInterestedForm();
                }}
                color="primary"
              >
                {intl.formatMessage(messages.interestedButton)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.mainPhraseContainer}>
        <span className={classes.quotationMarksSlogan}>"</span>
        <MuiThemeProvider theme={sloganTheme}>
          <MUIRichTextEditor
            defaultValue={
              partnerInformation.slogan && partnerInformation.slogan
            }
            toolbar={false}
            readOnly
            style={{ color: '#000' }}
          />
        </MuiThemeProvider>
        <span className={classes.quotationMarksSlogan}>"</span>
      </Grid>

      <Box
        width={1}
        pt={20}
        pb={12}
        style={{ backgroundColor: partnerStand.background }}
      >
        <Grid container alignItems="flex-end">
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              className={clsx(classes.partnerCard, classes.spacingBottom)}
            >
              <UserCard cardContent={partnerStand} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              px={{ xs: 2, md: 5 }}
              pb={{ xs: 10, sm: 0 }}
              display="flex"
              justifyContent="center"
            >
              {partnerInformation.partnerExhibition1 && (
                <CardMedia
                  image={partnerInformation.partnerExhibition1}
                  className={classes.partnerImage}
                />
              )}
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={6} md={4} className={classes.partnerCard}>
              <UserCard cardContent={partnerStand} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={4}>
            <Box px={{ xs: 2, md: 5 }} display="flex" justifyContent="center">
              {partnerInformation.partnerExhibition2 && (
                <CardMedia
                  image={partnerInformation.partnerExhibition2}
                  className={classes.partnerImage}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid item xs={12} lg={12} className={classes.footer}>
        <Box>
          <Typography variant="h2" className={classes.questionKnowMore}>
            {intl.formatMessage(messages.questionKnowMore)}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-around"
            style={{ marginTop: 35 }}
          >
            <Button onClick={() => history.goBack()} className={classes.button}>
              {intl.formatMessage(messages.exitButton)}
            </Button>
            <Button
              onClick={handleOpenImInterestedForm}
              className={classes.button}
            >
              {intl.formatMessage(messages.interestedButton)}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export { LandingPartner };
