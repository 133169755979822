import { responseMapper } from "../../helpers/responseMapper";
const contactTemplate = {
  id: '_id',
  username: 'user_name',
  name: 'name',
  eventId: 'event',
  email: 'email',
  position: 'position',
  description: 'description',
  phone: 'phone',
  company: 'company'
};
const chatConnectedParticipantsTemplate = {
  contacts: ['items', contactTemplate],
  totalPages: 'pages'
};
export const mapChatConnectedParticipants = data => {
  const mappedData = responseMapper({
    data,
    template: chatConnectedParticipantsTemplate
  });
  return mappedData;
};
export const mapContact = contact => {
  const mappedContact = responseMapper({
    data: contact,
    template: contactTemplate
  });
  return mappedContact;
};