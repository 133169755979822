import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  fontWeightBold: { fontWeight: 'bold' },
  horizontalScroll: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0
    },
    paddingBottom: theme.spacing(1)
  },
  scrollButton: {
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  arrowIcons: {
    fontSize: 40,
    [theme.breakpoints.down('xs')]: { fontSize: 25 }
  }
}));

export { useStyles };
