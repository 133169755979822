import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.modal
  },
  media: {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
    borderRadius: '5px',
    zIndex: 1,
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  navigationButton: {
    position: 'fixed',
    zIndex: 2,
    top: '50%',
    transform: 'translate(0%, -50%)',
    borderRadius: '50%',
    '& svg': {
      fontSize: '50px',
      color: '#ffffff8f',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px'
      }
    },
    '&.before': {
      left: '35px',
      [theme.breakpoints.down('sm')]: {
        left: '10px'
      }
    },
    '&.next': {
      right: '35px',
      [theme.breakpoints.down('sm')]: {
        right: '10px'
      }
    }
  }
}));
export { useStyles };