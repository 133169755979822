export const config = {
  appId: (process.env.REACT_APP_NAME || '').replace(/@octopy\/react-spa-/, ''),
  siteConfig: {
    languageCode: (process.env.REACT_APP_LANGUAGE_CODE || '').toLowerCase(),
    defaultTheme: process.env.REACT_APP_DEFAULT_THEME,
    allowBrowserTheme:
      process.env.REACT_APP_THEME_ENABLE_BROWSER_SUPPORT === 'true'
  },
  endpoints: {
    mainBackendUrl: process.env.REACT_APP_BACKEND_URL,
    userBackEndUrl: process.env.REACT_APP_SECONDARY_BACKEND_URL,
    xApikey: process.env.REACT_APP_X_API_KEY
  },
  recoveryPasswordExpirationTime: Number(
    process.env.REACT_APP_RECOVERY_PASSWORD_EXPIRATION_TIME
  ),
  videoPlayer: {
    vimeoAppId: process.env.REACT_APP_VIMEO_APP_ID
  },
  exhibitionRoom: {
    interestedLink: process.env.REACT_APP_EXHIBITION_ROOM_EXTERNAL_LINK,
    enableShowMore:
      process.env.REACT_APP_EXHIBITION_ROOM_ENABLE_SHOW_MORE === 'true'
  },
  company: {
    name: process.env.REACT_APP_COMPANY_NAME || 'Octopy'
  }
};
