import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography, IconButton } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { UserCard } from 'components/UserCard';
import { messages } from './MainRoomMessages';
import { useStyles } from './MainRoomStyles';
import { useRootProvider } from 'components/RootProvider';
import { reestructureActivity } from 'utils/reestructureActivity';
import { useApi } from 'hooks';
import { useChallenge } from 'hooks/useChallenges';

const MainRoom = () => {
  const intl = useIntl();
  const classes = useStyles();
  const activityContainerRef = useRef(null);
  const [filterActivities, setFilterActivities] = useState([]);
  const {
    rootState,
    handleChangeActivities,
    handleChangeActivitySchedule,
    handleChangeStream
  } = useRootProvider();
  const { activityList, activityScheduleList } = rootState;
  const { updateChallenge } = useChallenge();

  const [getActivitiesSchedule] = useApi({
    endpoint: `activities/schedule`,
    method: 'get'
  });

  const [getEventActivities] = useApi({
    endpoint: `events`,
    method: 'get'
  });

  const [scheduleActivity] = useApi({
    endpoint: `activities`,
    method: 'patch'
  });

  useEffect(() => {
    handleGetActivities();
    updateChallenge('main-room');
  }, []);

  useEffect(() => {
    const filterActivityList = _.map(activityList, (activity) => {
      const finded = _.find(
        activityScheduleList,
        (activitySchedule) => activity.id === activitySchedule.id
      );

      const refactorActivity = {
        ...activity,
        isSchedule: finded ? true : false
      };

      return refactorActivity;
    });

    const sortedFilterActivityList = filterActivityList
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .reverse();

    setFilterActivities(sortedFilterActivityList);
  }, [activityList, activityScheduleList]);

  const handleGetActivities = async () => {
    const session1 = await window.localStorage.getItem('session');
    const session = JSON.parse(session1);
    const { event } = session;

    const activityScheduleResponse = await getActivitiesSchedule({ body: {} });
    const activitiesResponse = await getEventActivities({
      body: {},
      urlParams: `${event}/activities`
    });

    const allActivityList = reestructureActivity(
      _.get(activitiesResponse, ['payload', 'items'])
    );

    const activityScheduleList = reestructureActivity(
      _.get(activityScheduleResponse, ['payload', 'items'])
    );

    handleChangeActivities({
      activityList: allActivityList
    });

    handleChangeActivitySchedule({
      activityScheduleList: activityScheduleList
    });
  };

  const handleScheduleActivity = async (activity) => {
    const activityID = _.get(activity, 'id', '');

    const responseScheduleActivity = await scheduleActivity({
      urlParams: `${activityID}/schedule`
    });

    const code = _.get(
      responseScheduleActivity,
      ['headerResponse', 'code'],
      500
    );

    if (code === 200) {
      handleGetActivities();
    }
  };

  const handleScroll = (direction) => {
    var container = activityContainerRef.current;
    const currentScroll = container.scrollLeft;

    if (direction === 'left') {
      container.scrollTo({
        top: 0,
        left: currentScroll - 500,
        behavior: 'smooth'
      });
    } else {
      container.scrollTo({
        top: 0,
        left: currentScroll + 500,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="h4"
        color="primary"
        className={classes.fontWeightBold}
      >
        {intl.formatMessage(messages.title, { name: 'MainRoom' })}
      </Typography>
      <Box width={1} display="flex" justifyContent="flex-end">
        <IconButton
          className={classes.scrollButton}
          onClick={() => {
            handleScroll('left');
          }}
        >
          <ArrowBackIos className={classes.arrowIcons} />
        </IconButton>
        <IconButton
          className={classes.scrollButton}
          onClick={() => {
            handleScroll('right');
          }}
        >
          <ArrowForwardIos className={classes.arrowIcons} />
        </IconButton>
      </Box>
      <Box
        ref={activityContainerRef}
        display="flex"
        className={classes.horizontalScroll}
      >
        {_.map(filterActivities, (activity, index) => (
          <Box mr={4} key={`user-card-box-${index}`}>
            <UserCard
              cardContent={activity}
              scheduleAction={({ isChecked }) => {
                handleScheduleActivity(activity);
              }}
              getInAction={() => {
                const {
                  chatIframe,
                  name,
                  description,
                  platform,
                  videoId
                } = activity;

                handleChangeStream({
                  stream: {
                    title: description,
                    speaker: name,
                    chatUri: chatIframe,
                    platform,
                    videoId
                  }
                });
              }} // recive a roomID param
            />
          </Box>
        ))}
      </Box>
    </div>
  );
};

export { MainRoom };
