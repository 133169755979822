import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  player: {
    backgroundColor: '#000',
    pointerEvents: 'none'
  }
}));

export { useStyles };
