import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: _ref => {
    let anchor = _ref.anchor;
    return {
      color: anchor === 'left' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
      padding: '7px 10px 2px 9px',
      fontSize: '0.750rem'
    };
  }
}));
export { useStyles };