import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";

const setItem = (keyName, data) => {
  let parsed = data;

  const isObject = _isObject(data);

  const isArray = _isArray(data);

  if (isObject || isArray) parsed = JSON.stringify(data);
  window.localStorage.setItem(keyName, parsed);
};

const getItem = keyName => {
  if (!keyName || _isEmpty(keyName)) return null;
  let value = window.localStorage.getItem(keyName);
  if (!_isEmpty(value) && _get(value, 0) === '[' || _get(value, 0) === '{') value = JSON.parse(value);
  if (value === 'true') value = true;
  if (value === 'false') value = false;
  return value;
};

export { getItem, setItem };