import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  title: {
    id: 'components.Messages.DeleteModal.title',
    defaultMessage: '¿Seguro que quieres eliminar este mensaje?'
  },
  delete: {
    id: 'components.Messages.DeleteModal.delete',
    defaultMessage: 'Eliminar'
  },
  cancel: {
    id: 'components.Messages.DeleteModal.cancel',
    defaultMessage: 'Cancelar'
  }
});