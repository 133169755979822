import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@material-ui/core';
import { useStyles } from './AvatarStyles';

const WithIndicator = (props) => {
  const classes = useStyles();
  const { children, status } = props;

  return (
    <Badge
      overlap="circle"
      badgeContent=" "
      className={classes[status ? 'availableStatus' : 'unavailableStatus']}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      {children}
    </Badge>
  );
};

WithIndicator.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.bool.isRequired
};

export { WithIndicator };
