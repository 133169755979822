import _ from 'lodash';
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box } from '@material-ui/core';
import { useApi } from 'hooks';
import { useRootProvider } from 'components/RootProvider';
import { messages } from './PartnerStageMessages';
import { useStyles } from './PartnerStageStyles';
import { PartnerCard } from './PartnerCard/PartnerCard';
import { useLandingPartner } from '../LandingPartner/hooks/useLandingPartner';
import { useChallenge } from 'hooks/useChallenges';

const PartnerStage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { changeDataPartner } = useLandingPartner();
  const { handleChangePartner, rootState } = useRootProvider();
  const [localPartnerList, setLocalPartnerList] = useState([]);
  const { event: eventState } = rootState;
  const { updateChallenge } = useChallenge();

  const [getEventService] = useApi({
    endpoint: 'events',
    method: 'get'
  });

  const [getPartnersService] = useApi({
    endpoint: 'partners',
    method: 'get'
  });

  const handlePartners = async () => {
    const eventResponse = await getEventService({ urlParams: eventState._id });

    const idPartners = _.map(
      _.get(eventResponse, 'payload.partners', []),
      (partner) => partner._id
    );

    const partnerResponse = await axios.all(
      _.map(idPartners, (partnerID) =>
        getPartnersService({
          urlParams: partnerID
        })
      )
    );

    const partnerList = _.map(
      partnerResponse,
      (dataPartner) => dataPartner.payload
    );

    setLocalPartnerList(partnerList);
    handleChangePartner({ partnerList });
  };

  useEffect(() => {
    handlePartners();
    updateChallenge('enter-partner');
  }, []);

  const generateCardPartnerStage = () =>
    _.map(localPartnerList, (partner, index) => (
      <Grid item xs={12} sm={6} lg={4} key={`partner-${index}`}>
        <Box px={2} py={2.5}>
          <PartnerCard
            contendCard={partner}
            action={() => changeDataPartner(partner)}
            key={index}
          />
        </Box>
      </Grid>
    ));

  return (
    <div className={classes.container}>
      <Typography variant="h4" color="primary" className={classes.mainText}>
        {intl.formatMessage(messages.title, {
          name: 'PartnerStage'
        })}
      </Typography>
      <Box className={classes.boxContainerCards}>
        <Grid container>{generateCardPartnerStage()}</Grid>
      </Box>
    </div>
  );
};

export { PartnerStage };
