import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Card, CardActionArea } from '@material-ui/core';
import { messages } from './QuizMessages';
import { useStyles } from './QuizStyles';
import { useModal } from '@octopy/react-modal';
import { TestQuestions } from 'components/TestQuestions/TestQuestions';
import { Divider } from '@material-ui/core';
import { useApi } from 'hooks';
import _ from 'lodash';

const Quiz = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const [quizState, setQuizState] = useState({});

  const [getQuiz] = useApi({
    endpoint: 'events',
    method: 'get'
  });

  useEffect(() => {
    handleQuizzes();
  }, []);

  const handleQuizzes = async () => {
    const sessionLs = await window.localStorage.getItem('session');
    const session = JSON.parse(sessionLs);
    const { event } = session;
    const quizzesResponse = await getQuiz({ urlParams: `${event}/quizzes` });
    const getItemQuiz = _.get(quizzesResponse, ['payload', 'items'], []);
    const objectQuiz = getItemQuiz[0];
    const { questions } = objectQuiz;
    const questionList = _.map(questions, (item) => ({
      answers: item.answers,
      points: item.points,
      question: item.question,
      rightAnswer: item.right_answer,
      room: item.room,
      id: item._id
    }));

    setQuizState(questionList);
  };

  const head = [
    'Hall',
    'Sala principal',
    'Sala de exhibición',
    'Sala partner',
    'Challenge',
    'Sala de café'
  ];
  const hallColumn = [];
  const mainColumn = [];
  const challengeColumn = [];
  const coffeColumn = [];
  const partnerColumn = [];
  const exhibitionColumn = [];

  const bubbleSort = (inputArr) => {
    const len = inputArr.length;

    for (let i = 1; i < len; i++) {
      for (let j = 0; j < len - 1; j++) {
        if (inputArr[j].points > inputArr[j + 1].points) {
          let tmp = inputArr[j];

          // eslint-disable-next-line no-param-reassign
          inputArr[j] = inputArr[j + 1];
          // eslint-disable-next-line no-param-reassign
          inputArr[j + 1] = tmp;
        }
      }
    }

    return inputArr;
  };

  _.map(quizState, (dataQuestion) => {
    if (dataQuestion.room === 'hall') {
      hallColumn.push(dataQuestion);
    }

    if (dataQuestion.room === 'main_room') {
      mainColumn.push(dataQuestion);
    }

    if (dataQuestion.room === 'partner_stage') {
      partnerColumn.push(dataQuestion);
    }

    if (dataQuestion.room === 'challenge') {
      challengeColumn.push(dataQuestion);
    }

    if (dataQuestion.room === 'coffe_room') {
      coffeColumn.push(dataQuestion);
    }

    if (dataQuestion.room === 'exhibition_room') {
      exhibitionColumn.push(dataQuestion);
    }
  });
  const hallColumnPoints = bubbleSort(hallColumn);
  const mainColumnPoints = bubbleSort(mainColumn);
  const partnerColumnPoints = bubbleSort(partnerColumn);
  const challengeColumnPoints = bubbleSort(challengeColumn);
  const coffeColumnPoints = bubbleSort(coffeColumn);
  const exhibitionColumnPoints = bubbleSort(exhibitionColumn);

  const handleQuestion = (dataQuestionsAnswers) => {
    handleOpenModal({
      body: <TestQuestions data={dataQuestionsAnswers} />,
      configProps: {
        maxWidth: 'sm'
      }
    });
  };

  return (
    <div className={classes.container}>
      <Typography color="primary" variant="h4" className={classes.roomName}>
        {intl.formatMessage(messages.roomName)}
      </Typography>
      <Box className={classes.boxContainer}>
        <Grid container justify="space-around">
          {head.map((titleHead) => (
            <Box className={classes.tableHead}>
              <Typography variant="subtitle2" className={classes.titleHead}>
                {titleHead}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Divider className={classes.divider} />
        <Grid container justify="space-around">
          <Grid item className={classes.contentCard}>
            {_.map(hallColumnPoints, (dataMain) => (
              <Card className={classes.cardSize}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleQuestion(dataMain)}
                >
                  <Typography variant="h1" className={classes.points}>
                    {dataMain.points}
                  </Typography>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
          <Grid item className={classes.contentCard}>
            {_.map(mainColumnPoints, (dataMain) => (
              <Card className={classes.cardSize}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleQuestion(dataMain)}
                >
                  <Typography variant="h1" className={classes.points}>
                    {dataMain.points}
                  </Typography>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
          <Grid item className={classes.contentCard}>
            {_.map(exhibitionColumnPoints, (dataExhibition) => (
              <Card className={classes.cardSize}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleQuestion(dataExhibition)}
                >
                  <Typography variant="h1" className={classes.points}>
                    {dataExhibition.points}
                  </Typography>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
          <Grid item className={classes.contentCard}>
            {_.map(partnerColumnPoints, (dataPartner) => (
              <Card className={classes.cardSize}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleQuestion(dataPartner)}
                >
                  <Typography variant="h1" className={classes.points}>
                    {dataPartner.points}
                  </Typography>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
          <Grid item className={classes.contentCard}>
            {_.map(challengeColumnPoints, (dataChallenge) => (
              <Card className={classes.cardSize}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleQuestion(dataChallenge)}
                >
                  <Typography variant="h1" className={classes.points}>
                    {dataChallenge.points}
                  </Typography>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
          <Grid item className={classes.contentCard}>
            {_.map(coffeColumnPoints, (dataCoffe) => (
              <Card className={classes.cardSize}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleQuestion(dataCoffe)}
                >
                  <Typography variant="h1" className={classes.points}>
                    {dataCoffe.points}
                  </Typography>
                </CardActionArea>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

Quiz.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Quiz };
