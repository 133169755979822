import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 140
  },
  styleSubtitle: {
    fontStyle: 'italic',
    color: theme.palette.secondary.main
  },
  textColor: {
    color: '#757575'
  },
  root: {
    minWidth: 280,
    maxWidth: theme.typography.pxToRem(358),
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.typography.pxToRem(358)
    }
  },
  font: {
    fontWeight: 700
  },
  scrollVertical: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0
    },
    maxHeight: 120
  },
  dividerMargin: {
    width: '100%',
    color: 'primary',
    border: `solid ${theme.palette.primary.main}`,
    borderWidth: `1px 0px 0px 0px`
  },
  heightCard: {
    height: 200
  },
  buttonSpace: {
    paddingBottom: 5,
    paddingTop: 10
  },
  container: {
    maxWidth: theme.typography.pxToRem(358),
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.typography.pxToRem(358)
    }
  },
  imageBackground: {
    backgroundColor:
      theme.palette.common[theme.palette.type === 'light' ? 'white' : 'black']
  },
  image: {
    width: '100%',
    minHeight: 150,
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
}));

export { useStyles };
