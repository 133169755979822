import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fontWeightBold: { fontWeight: 'bold' },
  divider: {
    border: 'none',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2)
  },
  iconContainer: {
    minWidth: 56,
    minHeight: 56,
    maxHeight: 56,
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255, 0.03)'
  },
  awardIcon: {
    height: 25
  }
}));

export { useStyles };
