import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { AuthProvider } from '@octopy/react-auth';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { App } from 'components/App';
import { LanguageProvider } from 'components/Language';
import { ThemeProvider } from 'components/Theme';
import { ModalProvider } from '@octopy/react-modal';
import { DrawerProvider } from 'components/Drawer';
import { LoaderProvider } from '@octopy/react-loader';
// import { ContextLogger } from 'components/ContextLogger';
import { config } from 'providers/config';
import { translations } from './translations';
// import appContext from './context';
import { RootProvider } from 'components/RootProvider';
import { LandingPartnerProvider } from 'components/LandingPartner/LandingPartnerProvider';
import { ChallengeProvider } from 'components/ChallengeProvider/ChallengeProvider';

// const contextConfig = { objectDiffs: true, arrayDiffs: false };

export function Root() {
  return (
    <>
      <ThemeProvider defaultTheme={config.siteConfig.defaultTheme}>
        <LanguageProvider
          locale={config.siteConfig.languageCode}
          translations={translations}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <LoaderProvider>
              <ModalProvider>
                <DrawerProvider>
                  <AuthProvider>
                    <RootProvider>
                      <ChallengeProvider>
                        <LandingPartnerProvider>
                          <App />
                          {/* <ContextLogger
                            contexts={appContext}
                            config={contextConfig}
                          /> */}
                        </LandingPartnerProvider>
                      </ChallengeProvider>
                    </RootProvider>
                  </AuthProvider>
                </DrawerProvider>
              </ModalProvider>
            </LoaderProvider>
          </MuiPickersUtilsProvider>
        </LanguageProvider>
      </ThemeProvider>
    </>
  );
}
