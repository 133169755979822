import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  boxModal: {
    height: 130,
    width: 400,
    [theme.breakpoints.down('xs')]: {
      height: 60,
      width: 200
    }
  },
  boxModalAltern: {
    height: 130,
    width: 400,
    [theme.breakpoints.down('xs')]: {
      height: 115,
      width: 200
    }
  },
  congratulations: {
    fontWeight: 900,
    paddingLeft: 29,
    paddingTop: 22,
    paddingBottom: 4,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 900,
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 6,
      fontSize: 12
    }
  },
  info: {
    fontWeight: 900,
    paddingLeft: 29,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingBottom: 6,
      fontSize: 11
    }
  },
  button: {
    paddingBottom: 20,
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 2,
      paddingRight: 2,
      paddingTop: 20
    }
  }
}));

export { useStyles };
